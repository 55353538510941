export const servillantasColors = {
  primary: "#48ac35",
  green: "#659131",
  darkerGreen: "#4e6f26",
  lightGreen: "#8ab232",
  jungleGreen: "#28b298",
  lightRed: "#ef6046",
  disabled: "#dedede",
  white: "#ffffff",
  darkWhite: "#f5f5f5",
  black: "#212121",
  gray: "#5f5f5f",
  orange: "#de8f1e",
  darkImageOverlay: "#00000080",
  facebook: "#3b5998",
  twitter: "#55acee",
  linkedin: "#0077b5",
  whatsapp: "#25d366",
  pse: "#2564a2",
};
