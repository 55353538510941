import React, { useState } from "react";
import styled from "styled-components";
import { string, func } from "prop-types";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import Button from "../../components/button";
import TextInput from "../../components/text-input";

const Wrapper = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;

  ${MEDIA_QUERIES.tablet} {
    flex-direction: row;
  }

  div {
    width: 250px;
    margin-bottom: 1rem;

    ${MEDIA_QUERIES.tablet} {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
`;

export default function SearchDashboard({
  handleSearch,
  queryValue,
  placeholder,
}) {
  const [query, setQuery] = useState(queryValue);

  return (
    <Wrapper onSubmit={e => handleSearch(e, query)}>
      <TextInput
        bordered
        placeholder={placeholder}
        value={query}
        onChange={setQuery}
      />
      <Button>Buscar</Button>
    </Wrapper>
  );
}

SearchDashboard.propTypes = {
  handleSearch: func,
  queryValue: string,
  placeholder: string,
};

SearchDashboard.defaultProps = {
  handleSearch: function() {},
  queryValue: "",
  placeholder: "",
};
