// @vendors
import React, { useState } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @utils
import removeAccents from "../../utils/removeAccents";

// @requests
import { GET_SERVICE } from "../../requests/queries/services";
import {
  CREATE_SERVICE,
  UPDATE_SERVICE,
} from "../../requests/mutations/services";
import { DELETE_IMAGE } from "../../requests/mutations";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import Layout from "../../containers/dashboard/Layout";
import DashboardImage from "../../components/dashboard/image";
import Button from "../../components/button";
import TextInput from "../../components/text-input";
import TextBoxInput from "../../components/textbox-input";
import Loading from "../../components/Loading";
import Updated from "../../components/dashboard/updated";

// @assets
import ServicesIcon from "../../assets/icons/gear";

const Wrapper = styled.form`
  width: 90%;
  max-width: 1260px;
  margin: 3rem auto;
`;

const Images = styled.div`
  ${MEDIA_QUERIES.tablet} {
    display: flex;
    justify-content: space-around;
  }
`;

const Content = styled.div`
  input,
  textarea {
    margin-bottom: 2rem;
  }
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  font-weight: bold;
`;

const SubmitContainer = styled.div`
  text-align: center;
`;

export default function DashboardService({
  match: {
    params: { slug },
  },
}) {
  const ENV = process.env.REACT_APP_ENV || "dev";
  const isNewService = slug === "nuevo";
  const [isSaved, setIsSaved] = useState(false);
  const [idService, setIdService] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [howWorks, setHowWorks] = useState("");
  const [image, setImage] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [icon, setIcon] = useState("");
  const [iconPath, setIconPath] = useState("");
  const { loading, error } = useQuery(GET_SERVICE, {
    variables: {
      slug: slug !== "nuevo" ? slug : "",
    },
    onCompleted({ service }) {
      if (service) {
        setIdService(service.id);
        setName(service.name);
        setDescription(service.description);
        setHowWorks(service.how_works);
        setImage(service.image);
        setImagePath(service.image_path);
        setIcon(service.icon);
        setIconPath(service.icon_path);
      }
    },
  });
  const [createService] = useMutation(CREATE_SERVICE, {
    onCompleted: function({ createService }) {
      if (createService) {
        setIsSaved(true);
      }
    },
  });
  const [updateService] = useMutation(UPDATE_SERVICE, {
    onCompleted: function({ updateService }) {
      if (updateService) {
        setIsSaved(true);
      }
    },
  });
  const [deleteImage] = useMutation(DELETE_IMAGE);

  function onChange(event, imageType, type, pathImg) {
    if (type === "name") {
      setName(event);
    }

    if (type === "image") {
      if (imageType === "icon") {
        setIcon(event);
        setIconPath(pathImg);
      } else {
        setImage(event);
        setImagePath(pathImg);
      }
    }

    if (type === "description") {
      setDescription(event);
    }

    if (type === "how_works") {
      setHowWorks(event);
    }
  }

  function onDelete(event, type) {
    deleteImage({ variables: { path_img: event } });

    if (type === "icon") {
      setIcon("");
      setIconPath("");
    } else {
      setImage("");
      setImagePath("");
    }
  }

  function handleService(e) {
    e.preventDefault();
    const data = {
      name,
      slug: removeAccents(name),
      description,
      how_works: howWorks,
      icon,
      icon_path: iconPath,
      image,
      image_path: imagePath,
    };

    if (isNewService) {
      createService({
        variables: {
          data,
        },
      });
    } else {
      updateService({
        variables: {
          id: idService,
          data,
        },
      });
    }
  }

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  if (isSaved)
    return (
      <Updated
        to="/administrador/servicios"
        message={`Se ha ${
          isNewService ? "creado" : "actualizado"
        } correctamente`}
      />
    );

  return (
    <Layout
      title={`Administrador - ${isNewService ? "Nuevo servicio" : name}`}
      Icon={ServicesIcon}
      heading={`${isNewService ? "Nuevo" : "Actualizar"} servicio`}
      nested
    >
      <Wrapper onSubmit={handleService}>
        <Content>
          <Label htmlFor="name">Nombre</Label>
          <TextInput
            bordered
            required
            id="name"
            name="name"
            value={name}
            placeholder="Escriba el nombre del servicio"
            onChange={onChange}
          />
          <Label htmlFor="description">Descripción</Label>
          <TextBoxInput
            bordered
            id="description"
            name="description"
            value={description}
            placeholder="Descripción del servicio"
            onChange={onChange}
          />
          <Label htmlFor="how_works">Como funciona</Label>
          <TextBoxInput
            bordered
            id="how_works"
            name="how_works"
            value={howWorks}
            placeholder="Como funciona el servicio"
            onChange={onChange}
          />
        </Content>
        <Images>
          <DashboardImage
            heading="Icono"
            image={icon}
            pathImg={iconPath}
            sizes="80px x 80px"
            folder={ENV === "dev" ? `dev/servicios` : "servicios"}
            onChange={onChange}
            onDelete={onDelete}
            position="icon"
          />
          <DashboardImage
            heading="Imagen"
            image={image}
            pathImg={imagePath}
            sizes="1440px x 800px"
            folder={ENV === "dev" ? `dev/servicios` : "servicios"}
            onChange={onChange}
            onDelete={onDelete}
            position="image"
          />
        </Images>
        <SubmitContainer>
          <Button>{isNewService ? "Guardar y publicar" : "Actualizar"}</Button>
        </SubmitContainer>
      </Wrapper>
    </Layout>
  );
}
