import React, { useState } from "react";
import styled, { withTheme } from "styled-components";

// @assets
import ArrowDownIcon from "../assets/icons/arrow-down";

const AccordionWrapper = styled.div`
  margin-bottom: 1rem;
`;

const AccordionHeading = styled.p`
  position: relative;
  padding: 0.8rem 3rem 0.8rem 0.8rem;
  border: 1px solid ${({ theme }) => theme.accordion.borderColor};
  font-weight: bold;

  svg {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    transform: ${({ isActive }) =>
      isActive ? "rotate(-180deg)" : "rotate(0deg)"};
  }
`;

const AccordionContainer = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.accordion.borderColor};
  border-top: 0;
`;

function Accordion({ heading, children, onToggle, theme }) {
  const [isVisible, setIsVisible] = useState(false);

  function handleToggle() {
    setIsVisible(!isVisible);

    if (onToggle) {
      onToggle(!isVisible);
    }
  }

  return (
    <AccordionWrapper>
      <AccordionHeading onClick={handleToggle} isActive={isVisible}>
        {heading}
        <ArrowDownIcon color={theme.accordion.primaryColor} />
      </AccordionHeading>
      {isVisible ? (
        <AccordionContainer>{children}</AccordionContainer>
      ) : (
        undefined
      )}
    </AccordionWrapper>
  );
}

export default withTheme(Accordion);
