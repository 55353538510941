// @vendors
import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

// @data
import branches from "../data/branches";

// @utils
import formatDateTime from "../utils/formatDateTime";

// @components
import TextInput from "./text-input";
import Select from "./search-select";
import DateInput from "./date-input";
import Button from "./button";

// @styles
import { MEDIA_QUERIES } from "../constants";

const Wrapper = styled.form`
  width: 100%;
`;

const Title = styled.h3`
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.appointmentForm.titleColor};
  text-align: center;
`;

const AdditionalFields = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  align-items: end;
  justify-items: stretch;

  ${MEDIA_QUERIES.mobileMax} {
    grid-column-gap: 10px;
  }
`;

const SelectLabel = styled.label`
  padding-left: 25px;
  font-weight: bold;
`;

const AppointmentButton = styled(Button).attrs(() => ({
  type: "submit",
}))`
  justify-content: center;
`;

const Message = styled.p`
  margin-top: 1rem;
  text-align: center;
`;

export default function AppointmentForm({ emailSender }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [branch, setBranch] = useState("");
  const [message, setMessage] = useState("");
  const [appointment] = useMutation(
    gql`
      mutation sendAppointment($data: AppointmentInput!) {
        appointment(data: $data) {
          message
        }
      }
    `,
    {
      onCompleted(data) {
        const { appointment } = data;
        setMessage(appointment.message);

        setTimeout(() => setMessage(""), 6000);
      },
    },
  );
  const options = branches.map((branch) => {
    return {
      value: branch.title,
      label: branch.title,
    };
  });
  const removeOptions = options.splice(0, 1);

  function handleAppointment(e) {
    e.preventDefault();

    const dateFormatted = formatDateTime(date);
    const timeFormatted = new Date(time).toTimeString().split(" ")[0];

    appointment({
      variables: {
        data: {
          name,
          email,
          phone,
          date: dateFormatted,
          time: timeFormatted,
          branch: branch.value,
          email_sender: emailSender,
        },
      },
    });

    setName("");
    setEmail("");
    setPhone("");
    setDate("");
    setTime("");
    setBranch("");
  }

  const eigthAM = moment().hours(8).minutes(0);
  const fivePM = moment().hours(17).minutes(0);
  const twoPM = moment().hours(14).minutes(0);

  return (
    <Wrapper onSubmit={handleAppointment}>
      <Title>Programa tu cita</Title>
      <TextInput
        bordered
        required
        id="name-field"
        placeholder="Nombre completo"
        value={name}
        onChange={setName}
      />
      <TextInput
        bordered
        required
        type="email"
        id="email-field"
        placeholder="Email"
        value={email}
        onChange={setEmail}
      />
      <TextInput
        bordered
        type="number"
        id="phone-field"
        placeholder="Teléfono"
        value={phone}
        onChange={setPhone}
      />
      <AdditionalFields>
        <DateInput label="Fecha" selected={date} onChange={setDate} />
        <DateInput
          label="Hora"
          timeOnly
          selected={time}
          onChange={setTime}
          initSchedule={new Date(eigthAM)}
          endSchedule={new Date(fivePM)}
          initScheduleSaturday={new Date(eigthAM)}
          endScheduleSaturday={new Date(twoPM)}
        />
        <div>
          <SelectLabel>Sede</SelectLabel>
          <Select
            type="ternary"
            placeholder="Selecciona"
            value={branch}
            options={removeOptions}
            onChange={setBranch}
          />
        </div>
        <AppointmentButton>Programar cita</AppointmentButton>
      </AdditionalFields>
      {message.length > 0 && <Message>{message}</Message>}
    </Wrapper>
  );
}
