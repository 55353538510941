// @vendors
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { elementType, string } from "prop-types";

// @constants
import { MEDIA_QUERIES } from "../../constants";

const Wrapper = styled(Link)`
  display: inline-block;
  min-width: 150px;
  margin-bottom: 2rem;
  text-align: center;
  text-decoration: none;

  ${MEDIA_QUERIES.tablet} {
    width: calc(100% / 3 - 3rem);
    margin: 1rem;
  }

  ${MEDIA_QUERIES.landscape} {
    width: calc(100% / 4 - 4rem);
    margin-bottom: 2rem;
  }
`;

const DashboardIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 ${({ theme }) => theme.main.boxShadow};

  svg {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.main.primaryColor};
  }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.main.grayDarkerColor};
  font-size: 1rem;
  font-weight: bold;

  ${MEDIA_QUERIES.desktopXL} {
    font-size: 1.2rem;
  }
`;

export default function DashboardItem({ Icon, text, link }) {
  return (
    <Wrapper to={`/administrador/${link}`}>
      <DashboardIcon>
        <Icon />
      </DashboardIcon>
      <Text>{text}</Text>
    </Wrapper>
  );
}

DashboardItem.propTypes = {
  Icon: elementType,
  text: string,
  link: string,
};

DashboardItem.propTypes = {
  text: "",
  link: "",
};
