// @vendors
import React from "react";
import styled from "styled-components";
import { array } from "prop-types";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Promotion from "../components/promotion";

const Container = styled.div`
  display: flex;

  ${MEDIA_QUERIES.tabletMax} {
    flex-direction: column;
  }
`;

export default function Promotions({ promotions }) {
  const promotionOne = promotions[0];
  const promotionTwo = promotions[1];
  const isMultiple = promotionOne.image && promotionTwo.image;
  const isUnique = promotionOne.image ? promotionOne : promotionTwo;

  if (!promotionOne.image && !promotionTwo.image) return null;

  return (
    <Container>
      {isMultiple ? (
        promotions.map((offer, index) => (
          <Promotion
            key={offer.id}
            multiple={isMultiple}
            bgAlt={index === 1}
            {...offer}
          />
        ))
      ) : (
        <Promotion {...isUnique} />
      )}
    </Container>
  );
}

Promotions.propTypes = {
  promotions: array,
};

Promotions.defaultProps = {
  promotions: [],
};
