// @vendors
import React, { useState } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @requests
import { GET_OFFERS } from "../../requests/queries";
import { UPDATE_OFFER, DELETE_IMAGE } from "../../requests/mutations";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import Layout from "../../containers/dashboard/Layout";
import DashboardOffersItems from "../../containers/dashboard/offers-items";
import Button from "../../components/button";
import Loading from "../../components/Loading";
import Updated from "../../components/dashboard/updated";

// @assets
import OfferIcon from "../../assets/icons/offer";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1260px;
  margin: 3rem auto 5rem;

  ${MEDIA_QUERIES.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Submit = styled.div`
  width: 100%;
  text-align: center;
`;

export default function DashboardOffers() {
  const [offers, setOffers] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const { loading, error } = useQuery(GET_OFFERS, {
    onCompleted(data) {
      const { cover_offers } = data;
      setOffers(cover_offers);
    },
  });
  const [updateCoverOffer] = useMutation(UPDATE_OFFER);
  const [deleteImage] = useMutation(DELETE_IMAGE);

  function onChange(event, offerN, type, pathImg) {
    const values = [...offers];

    if (type === "image") {
      values[offerN].image = event;
      values[offerN].image_path = pathImg;
    } else {
      values[offerN][type] = event;
    }

    if (values[0]["__typename"]) delete values[0]["__typename"];
    if (values[1]["__typename"]) delete values[1]["__typename"];

    setOffers(values);
  }

  function onDelete(event, offerN) {
    const values = [...offers];

    deleteImage({ variables: { path_img: event } });

    values[offerN].image = "";
    values[offerN].image_path = "";

    if (values[0]["__typename"]) delete values[0]["__typename"];
    if (values[1]["__typename"]) delete values[1]["__typename"];

    setOffers(values);
  }

  function handleOffers(e) {
    e.preventDefault();

    updateCoverOffer({ variables: { data: offers } });
    setIsSaved(true);
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;
  if (isSaved) return <Updated />;

  return (
    <Layout
      title="Administrador - Ofertas destacadas"
      Icon={OfferIcon}
      heading="Administrar ofertas destacadas"
    >
      <Wrapper onSubmit={handleOffers}>
        {offers.map((offer, index) => (
          <DashboardOffersItems
            key={offer.id}
            {...offer}
            pathImg={offer.image_path}
            heading={`Promoción ${index + 1}`}
            sizes="1000px x 1000px"
            onChange={onChange}
            onDelete={onDelete}
            position={index}
          />
        ))}
        <Submit>
          <Button>Guardar y publicar</Button>
        </Submit>
      </Wrapper>
    </Layout>
  );
}
