import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

// @utils
import removeAccents from "../utils/removeAccents";

// @requests
import { GET_SERVICES } from "../requests/queries/services";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import WhatsappButton from "./whatsapp-button";
import PseButton from "./pse-button";
import Loading from "./Loading";

// @assets
import blackLogo from "../assets/images/logo-servillantas-black.png";
import whiteLogo from "../assets/images/logo-servillantas-white.png";
import ArrowDown from "../assets/icons/arrow-down";
import FacebookIcon from "../assets/icons/facebook";
import InstagramIcon from "../assets/icons/instagram";

const Wrapper = styled.nav`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  transition: background 0.37s ease-in-out;
  background: ${({ scrolled }) => (scrolled ? "#fff" : "none")};

  .submenu--active {
    display: block;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  margin: 0 auto;

  ${MEDIA_QUERIES.desktopXL} {
    width: 80%;
  }
`;

const Logo = styled(Link)`
  display: block;
  width: 60%;
  text-align: center;

  ${MEDIA_QUERIES.tablet} {
    width: 30%;
  }

  ${MEDIA_QUERIES.desktop} {
    width: 18%;
  }

  img {
    height: 60px;

    ${MEDIA_QUERIES.landscape} {
      height: 80px;
    }
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
  width: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;

  ${MEDIA_QUERIES.landscape} {
    width: 75%;
  }

  ${MEDIA_QUERIES.desktopXL} {
    width: 82%;
  }
`;

const Item = styled.li`
  display: ${({ desktop, tablet }) =>
    desktop || tablet ? "none" : "inline-block"};
  position: relative;
  align-self: center;
  margin: 0 0.5rem;

  ${MEDIA_QUERIES.tablet} {
    display: ${({ desktop }) => (desktop ? "none" : "inline-block")};
  }

  ${MEDIA_QUERIES.desktop} {
    display: inline-block;
  }

  ${MEDIA_QUERIES.desktopXL} {
    margin: 0 1rem;
  }

  svg {
    pointer-events: none;
  }
`;

const NavbarLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme, transparent, scrolled, isActive }) =>
    isActive
      ? theme.main.primaryColor
      : transparent && !scrolled
      ? theme.navbar.textColorTransparent
      : theme.navbar.textColor};
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none;

  ${MEDIA_QUERIES.desktopXL} {
    font-size: 1rem;
  }
`;

const ToggleBtn = styled.button`
  position: absolute;
  z-index: 3;
  top: 2.5rem;
  right: 2rem;
  padding: 0;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;

  ${MEDIA_QUERIES.desktopXL} {
    right: 3rem;
  }
`;

const Lines = styled.span`
  display: block;
  width: 40px;

  &::before,
  &::after {
    content: "";
    display: block;
    background-color: ${({ theme }) => theme.navbar.burgerColor};
    height: 5px;
    transition: all 200ms ease-in-out;
  }

  &::before {
    margin-bottom: 15px;
    transform: ${({ isActive }) =>
      isActive ? "translateY(10px) rotate(45deg)" : `none`};
    box-shadow: ${({ isActive, theme }) =>
      isActive
        ? `0 0 0 ${theme.navbar.burgerColor}`
        : `0 10px 0 ${theme.navbar.burgerColor}`};
  }

  &::after {
    transform: ${({ isActive }) =>
      isActive ? "translateY(-10px) rotate(-45deg)" : `none`};
  }
`;

const Mobile = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 560px;
  height: 100%;
  transform: ${({ isActive }) =>
    isActive ? "translateX(0)" : "translateX(100%)"};
  transition: all 0.37s ease-in-out;
  background-color: ${({ theme }) => theme.navbar.backgroundColor};
  overflow-y: auto;
`;

const MobileList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 4rem 2rem;
  padding: 0;
  list-style-type: none;

  ${MEDIA_QUERIES.tablet} {
    margin: 5rem 4rem;
  }
`;

const MobileItem = styled.li`
  margin: 1rem 0;

  ${MEDIA_QUERIES.tablet} {
    display: ${({ mobile }) => (mobile ? "none" : "inline-block")};
  }

  ${MEDIA_QUERIES.desktop} {
    display: ${({ tablet, mobile }) =>
      tablet || mobile ? "none" : "inline-block"};
  }

  svg {
    pointer-events: none;
  }
`;

const MobileLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme, isActive }) =>
    isActive ? theme.main.primaryColor : theme.navbar.textColor};
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;

  svg {
    margin-left: 0.5rem;
  }
`;

const MobileLinkSpecial = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${({ theme, isActive }) =>
    isActive ? theme.main.primaryColor : theme.navbar.textColor};
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;

  svg {
    margin-left: 0.5rem;
  }
`;

const Submenu = styled.ul`
  display: none;
  position: absolute;
  z-index: 2;
  top: 2.5rem;
  left: 0;
  width: 180px;
  padding: 0 1rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.navbar.backgroundColor};
  list-style-type: none;

  &::before {
    content: "";
    position: absolute;
    top: -0.8rem;
    left: 2rem;
    width: 0;
    height: 0;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-bottom: 1rem solid ${({ theme }) => theme.navbar.backgroundColor};
  }
`;

const SubmenuItem = styled.li`
  margin: 1.2rem 0;
`;

const SubmenuLink = styled(Link)`
  color: ${({ theme, isActive }) =>
    isActive ? theme.main.primaryColor : theme.navbar.textColor};
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none;

  ${MEDIA_QUERIES.desktopXL} {
    font-size: 1rem;
  }
`;

const SubmenuMobile = styled.ul`
  display: none;
  padding: 0 1rem;
  list-style-type: none;
`;

const SubmenuMobileItem = styled.li`
  margin: 1.2rem 0;
`;

const Icon = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.footer.linkTextColor};
  &:last-child {
    margin-right: 0;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${({ theme }) => theme.footer.titleLineColor};
  }
`;

function Navbar({ transparent, theme, location }) {
  const currentURL = location.pathname;
  const [isActive, setIsActive] = useState(false);
  const [scrolledMenu, setScrolledMenu] = useState(false);
  const { loading, error, data } = useQuery(GET_SERVICES);

  useEffect(function sideEffects() {
    window.addEventListener("scroll", handleFixedMenu);

    return () => {
      window.removeEventListener("scroll", handleFixedMenu);
    };
  }, []);

  function handleToggleMenu() {
    setIsActive(!isActive);
  }

  function handleFixedMenu() {
    let DOMproperties = document.body.getBoundingClientRect();
    let currentTop = DOMproperties.top;

    if (currentTop >= 0) {
      setScrolledMenu(false);
    } else {
      setScrolledMenu(true);
    }
  }

  function handleToggleSubmenu(e) {
    let currentElement = e.target.nextSibling;

    currentElement.classList.toggle("submenu--active");
  }

  function handleDisableSubmenu(e) {
    let currentElement = e.target;

    currentElement.classList.remove("submenu--active");
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { services } = data;

  return (
    <Wrapper scrolled={scrolledMenu ? 1 : 0} id="navigation">
      <Container>
        <Logo to="/">
          {transparent && !scrolledMenu ? (
            <img src={whiteLogo} alt="Logo Servillantas" />
          ) : (
            <img src={blackLogo} alt="Logo Servillantas" />
          )}
        </Logo>

        <List>
          <Item desktop>
            <NavbarLink
              to="#"
              transparent={transparent ? 1 : 0}
              scrolled={scrolledMenu ? 1 : 0}
              onClick={handleToggleSubmenu}
            >
              Categorías
              <ArrowDown color={theme.main.primaryColor} />
            </NavbarLink>
            <Submenu onClick={handleDisableSubmenu}>
              <SubmenuItem>
                <SubmenuLink
                  to="/categoria/automoviles"
                  isActive={currentURL === "/categoria/automoviles"}
                >
                  Automoviles
                </SubmenuLink>
              </SubmenuItem>
              <SubmenuItem>
                <SubmenuLink
                  to="/categoria/camionetas"
                  isActive={currentURL === "/categoria/camionetas"}
                >
                  Camionetas
                </SubmenuLink>
              </SubmenuItem>
              <SubmenuItem>
                <SubmenuLink
                  to="/categoria/camiones"
                  isActive={currentURL === "/categoria/camiones"}
                >
                  Camiones
                </SubmenuLink>
              </SubmenuItem>
            </Submenu>
          </Item>
          <Item desktop>
            <NavbarLink
              to="/busqueda"
              transparent={transparent ? 1 : 0}
              scrolled={scrolledMenu ? 1 : 0}
              isActive={currentURL === "/busqueda"}
            >
              Buscar llantas
            </NavbarLink>
          </Item>
          <Item desktop>
            <NavbarLink
              to="/servicios"
              transparent={transparent ? 1 : 0}
              scrolled={scrolledMenu ? 1 : 0}
              onMouseEnter={handleToggleSubmenu}
              isActive={currentURL === "/servicios"}
            >
              Servicios
              <ArrowDown color={theme.main.primaryColor} />
            </NavbarLink>
            <Submenu onMouseLeave={handleDisableSubmenu}>
              {services.map((service) => (
                <SubmenuItem key={service.id}>
                  <SubmenuLink
                    to={`/servicios/${removeAccents(service.name)}`}
                    isActive={
                      currentURL === `/servicios/${removeAccents(service.name)}`
                    }
                  >
                    {service.name}
                  </SubmenuLink>
                </SubmenuItem>
              ))}
            </Submenu>
          </Item>
          <Item desktop>
            <NavbarLink
              to="/descuentos"
              transparent={transparent ? 1 : 0}
              scrolled={scrolledMenu ? 1 : 0}
              isActive={currentURL === "/descuentos"}
            >
              Descuentos
            </NavbarLink>
          </Item>
          <Item tablet>
            <PseButton isSmall>Zona pagos</PseButton>
          </Item>
          <Item tablet>
            <WhatsappButton isSmall></WhatsappButton>
          </Item>
          <Item tablet>
            <Icon
              href="https://www.facebook.com/ServillantasLa57/"
              target="_blank"
              rel="noopener"
            >
              <FacebookIcon />
            </Icon>
            <Icon
              href="https://www.instagram.com/servillantasla57/"
              target="_blank"
              rel="noopener"
            >
              <InstagramIcon />
            </Icon>
          </Item>
        </List>
      </Container>

      <ToggleBtn onClick={handleToggleMenu}>
        <Lines isActive={isActive} />
      </ToggleBtn>

      <Mobile isActive={isActive}>
        <MobileList>
          <MobileItem tablet>
            <MobileLink to="#" onClick={handleToggleSubmenu}>
              Categorías <ArrowDown color={theme.main.primaryColor} />
            </MobileLink>
            <SubmenuMobile onClick={handleDisableSubmenu}>
              <SubmenuMobileItem>
                <SubmenuLink
                  to="/categoria/automoviles"
                  isActive={currentURL === "/categoria/automoviles"}
                >
                  Automoviles
                </SubmenuLink>
              </SubmenuMobileItem>
              <SubmenuMobileItem>
                <SubmenuLink
                  to="/categoria/camionetas"
                  isActive={currentURL === "/categoria/camionetas"}
                >
                  Camionetas
                </SubmenuLink>
              </SubmenuMobileItem>
              <SubmenuMobileItem>
                <SubmenuLink
                  to="/categoria/camiones"
                  isActive={currentURL === "/categoria/camiones"}
                >
                  Camiones
                </SubmenuLink>
              </SubmenuMobileItem>
            </SubmenuMobile>
          </MobileItem>
          <MobileItem tablet>
            <MobileLink to="/busqueda" isActive={currentURL === "/busqueda"}>
              Buscar llantas
            </MobileLink>
          </MobileItem>
          <MobileItem tablet>
            <MobileLink
              to="/servicios"
              isActive={currentURL === "/servicios"}
              onClick={handleToggleSubmenu}
            >
              Servicios <ArrowDown color={theme.main.primaryColor} />
            </MobileLink>
            <SubmenuMobile onClick={handleDisableSubmenu}>
              {services.map((service) => (
                <SubmenuMobileItem key={service.id}>
                  <SubmenuLink
                    to={`/servicios/${removeAccents(service.name)}`}
                    isActive={
                      currentURL === `/servicios/${removeAccents(service.name)}`
                    }
                  >
                    {service.name}
                  </SubmenuLink>
                </SubmenuMobileItem>
              ))}
            </SubmenuMobile>
          </MobileItem>
          <MobileItem>
            <MobileLinkSpecial
              href="http://www.servireencauche.com/"
              transparent={transparent ? 1 : 0}
              scrolled={scrolledMenu ? 1 : 0}
              target="_blank"
            >
              Reencauche
            </MobileLinkSpecial>
          </MobileItem>
          <MobileItem tablet>
            <MobileLink
              to="/descuentos"
              isActive={currentURL === "/descuentos"}
            >
              Descuentos
            </MobileLink>
          </MobileItem>
          <MobileItem>
            <MobileLink
              to="/quienes-somos"
              isActive={currentURL === "/quienes-somos"}
            >
              Quienes somos
            </MobileLink>
          </MobileItem>
          <MobileItem>
            <MobileLink to="/ofertas" isActive={currentURL === "/ofertas"}>
              Ofertas
            </MobileLink>
          </MobileItem>
          <MobileItem>
            <MobileLink to="/sedes" isActive={currentURL === "/sedes"}>
              Sedes
            </MobileLink>
          </MobileItem>
          <MobileItem>
            <MobileLink to="/contacto" isActive={currentURL === "/contacto"}>
              Contacto
            </MobileLink>
          </MobileItem>
          <MobileItem>
            <MobileLink to="/faqs" isActive={currentURL === "/faqs"}>
              Preguntas frecuentes
            </MobileLink>
          </MobileItem>
          <MobileItem mobile>
            <WhatsappButton isSmall>Contacta un asesor</WhatsappButton>
          </MobileItem>
          <MobileItem mobile>
            <PseButton isSmall>Zona pagos</PseButton>
          </MobileItem>
          <MobileItem mobile>
            <Icon
              href="https://www.facebook.com/ServillantasLa57/"
              target="_blank"
              rel="noopener"
            >
              <FacebookIcon />
            </Icon>
            <Icon
              href="https://www.instagram.com/servillantasla57/"
              target="_blank"
              rel="noopener"
            >
              <InstagramIcon />
            </Icon>
          </MobileItem>
        </MobileList>
      </Mobile>
    </Wrapper>
  );
}

export default withRouter(withTheme(Navbar));
