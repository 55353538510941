// @vendors
import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @utils
import createPagination from "../../utils/createPagination";

// @requests
import { GET_CARS } from "../../requests/queries/cars";
import { DELETE_CAR } from "../../requests/mutations/cars";

// @components
import Layout from "../../containers/dashboard/Layout";
import SearchDashboard from "../../containers/dashboard/search";
import DashboardList from "../../containers/dashboard/list";
import Pagination from "../../components/pagination";
import Loading from "../../components/Loading";

// @assets
import CarIcon from "../../assets/icons/car";

export default function DashboardCars({ history, location: { state } }) {
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = useState("");
  const [querySearch, setQuerySearch] = useState("");

  const { data, loading, error, refetch } = useQuery(GET_CARS, {
    variables: {
      numCars: itemsPerPage,
      currentBatch: itemsPerPage * (currentPage - 1),
      query: querySearch,
    },
  });
  const [deleteCar] = useMutation(DELETE_CAR, {
    onCompleted: function({ deleteCar }) {
      if (deleteCar) {
        refetch({
          numCars: itemsPerPage,
          currentBatch: itemsPerPage * (currentPage - 1),
          query: querySearch,
        });
      }
    },
  });

  useEffect(() => {
    refetch();

    if (state) {
      setNotification(state.notification);

      setTimeout(() => {
        setNotification("");
        history.replace({ pathname: "/administrador/carros", state: "" });
      }, 2000);
    }
  }, [history, state, refetch]);

  function onDelete(carId) {
    deleteCar({ variables: { id: carId } });
  }

  function handleSearch(e, query) {
    e.preventDefault();

    setQuerySearch(query);
  }

  function handlePagination(e, currentPage) {
    setCurrentPage(currentPage);
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { cars, total_cars } = data;
  const { pagesParsed, previousPage, nextPage } = createPagination(
    total_cars,
    itemsPerPage,
    currentPage,
  );

  return (
    <Layout
      title="Administrador - Carros"
      notification={notification}
      Icon={CarIcon}
      heading="Administrar carros"
    >
      <SearchDashboard
        handleSearch={handleSearch}
        queryValue={querySearch}
        placeholder="Buscar marca-referencia"
      />
      <DashboardList
        btnLink="carros/nuevo"
        btnText="carro"
        data={cars}
        onDelete={onDelete}
      />
      {total_cars && total_cars.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          handlePagination={handlePagination}
          nextPage={nextPage}
          pagesParsed={pagesParsed}
          previousPage={previousPage}
        />
      )}
    </Layout>
  );
}
