const branches = [
  {
    id: 1,
    title: "Servillantas la 57",
    address: "Calle 60 # 55-22",
    city: "Medellín",
    country: "Colombia",
    phone: "+57 (4) 448 01 30",
    link: "lat=6.2596291&lng=-75.5722581",
    lat: 6.259742,
    lng: -75.569989,
  },
  {
    id: 2,
    title: "Servireencauche medellín",
    address: "Carrera 57 # 62-36",
    city: "Medellín",
    country: "Colombia",
    phone: "+57 (4) 448 01 30",
    link: "lat=6.2522741&lng=-75.5770705",
    lat: 6.262411,
    lng: -75.572157,
  },
  {
    id: 3,
    title: "Tecnollantas antioquia",
    address: "Carrera 64 # 78-56",
    city: "Medellín",
    country: "Colombia",
    phone: "+57 (4) 257 27 26",
    link: "lat=6.2732901&lng=-75.5736641",
    lat: 6.2732901,
    lng: -75.5736641,
  },
  {
    id: 4,
    title: "Servireencauche cúcuta",
    address: "Av. 7 # 1-07 Barrio Panamericana",
    city: "Cúcuta",
    country: "Colombia",
    phone: "+57 (7) 578 29 12- 322 410 25 83",
    link: "lat=7.8954736&lng=-72.509864",
    lat: 7.8954736,
    lng: -72.509864,
  },
  {
    id: 5,
    title: "Servireencauche cartagena",
    address: "Transversal 54 # 27- 42",
    city: "Cartagena",
    country: "Colombia",
    phone: "+57 (5) 662 11 72 - 311 725 83 96",
    link: "lat=10.3912713&lng=-75.4898655",
    lat: 10.3912713,
    lng: -75.4898655,
  },
  {
    id: 6,
    title: "Servireencauche barranquilla",
    address: "Carrera 53 # 64 -196",
    city: "Barranquilla",
    country: "Colombia",
    phone: "+57 318 885 26 26",
    link: "lat=10.9956137&lng=-74.7981111",
    lat: 10.9956137,
    lng: -74.7981111,
  },
  {
    id: 7,
    title: "Servireencauche bogota",
    address: "Parque industrial San Jorge - bodega 29, manzana P3 Mosquera",
    city: "Cundinamarca",
    country: "Colombia",
    link: "lat=4.7141469&lng=-74.2446493",
    lat: 4.7141469,
    lng: -74.2446493,
  },
];

export default branches;
