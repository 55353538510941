import React, { useState } from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Button from "./button";
import TermsBox from "./terms-box";

const Wrapper = styled.section`
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.newsletter.backgroundColor};
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  max-width: 1180px;
  margin: 0 auto;
`;

const Content = styled.div`
  margin-bottom: 1.5rem;

  ${MEDIA_QUERIES.tablet} {
    width: 45%;
    margin-bottom: 0;
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.newsletter.textColor};
`;

const Line = styled.hr`
  width: 90%;
  margin: 0.2rem 0;
  border-color: ${({ theme }) => theme.newsletter.colorLine};

  ${MEDIA_QUERIES.desktopXL} {
    width: 60%;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.newsletter.textColor};
  font-size: 0.9rem;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${MEDIA_QUERIES.tablet} {
    width: 45%;
  }

  ${MEDIA_QUERIES.landscape} {
    justify-content: space-between;
  }
`;

const Message = styled.p`
  margin-top: 2rem;
  color: ${({ theme }) => theme.newsletter.textColor};
  text-align: center;
`;

const Email = styled.input`
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.8rem 2rem;
  border: 0;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.newsletter.backgroundColorInput};
  color: ${({ theme }) => theme.newsletter.colorLine};
  font-size: 1rem;
  outline: none;

  ::placeholder {
    color: ${({ theme }) => theme.newsletter.colorLine};
  }
`;

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [newsletter] = useMutation(
    gql`
      mutation sendNewsletter($email: String!) {
        newsletter(email: $email) {
          message
        }
      }
    `,
    {
      onCompleted(data) {
        const { newsletter } = data;

        setMessage(newsletter.message);

        setTimeout(() => setMessage(""), 3000);
      },
    },
  );

  function handleNewsletter(e) {
    e.preventDefault();

    newsletter({
      variables: {
        email,
      },
    });

    setEmail("");
  }

  return (
    <Wrapper>
      <Container>
        <Content>
          <Title>
            Suscribete <br /> a nuestro boletín
          </Title>
          <Line />
          <Description>Recibe noticias y promociones en tu correo.</Description>
        </Content>
        <Form onSubmit={handleNewsletter}>
          <Email
            type="email"
            placeholder="Escribe acá tu email"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
          />
          <TermsBox />
          <Button flat>Suscribirse</Button>
        </Form>
      </Container>
      {message.length > 0 && <Message>{message}</Message>}
    </Wrapper>
  );
}
