import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// @utils
import removeAccents from "../utils/removeAccents";

const Wrapper = styled(Link)`
  display: block;
  position: relative;
  z-index: 1;
  height: 450px;
  margin: 0 0.2rem;
  background-image: url(${({ bgimg }) => bgimg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const Container = styled.div`
  position: absolute;
  left: 50%;
  bottom: 2rem;
  width: 90%;
  min-height: 160px;
  transform: translateX(-50%);
`;

const IconContainer = styled.span`
  display: inline-block;
  margin-bottom: 1rem;
`;

const Icon = styled.img`
  height: 5rem;
`;

const Title = styled.h4`
  color: ${({ theme }) => theme.servicePageCard.secondaryColor};
`;

export default function ServicePageCard({ icon, name, image }) {
  return (
    <Wrapper to={`/servicios/${removeAccents(name)}`} bgimg={image}>
      <Container>
        <IconContainer>
          <Icon src={icon} alt={name} />
        </IconContainer>
        <Title>{name}</Title>
      </Container>
    </Wrapper>
  );
}
