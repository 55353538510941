// @vendors
import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

// @requests
import { GET_SERVICES } from "../requests/queries/services";

// @components
import Layout from "../containers/Layout";
import HeroService from "../containers/hero-service-section";
import HowDoesServiceWork from "../components/how-does-service-work";
import ServicesSection from "../containers/services-section";
import Loading from "../components/Loading";

const Wrapper = styled.section`
  width: 90%;
  max-width: 1260px;
  margin: 8rem auto 4rem;
`;

export default function Service() {
  const [titlePage, setTitlePage] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const { loading, error, data } = useQuery(GET_SERVICES);

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { services } = data;

  return (
    <Layout title={titlePage} metaDescription={metaDescription}>
      <Wrapper>
        <HeroService
          services={services}
          setTitlePage={setTitlePage}
          setMetaDescription={setMetaDescription}
        />
        <HowDoesServiceWork services={services} />
        {services.length > 1 && (
          <ServicesSection
            title="Otros servicios"
            isSingleService
            services={services}
          />
        )}
      </Wrapper>
    </Layout>
  );
}
