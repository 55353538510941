// @vendors
import React from "react";
import { useQuery } from "@apollo/react-hooks";

// @requests
import { GET_SERVICES } from "../requests/queries/services";

// @components
import Layout from "../containers/Layout";
import HeroAboutUs from "../containers/hero-about-us";
import MisionVisionSection from "../containers/mision-vision-section";
import ServicesSection from "../containers/services-section";
import BranchesSection from "../containers/branches-section";
import EnvironmentalSection from "../containers/environmental-section";

import Loading from "../components/Loading";

export default function AboutUs() {
  const { loading, error, data } = useQuery(GET_SERVICES);

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { services } = data;

  return (
    <Layout title="Quienes somos">
      <HeroAboutUs />
      <MisionVisionSection />
      {services.length > 0 && (
        <ServicesSection title="También te ofrecemos" services={services} />
      )}
      <BranchesSection />
      <EnvironmentalSection />
    </Layout>
  );
}
