import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

// @components
import SearchSelect from "../components/search-select";

// @constants
import { MEDIA_QUERIES } from "../constants";

const Wrapper = styled.header`
  position: relative;
  height: 300px;
  background-image: url(${({ bgHero }) => bgHero});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  ${MEDIA_QUERIES.landscape} {
    height: 350px;
  }

  ${MEDIA_QUERIES.desktopXL} {
    height: 450px;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${MEDIA_QUERIES.tablet} {
    top: 70%;
    left: 5rem;
    transform: translate(0%, -50%);
  }

  ${MEDIA_QUERIES.desktopXL} {
    left: 10rem;
  }
`;

const Title = styled.h2`
  display: inline-block;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid ${({ theme }) => theme.categoryHero.lineColor};
  color: ${({ theme }) => theme.categoryHero.textColor};
`;

const Select = styled.div`
  width: 220px;
`;

const Image = styled.div`
  display: none;

  ${MEDIA_QUERIES.tablet} {
    display: block;
    position: absolute;
    right: 50px;
    bottom: -20px;
  }

  ${MEDIA_QUERIES.landscape} {
    right: 120px;
    bottom: -40px;
  }

  ${MEDIA_QUERIES.desktopXL} {
    right: 200px;
  }

  img {
    height: 180px;

    ${MEDIA_QUERIES.landscape} {
      height: 250px;
    }

    ${MEDIA_QUERIES.desktopXL} {
      height: 300px;
    }
  }
`;

function CategoryHero({ title, image, bgHero, history }) {
  function handleCategory(selected) {
    const category = selected.value;

    history.push(`/categoria/${category}`)
  }

  return (
    <Wrapper bgHero={bgHero}>
      <Content>
        <Title>{title}</Title>
        <Select>
          <SearchSelect
            placeholder="Cambiar categoría"
            onChange={handleCategory}
            options={[
              { value: "automoviles", label: "automoviles" },
              { value: "camiones", label: "camiones" },
              { value: "camionetas", label: "camionetas" }
            ].filter(category => category.label !== title)}
          />
        </Select>
      </Content>

      <Image>
        <img src={image} alt={title} />
      </Image>
    </Wrapper>
  );
}

export default withRouter(CategoryHero);

CategoryHero.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  bgHero: PropTypes.string
};

CategoryHero.defaultProps = {
  title: "",
  image: "",
  bgHero: ""
};
