// @vendors
import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import XLSX from "xlsx";

// @requests
import { UPLOAD_INVENTORY } from "../../requests/mutations";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import Layout from "../../containers/dashboard/Layout";
import Button from "../../components/button";
import Loading from "../../components/Loading";

// @assets
import UploadIcon from "../../assets/icons/upload";

const Wrapper = styled.form`
  width: 90%;
  min-height: 320px;
  max-width: 1260px;
  margin: 3rem auto;
  text-align: center;

  ${MEDIA_QUERIES.tablet} {
    flex-direction: row;
  }
`;

const Description = styled.p`
  margin-bottom: 2rem;
`;

const InputFile = styled.label`
  display: inline-block;
  padding: 0.8rem 2rem;
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.main.primaryColor};
  transition: background 0.25s ease-in, color 0.25s ease-in;
  color: ${({ theme }) => theme.main.primaryColor};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.main.primaryColor};
    color: ${({ theme }) => theme.main.backgroundColor};
  }

  input[type="file"] {
    position: absolute;
    left: -100%;
    opacity: 0;
  }
`;

const FileText = styled.p`
  margin-top: 1rem;
  color: ${({ theme, error }) =>
    error ? theme.main.dangerColor : theme.main.defaultTextColor};
`;

const SubmitContainer = styled.div`
  margin-top: 4rem;
`;

const Message = styled.h3``;

export default function DashboardInventory() {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState(false);
  const [message, setMessage] = useState("");

  const [uploadInventory, { loading, error }] = useMutation(UPLOAD_INVENTORY, {
    onCompleted: function ({ uploadInventory }) {
      setMessage(uploadInventory.message);

      setTimeout(() => {
        setFile({});
        setFileName("");
        setFileError(false);
        setMessage("");
      }, 3000);
    },
  });

  function handleFile(e) {
    const fileInput = e.target.files[0];
    const validity = e.target.validity;

    if (validity.valid) {
      setFileName(fileInput.name);

      const reader = new FileReader();
      let result = [];

      reader.onload = function (e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        var parsedData = XLSX.utils.sheet_to_json(first_worksheet, {
          header: 1,
        });

        parsedData.forEach((row, index) => {
          if (index >= 4 && row[1]) {
            result.push({
              reference: row[1].trim(),
              available: row[4] > 0,
              price: parseInt(row[5], 10),
            });
          }
        });

        setFile(JSON.stringify(result));
      };
      reader.readAsArrayBuffer(fileInput);
    } else {
      setFileError(true);
    }
  }

  function handleInventory(e) {
    e.preventDefault();

    uploadInventory({ variables: { data: file } });
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  return (
    <Layout
      title="Administrador - Actualizar inventario"
      Icon={UploadIcon}
      heading="Actualizar inventario"
    >
      <Wrapper onSubmit={handleInventory}>
        {message ? (
          <Message>{message}</Message>
        ) : (
          <Fragment>
            <Description>
              ¿Deseas actualizar tu inventario? Haz clic en subir archivo
            </Description>
            <InputFile>
              <input type="file" onChange={handleFile} accept=".xlsx" />
              Subir archivo
            </InputFile>
            {fileError && (
              <FileText error>Hubo un error al cargar el archivo</FileText>
            )}

            {fileName && (
              <FileText>
                Archivo seleccionado: <strong>{fileName}</strong>
              </FileText>
            )}

            <SubmitContainer>
              <Button disabled={!fileName}>Actualizar</Button>
            </SubmitContainer>
          </Fragment>
        )}
      </Wrapper>
    </Layout>
  );
}
