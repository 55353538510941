import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Layout from "../containers/Layout";

const Wrapper = styled.section`
  width: 90%;
  max-width: 1260px;
  margin: 7rem auto 4rem;

  ${MEDIA_QUERIES.tablet} {
    margin-top: 8rem;
  }

  p,
  ul,
  ol {
    margin-bottom: 1.4rem;
  }

  li {
    margin-bottom: 1rem;
  }
`;

const Title = styled.h1`
  margin-bottom: 2.5rem;
  font-size: 1.6rem;
  text-align: center;

  ${MEDIA_QUERIES.tablet} {
    font-size: 2rem;
  }
`;

export default function Privacy() {
  return (
    <Layout title="Política de tratamiento de protección de datos personales">
      <Wrapper>
        <Title>
          Política de tratamiento de protección de datos personales de los
          titulares
        </Title>
        <p>
          <strong>SERVILLANTAS LA 57 S.A.S.</strong> <br />
          Dando cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de 2012,
          el Decreto Reglamentario 1377 de 2013 y su Decreto Único 1074 de 2015
          y demás Normas concordantes. SERVILLANTAS LA 57 S.A.S. adopta la
          presente política para el tratamiento de datos personales, la cual
          será informada a todos los titulares de los datos recolectados o que
          en el futuro se obtengan en el ejercicio de las actividades
          comerciales o laborales.
        </p>

        <p>
          De esta manera, SERVILLANTAS LA 57 S.A.S. manifiesta que garantiza los
          derechos de la privacidad, la intimidad, el buen nombre y la
          autonomía, en el tratamiento de los datos personales, y en
          consecuencia todas sus actuaciones se regirán por los principios de
          legalidad, finalidad, libertad, veracidad o calidad, transparencia,
          acceso y circulación restringida, seguridad y confidencialidad.
        </p>

        <p>
          Todas las personas que, en desarrollo de diferentes actividades
          contractuales, comerciales, laborales, entre otras, sean permanentes u
          ocasionales, llegaran a suministrar a SERVILLANTAS LA 57 S.A.S.
          cualquier tipo de información o dato personal, podrá conocerla,
          actualizarla y rectificarla.
        </p>

        <p>I. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO</p>

        <p>
          NOMBRE DE LA EMPRESA: SERVILLANTAS LA 57 S.A.S., que en adelante se
          denominará LA EMPRESA, sociedad comercial por acciones simplificada,
          creada mediante documentos privado de Julio 1 de 2011, registrada en
          la cámara de comercio del 7 de diciembre de 2011 libro 9 bajo el No
          21760.
        </p>

        <p>
          DOMICILIO Y DIRECCIÓN: LA EMPRESA tiene su domicilio en la ciudad de
          Medellín, y su sede principal se encuentra en la Calle 60 N 55-22,
          Barrio El Chagualo.
        </p>

        <p>
          CORREO ELECTRÓNICO:{" "}
          <a href="mailto:ventas4@servillantasla57.com">
            ventas4@servillantasla57.com
          </a>
        </p>

        <p>TELEFÓNO: 4480130</p>

        <p>II. MARCO LEGAL Y JURISPRUDENCIAL</p>

        <ul>
          <li>Artículo 15 de La Constitución Política.</li>
          <li>Ley 1266 de 2008.</li>
          <li>Ley 1581 de 2012.</li>
          <li>Decreto Único 1074 de 2015.</li>
          <li>Decretos Reglamentarios 1727 de 2009.</li>
          <li>Decreto Reglamentario 2952 de 2010.</li>
          <li>Decreto Reglamentario parcial 1377 de 2013</li>
          <li>Decreto Único 1074 de 2015</li>
          <li>Sentencias C – 1011 de 2008 de la Corte Constitucional.</li>
          <li>Sentencia C - 748 del 2011 de la Corte Constitucional</li>
        </ul>

        <p>III. ÁMBITO DE APLICACIÓN</p>

        <p>
          La presente política será aplicable a los datos personales registrados
          en cualquier base de datos de LA EMPRESA cuyo titular sea una persona
          natural.
        </p>

        <p>IV. DEFINICIONES</p>

        <p>
          Para los efectos de la presente política y en concordancia con la
          normatividad vigente en materia de protección de datos personales, se
          tendrán en cuenta las siguientes definiciones:
        </p>

        <p>
          Autorización: Consentimiento previo, expreso e informado del Titular
          para llevar a cabo el Tratamiento de datos personales.
        </p>

        <p>
          Aviso de privacidad: Comunicación verbal o escrita generada por el
          Responsable, dirigida al Titular para el tratamiento de sus datos
          personales, mediante la cual se le informa acerca de la existencia de
          las políticas de tratamiento de información que le serán aplicables,
          la forma de acceder a las mismas y las finalidades del tratamiento que
          se pretende dar a los datos personales.
        </p>

        <p>
          Base de Datos: Conjunto organizado de datos personales que sea objeto
          de tratamiento.
        </p>

        <p>
          Causahabiente: persona que ha sucedido a otra por causa del
          fallecimiento de ésta (heredero).
        </p>

        <p>
          Dato personal: Cualquier información vinculada o que pueda asociarse a
          una o varias personas naturales determinadas o determinables.
        </p>

        <p>
          Dato público: Es el dato que no sea semiprivado, privado o sensible.
          Son considerados datos públicos, entre otros, los datos relativos al
          estado civil de las personas, a su profesión u oficio ya su calidad de
          comerciante o de servidor público. Por su naturaleza, los datos
          públicos pueden estar contenidos, entre otros, en registros públicos,
          documentos públicos, gacetas y boletines oficiales y sentencias
          judiciales debidamente ejecutoriadas que no estén sometidas a reserva.
        </p>

        <p>
          Datos sensibles: Se entiende por datos sensibles aquellos que afectan
          la intimidad del Titular o cuyo uso indebido puede generar su
          discriminación, tales como que revelen el origen racial o étnico, la
          orientación política, las convicciones religiosas o filosóficas, la
          pertenencia a sindicatos, organizaciones sociales, de derechos humanos
          o que promueva intereses de cualquier partido político o que
          garanticen los derechos y garantías de
        </p>

        <p>
          Partidos políticos de oposición, así como los datos relativos a la
          salud, a la vida sexual, y los datos biométricos.
        </p>

        <p>
          Encargado del Tratamiento: Persona natural o jurídica, pública o
          privada, que por sí misma o en asocio con otros, realice el
          Tratamiento de datos personales por cuenta del Responsable del
          Tratamiento.
        </p>

        <p>
          Responsable del Tratamiento: Persona natural o jurídica, pública o
          privada, que por sí misma o en asocio con otros, decida sobre la base
          de datos y/o el Tratamiento de los datos.
        </p>

        <p>
          Titular: Persona natural cuyos datos personales sean objeto de
          Tratamiento.
        </p>

        <p>
          Tratamiento: Cualquier operación o conjunto de operaciones sobre datos
          personales, tales como la recolección, almacenamiento, uso,
          circulación o supresión.
        </p>
        <p>
          Transferencia: la transferencia de datos tiene lugar cuando el
          responsable y/o encargado del tratamiento de datos personales, ubicado
          en Colombia, envía la información o los datos personales a un
          receptor, que a su vez es responsable del tratamiento y se encuentra
          dentro o fuera del país.
        </p>
        <p>
          Transmisión: tratamiento de datos personales que implica la
          comunicación de los mismos dentro o fuera del territorio de la
          República de Colombia cuando tenga por objeto la realización de un
          tratamiento por el encargado por cuenta del responsable.
        </p>
        <p>V. PRINCIPIOS</p>
        <p>
          Para efectos de garantizar la protección de datos personales, LA
          EMPRESA aplicará de manera armónica e integral los siguientes
          principios, a la luz de los cuales se deberá realizar el tratamiento,
          transferencia y transmisión de datos personales
        </p>
        <p>
          Principio de legalidad en materia de Tratamiento de datos: El
          tratamiento de datos es una actividad reglada, la cual deberá estar
          sujeta a las disposiciones legales vigentes y aplicables rigen el
          tema.
        </p>
        <p>
          Principio de finalidad: la actividad del tratamiento de datos
          personales que realice LA EMPRESA o a la cual tuviere acceso,
          obedecerán a una finalidad legítima en consonancia con la Constitución
          Política de Colombia, la cual deberá ser informada al respectivo
          titular de los datos personales.
        </p>
        <p>
          Principio de libertad: el tratamiento de los datos personales sólo
          puede realizarse con el consentimiento, previo, expreso e informado
          del Titular. Los datos personales no podrán ser obtenidos o divulgados
          sin previa autorización, o en ausencia de mandato legal, estatutario,
          o judicial que releve el consentimiento.
        </p>
        <p>
          Principio de veracidad o calidad: la información sujeta a Tratamiento
          de datos personales debe ser veraz, completa, exacta, actualizada,
          comprobable y comprensible. Se prohíbe el Tratamiento de datos
          parciales, incompletos, fraccionados o que induzcan a error.
        </p>
        <p>
          Principio de transparencia: En el tratamiento de datos personales, LA
          EMPRESA garantizará al Titular su derecho de obtener en cualquier
          momento y sin restricciones, información acerca de la existencia de
          cualquier tipo de información o dato personal que sea de su interés o
          titularidad.
        </p>

        <p>
          Principio de acceso y circulación restringida: El tratamiento de datos
          personales se sujeta a los límites que se derivan de la naturaleza de
          éstos, de las disposiciones de la ley y la Constitución. En
          consecuencia, el tratamiento sólo podrá hacerse por personas
          autorizadas por el titular y/o por las personas previstas en la ley.
          Los datos personales, salvo la información pública, no podrán estar
          disponibles en internet u otros medios de divulgación o comunicación
          masiva, salvo que el acceso sea técnicamente controlable para brindar
          un conocimiento restringido sólo a los titulares o terceros
          autorizados conforme a la ley. Para estos propósitos la obligación de
          LA EMPRESA, será de medio.
        </p>
        <p>
          Principio de seguridad: la información sujeta a tratamiento por LA
          EMPRESA, se deberá manejar con las medidas técnicas, humanas y
          administrativas que sean necesarias para otorgar seguridad a los
          registros evitando su adulteración, pérdida, consulta, uso o acceso no
          autorizado o fraudulento.
        </p>
        <p>
          Principio de confidencialidad: Todas las personas que en LA EMPRESA,
          administren, manejen, actualicen o tengan acceso a informaciones de
          cualquier tipo que se encuentre en Bases de Datos, están obligadas a
          garantizar la reserva de la información, por lo que se comprometen a
          conservar y mantener de manera estrictamente confidencial y no revelar
          a terceros, toda la información que llegaren a conocer en la ejecución
          y ejercicio de sus funciones; salvo cuando se trate de actividades
          autorizadas expresamente por la ley de protección de datos. Esta
          obligación persiste y se mantendrá inclusive después de finalizada su
          relación con alguna de las labores que comprende el Tratamiento.
        </p>
        <p>VI. DERECHOS DEL TITULAR DE LA INFORMACIÓN</p>
        <p>
          De acuerdo con lo contemplado por la normatividad vigente aplicable en
          materia de protección de datos, los siguientes son los derechos de los
          titulares de los datos personales:
        </p>
        <ol>
          <li>
            Acceder, conocer, actualizar y rectificar sus datos personales
            frente a la EMPRESA en su condición de responsable del tratamiento.
            Este derecho se podrá ejercer, entre otros, frente a datos
            parciales, inexactos, incompletos, fraccionados, que induzcan a
            error, o aquellos cuyo tratamiento esté expresamente prohibido o no
            haya sido autorizado.
          </li>
          <li>
            Solicitar prueba de la autorización otorgada a la EMPRESA para el
            tratamiento de datos, mediante cualquier medio válido, salvo en los
            casos en que no es necesaria la autorización.
          </li>
          <li>
            Ser informado por LA EMPRESA, previa solicitud, respecto del uso que
            le ha dado a sus datos personales.
          </li>
          <li>
            Presentar ante la Superintendencia de Industria y Comercio, o la
            entidad que hiciere sus veces, quejas por infracciones a lo
            dispuesto en la ley 1581 de 2012 y las demás normas que la
            modifiquen, adicionen o complementen, previo trámite de consulta o
            requerimiento ante LA EMPRESA.
          </li>
          <li>
            Revocar la autorización y/o solicitar la supresión del dato cuando
            en el Tratamiento no se respeten los principios, derechos y
            garantías constitucionales y legales.
          </li>
          <li>
            Acceder en forma gratuita a sus datos personales que hayan sido
            objeto de tratamiento, al menos una vez cada mes calendario, y cada
            vez que existan modificaciones sustanciales de la presente política
            que motiven nuevas consultas.
          </li>
        </ol>
        <p>Estos derechos podrán ser ejercidos por:</p>
        <ul>
          <li>
            El titular, quien deberá acreditar su identidad en forma suficiente
            por los distintos medios que le ponga a disposición LA EMPRESA
          </li>
          <li>
            Los causahabientes del titular, quienes deberán acreditar tal
            calidad.
          </li>
          <li>
            El representante y/o apoderado del titular, previa acreditación de
            la representación o apoderamiento.
          </li>
          <li>Otro a favor o para el cual el titular hubiere estipulado.</li>
        </ul>
        <p>Derechos de los niños y adolescentes</p>
        <p>
          En el tratamiento de datos personales se asegurará el respeto a los
          derechos prevalentes de los menores.
        </p>
        <p>
          Queda proscrito el tratamiento de datos personales de menores, salvo
          aquellos datos que sean de naturaleza pública, y en este caso el
          tratamiento deberá cumplir con los siguientes parámetros:
        </p>
        <p>
          Responder y respetar el interés superior de los menores Asegurar el
          respeto de los derechos fundamentales de los menores.
        </p>

        <p>
          Es tarea del Estado y las entidades educativas de todo tipo proveer
          información y capacitar a los representantes legales y tutores sobre
          los eventuales riesgos a los que se enfrentan los niños, niñas y
          adolescentes respecto del Tratamiento indebido de sus datos
          personales, y proveer de conocimiento acerca del uso responsable y
          seguro por parte de niños, niñas y adolescentes de sus datos
          personales, su derecho a la privacidad y protección de su información
          personal y la de los demás.
        </p>

        <p>
          VII. DEBERES DE LA EMPRESA COMO RESPONSABLE Y ENCARGADA DEL
          TRATAMIENTO DE LOS DATOS PERSONALES
        </p>

        <p>
          LA EMPRESA reconoce la titularidad que de los datos personales
          ostentan las personas y en consecuencia ellas de manera exclusiva
          pueden decidir sobre los mismos. Por lo tanto, LA EMPRESA utilizará
          los datos personales para el cumplimiento de las finalidades
          autorizadas expresamente por el titular o por las normas vigentes.
        </p>
        <p>
          En el tratamiento y protección de datos personales, LA EMPRESA tendrá
          los siguientes deberes, sin perjuicio de otros previstos en las
          disposiciones que regulen o lleguen a regular esta materia:
        </p>
        <ol>
          <li>
            Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio
            del derecho de hábeas data.
          </li>
          <li>
            Solicitar y conservar, copia de la respectiva autorización otorgada
            por el titular para el tratamiento de datos personales.
          </li>
          <li>
            Informar debidamente al titular sobre la finalidad de la recolección
            y los derechos que le asisten en virtud de la autorización otorgada.
          </li>
          <li>
            Conservar la información bajo las condiciones de seguridad
            necesarias para impedir su adulteración, pérdida, consulta, uso o
            acceso no autorizado o fraudulento.
          </li>
          <li>
            Garantizar que la información sea veraz, completa, exacta,
            actualizada, comprobable y comprensible.
          </li>
          <li>
            Actualizar oportunamente la información, atendiendo de esta forma
            todas las novedades respecto de los datos del titular.
            Adicionalmente, se deberán implementar todas las medidas necesarias
            para que la información se mantenga actualizada.
          </li>
          <li>
            Rectificar la información cuando sea incorrecta y comunicar lo
            pertinente.
          </li>
          <li>
            Respetar las condiciones de seguridad y privacidad de la información
            del titular.
          </li>
          <li>
            Tramitar las consultas y reclamos formulados en los términos
            señalados por la ley.
          </li>
          <li>
            Identificar cuando determinada información se encuentra en discusión
            por parte del titular.
          </li>
          <li>
            Informar a solicitud del titular sobre el uso dado a sus datos.
          </li>
          <li>
            Informar a la autoridad de protección de datos cuando se presenten
            violaciones a los códigos de seguridad y existan riesgos en la
            administración de la información de los titulares.
          </li>
          <li>
            Cumplir los requerimientos e instrucciones que imparta la
            Superintendencia de Industria y Comercio sobre el tema en
            particular.
          </li>
          <li>
            Usar únicamente datos cuyo tratamiento esté previamente autorizado
            de conformidad con lo previsto en la ley 1581 de 2012.
          </li>
          <li>
            Velar por el uso adecuado de los datos personales de los niños,
            niñas y adolescentes, en aquellos casos en que se entra autorizado
            el tratamiento de sus datos.
          </li>
          <li>
            Registrar en la base de datos la leyenda "reclamo en trámite" en la
            forma en que se regula en la ley.
          </li>
          <li>
            Insertar en la base de datos la leyenda "información en discusión
            judicial" una vez notificado por parte de la autoridad competente
            sobre procesos judiciales relacionados con la calidad del dato
            personal.
          </li>
          <li>
            Abstenerse de circular información que esté siendo controvertida por
            el titular y cuyo bloqueo haya sido ordenado por la Superintendencia
            de Industria y Comercio
          </li>
          <li>
            Permitir el acceso a la información únicamente a las personas que
            pueden tener acceso a ella.
          </li>
          <li>
            Usar los datos personales del titular sólo para aquellas finalidades
            para las que se encuentre facultada debidamente y respetando en todo
            caso la normatividad vigente sobre protección de datos personales.
          </li>
        </ol>
        <p>VII. AUTORIZACIÓN Y CONSENTIMIENTO DEL TITULAR</p>
        <p>
          LA EMPRESA requiere del consentimiento libre, previo, expreso e
          informado del titular de los datos personales para el tratamiento de
          los mismos, excepto en los casos expresamente autorizados en la ley, a
          saber:
        </p>
        <ol>
          <li>
            Información requerida por una entidad pública o administrativa en
            ejercicio de sus funciones legales o por orden judicial.
          </li>
          <li>Datos de naturaleza pública.</li>
          <li>Casos de urgencia médica o sanitaria.</li>
          <li>
            Tratamiento de información autorizado por la ley para fines
            históricos, estadísticos o científicos.
          </li>
          <li>Datos relacionados con el Registro Civil de las Personas</li>
        </ol>
        <p>Manifestación de la autorización</p>
        <p>
          La autorización a LA EMPRESA para el tratamiento de los datos
          personales será otorgada por:
        </p>
        <ul>
          <li>
            El titular, quien deberá acreditar su identidad en forma suficiente
            por los distintos medios que le ponga a disposición LA EMPRESA
          </li>
          <li>
            Los causahabientes del titular, quienes deberán acreditar tal
            calidad.
          </li>
          <li>
            El representante y/o apoderado del titular, previa acreditación de
            la representación o apoderamiento.
          </li>
          <li>Otro a favor o para el cual el titular hubiere estipulado.</li>
        </ul>
        <p>Medios para otorgar la autorización</p>
        <p>
          LA EMPRESA obtendrá la autorización mediante diferentes medios, entre
          ellos el documento físico, electrónico, mensaje de datos, Internet,
          Sitios Web, o en cualquier otro formato que en todo caso permita la
          obtención del consentimiento mediante conductas inequívocas a través
          de las cuales se concluya que de no haberse surtido la misma por parte
          del titular o la persona legitimada para ello, los datos no se
          hubieran almacenado o capturado en la base de datos. La autorización
          será solicitada por LA EMPRESA de manera previa al tratamiento de los
          datos personales.
        </p>
        <p>Prueba de la autorización</p>
        <p>
          LA EMPRESA conservará la prueba de la autorización otorgada por los
          titulares de los datos personales para su tratamiento, para lo cual
          utilizará los mecanismos disponibles a su alcance en la actualidad al
          igual que adoptará las acciones necesarias para mantener el registro
          de la forma y fecha y en la que obtuvo ésta. En consecuencia, LA
          EMPRESA podrá establecer archivos físicos o repositorios electrónicos
          realizados de manera directa o a través de terceros contratados para
          tal fin.
        </p>
        <p>Revocatoria de la autorización.</p>
        <p>
          Los titulares de los datos personales pueden en cualquier momento
          revocar la autorización otorgada a LA EMPRESA para el tratamiento de
          sus datos personales o solicitar la supresión de los mismos, siempre y
          cuando no lo impida una disposición legal o contractual. LA EMPRESA
          establecerá mecanismos sencillos y gratuitos que permitan al titular
          revocar su autorización o solicitar la supresión sus datos personales,
          al menos por el mismo medio por el que lo otorgó.
        </p>
        <p>
          Para lo anterior, deberá tenerse en cuenta que la revocatoria del
          consentimiento puede expresarse, por una parte, de manera total en
          relación con las finalidades autorizadas, y por lo tanto LA EMPRESA
          deberá cesar cualquier actividad de tratamiento de los datos; y por la
          otra de manera parcial en relación con cierto tipo de tratamiento, en
          cuyo caso serán estos sobre los que cesarán las actividades de
          tratamiento, como para fines publicitarios, entre otros. En este
          último caso, LA EMPRESA podrá continuar tratando los datos personales
          para aquellos fines en relación con los cuales el titular no hubiera
          revocado su consentimiento.
        </p>
        <p>
          VIII. TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS Y FINALIDAD DEL
          MISMO
        </p>
        <p>
          El tratamiento de los datos personales de cualquier persona con la
          cual LA EMPRESA tuviere establecida o estableciera una relación,
          permanente u ocasional, lo realizará en el marco legal que regula la
          materia y en virtud de su condición de Empresa de rencauche de llantas
          usadas y servicios integrales para vehículos automotores y serán todos
          los necesarios para el cumplimiento de la misión corporativa. En todo
          caso, los datos personales podrán ser recolectados y tratados para:
        </p>
        <ol>
          <li>Desarrollar la misión de LA EMPRESA conforme a sus estatutos</li>
          <li>
            Cumplir las normas aplicables a proveedores y contratistas,
            incluyendo, pero sin limitarse a las tributarias y comerciales
          </li>
          <li>
            Cumplir lo dispuesto por el ordenamiento jurídico colombiano en
            materia laboral y de seguridad social, entre otras, aplicables a ex
            empleados, empleados actuales y candidatos a futuro empleo.
          </li>
          <li>
            Realizar encuestas relacionadas con los servicios o bienes de LA
            EMPRESA
          </li>
          <li>Desarrollar programas conforme a sus estatutos</li>
          <li>Cumplir todos sus compromisos contractuales.</li>
        </ol>
        <p>
          Para el tratamiento de datos personales de niños, niñas y adolescentes
          se procederá de acuerdo con lo contemplado en la presente política en
          el aparte relacionado con los derechos de éstos.
        </p>
        <p>Datos sensibles</p>
        <p>
          Para el caso de datos personales sensibles, LA EMPRESA podrá hacer uso
          y tratamiento de ellos cuando:
        </p>
        <ol>
          <li>
            El titular haya dado su autorización explícita, salvo en los casos
            que por ley no sea requerido el otorgamiento de dicha autorización.
          </li>
          <li>
            El tratamiento sea necesario para salvaguardar el interés vital del
            Titular y este se encuentre física o jurídicamente incapacitado. En
            estos eventos, los representantes legales deberán otorgar su
            autorización.
          </li>
          <li>
            El tratamiento sea efectuado en el curso de las actividades
            legítimas y con las debidas garantías por parte de una fundación,
            ONG, asociación o cualquier otro organismo sin ánimo de lucro, cuya
            finalidad sea política, filosófica, religiosa o sindical, siempre
            que se refieran exclusivamente a sus miembros o a las personas que
            mantengan contactos regulares por razón de su finalidad. En estos
            eventos, los datos no se podrán suministrar a terceros sin la
            autorización del titular.
          </li>
          <li>
            El Tratamiento se refiera a datos que sean necesarios para el
            reconocimiento, ejercicio o defensa de un derecho en un proceso
            judicial.
          </li>
          <li>
            El Tratamiento tenga una finalidad histórica, estadística o
            científica. En este evento deberán adoptarse las medidas conducentes
            a la supresión de identidad de los titulares.
          </li>
        </ol>
        <p>
          Sin perjuicio de las excepciones previstas en la ley, en el
          tratamiento de datos sensibles se requiere la autorización previa,
          expresa e informada del titular, la cual deberá ser obtenida por
          cualquier medio que pueda ser objeto de consulta y verificación
          posterior.
        </p>
        <p>IX. AVISO DE PRIVACIDAD</p>
        <p>
          El Aviso de Privacidad es el documento físico, electrónico o en
          cualquier otro formato, puesto a disposición del titular para
          informarle acerca del tratamiento de sus datos personales. A través de
          este documento se comunica al titular la información relacionada con
          la existencia de las políticas de tratamiento de información de LA
          EMPRESA y que le serán aplicables, la forma de acceder a las mismas y
          las características del tratamiento que se pretende dar a los datos
          personales.
        </p>
        <p>
          El aviso de privacidad deberá contener, como mínimo, la siguiente
          información:
        </p>
        <ol>
          <li>
            La identidad, domicilio y datos de contacto del responsable del
            tratamiento.
          </li>
          <li>
            El tipo de tratamiento al cual serán sometidos los datos y la
            finalidad del mismo.
          </li>
          <li>Los derechos del titular</li>
          <li>
            Los mecanismos generales dispuestos por el responsable para que el
            titular conozca la política de tratamiento de la información y los
            cambios sustanciales que se produzcan en ella. En todos los casos,
            debe informar al titular cómo acceder o consultar la política de
            tratamiento de información.
          </li>
          <li>
            El carácter facultativo de la respuesta relativa a preguntas sobre
            datos sensibles
          </li>
        </ol>
        <p>X. GARANTÍAS DEL DERECHO DE ACCESO</p>
        <p>
          Para garantizar el derecho de acceso del titular de los datos, LA
          EMPRESA pondrá a disposición de éste, previa acreditación de su
          identidad, legitimidad, o personalidad de su representante, sin costo
          o erogación alguna, de manera pormenorizada y detallada, los
          respectivos datos personales a través de todo tipo de medio,
          incluyendo los medios electrónicos que permitan el acceso directo del
          titular a ellos. Dicho acceso deberá ofrecerse sin límite alguno y le
          deben permitir al titular la posibilidad de conocerlos y actualizarlos
          en línea.
        </p>
        <p>
          XI. PROCEDIMIENTO PARA LA ATENCIÓN DE CONSULTAS, RECLAMOS, PETICIONES
          DE RECTIFICACIÓN, ACTUALIZACIÓN Y SUPRESIÓN DE DATOS
        </p>
        <ol>
          <li>
            Consultas: <br />
            <p>
              Los titulares o sus causahabientes podrán consultar la información
              personal del titular que repose en LA EMPRESA, quien suministrará
              toda la información contenida en el registro individual o que esté
              vinculada con la identificación del Titular.
            </p>
            <p>
              Con respecto a la atención de solicitudes de consulta de datos
              personales LA EMPRESA garantiza:
            </p>
            <ul>
              <li>
                Habilitar medios de comunicación electrónica u otros que
                considere pertinentes.
              </li>
              <li>
                Establecer formularios, sistemas y otros métodos simplificados,
                los cuales deberán ser informados en el aviso de privacidad.
              </li>
              <li>
                Utilizar los servicios de atención al cliente o de reclamaciones
                que tiene en operación.
              </li>
              <li>
                En cualquier caso, independientemente del mecanismo implementado
                para la atención de solicitudes de consulta, las mismas serán
                atendidas en un término máximo de diez
              </li>
            </ul>
            <p>
              (10) días hábiles contados a partir de la fecha de su recibo.
              Cuando no fuere posible atender la consulta dentro de dicho
              término, se informará al interesado antes del vencimiento de los
              10 días, expresando los motivos de la demora y señalando la fecha
              en que se atenderá su consulta, la cual en ningún caso podrá
              superar los cinco (5) días hábiles siguientes al vencimiento del
              primer plazo.
            </p>
            <ul>
              <li>
                Las consultas podrán formularse al correo
                <a href="mailto:ventas4@servillantasla57.com">
                  ventas4@servillantasla57.com
                </a>
              </li>
            </ul>
          </li>
          <li>
            Reclamos <br />
            <p>
              El Titular o sus causahabientes que consideren que la información
              contenida en una base de datos debe ser objeto de corrección,
              actualización o supresión, o cuando adviertan el presunto
              incumplimiento de cualquiera de los deberes contenidos en la ley,
              podrán presentar un reclamo ante LA EMPRESA, el cual será
              tramitado bajo las siguientes reglas:
            </p>
            <ol>
              <li>
                El reclamo del Titular se formulará mediante solicitud dirigida
                a la EMPRESA al correo electrónico ventas4@servillantasla57.com
                mediante comunicación escrita dirigida al Gerente, con la
                identificación del titular, la descripción de los hechos que dan
                lugar al reclamo, la dirección, y acompañando los documentos que
                se quiera hacer valer. Si el reclamo resulta incompleto, se
                requerirá al interesado dentro de los cinco (5) días siguientes
                a la recepción del reclamo para que subsane las fallas.
                Transcurridos dos (2) meses desde la fecha del requerimiento,
                sin que el solicitante presente la información requerida, se
                entenderá que ha desistido del reclamo.
              </li>
              <p>
                En caso de que quien reciba el reclamo no sea competente para
                resolverlo, dará traslado a quien corresponda en un término
                máximo de dos (2) días hábiles e informará de la situación al
                interesado.
              </p>
              <li>
                Una vez recibido el reclamo completo, éste se catalogará con la
                etiqueta "reclamo en trámite" y el motivo del mismo, en un
                término no mayor a dos (2) días hábiles. Dicha etiqueta se
                mantendrá hasta que el reclamo sea decidido.
              </li>
              <li>
                El término máximo para atender el reclamo será de quince (15)
                días hábiles contados a partir del día siguiente a la fecha de
                su recibo. Cuando no fuere posible atender el reclamo dentro de
                dicho término, se informará al interesado los motivos de la
                demora y la fecha en que se atenderá su reclamo, la cual en
                ningún caso podrá superar los ocho (8) días hábiles siguientes
                al vencimiento del primer término.
              </li>
            </ol>
          </li>
          <li>
            Petición de actualización y/o rectificación <br />
            <p>
              La EMPRESA rectificará y actualizará, a solicitud del titular, la
              información de éste que resulte ser incompleta o inexacta, de
              conformidad con el procedimiento y los términos antes señalados,
              para lo cual se tendrá en cuenta:
            </p>
            <ol>
              <li>
                El titular deberá allegar la solicitud al correo electrónico
                ventas4@servillantasla57.com o en medio físico dirigido a la
                Empresa, indicando la actualización y/o rectificación a realizar
                y aportará la documentación que sustente su petición.
              </li>
              <li>
                LA EMPRESA podrá habilitar mecanismos que le faciliten el
                ejercicio de este derecho al titular, siempre y cuando éstos lo
                beneficien. En consecuencia, se podrán habilitar medios
                electrónicos u otros que considere pertinentes, los cuales serán
                informados en el aviso de privacidad y se pondrán a disposición
                de los interesados en la página web.
              </li>
              <li>
                Petición de supresión de datos <br />
                <p>
                  El titular de los datos personales tiene el derecho de
                  solicitar a LA EMPRESA su supresión (eliminación) en
                  cualquiera de los siguientes eventos:
                </p>
                <ol>
                  <li>
                    Considere que los mismos no están siendo tratados conforme a
                    los principios, deberes y obligaciones previstas en la
                    normatividad vigente.
                  </li>
                  <li>
                    Hayan dejado de ser necesarios o pertinentes para la
                    finalidad para la cual fueron recabados.
                  </li>
                  <li>
                    Se haya superado el periodo necesario para el cumplimiento
                    de los fines para los que fueron recabados
                  </li>
                </ol>
                <p>
                  Esta supresión implica la eliminación total o parcial de la
                  información personal de acuerdo con lo solicitado por el
                  titular en los registros, archivos, bases de datos o
                  tratamientos realizados por LA EMPRESA. Sin embargo, este
                  derecho del titular no es absoluto y en consecuencia LA
                  EMPRESA podrá negar el ejercicio del mismo cuando:
                </p>
                <ol>
                  <li>
                    El titular tenga un deber legal o contractual de permanecer
                    en la base de datos.
                  </li>
                  <li>
                    La eliminación de datos obstaculice actuaciones judiciales o
                    administrativas vinculadas a obligaciones fiscales, la
                    investigación y persecución de delitos o la actualización de
                    sanciones administrativas.
                  </li>
                  <li>
                    Los datos sean necesarios para proteger los intereses
                    jurídicamente tutelados del titular; para realizar una
                    acción en función del interés público, o para cumplir con
                    una obligación legalmente adquirida por el titular.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <p>XII. REGISTRO NACIONAL DE BASE DE DATOS</p>
        <p>
          LA EMPRESA, se reserva, en los eventos contemplados en la ley y en sus
          estatutos y reglamentos internos, la facultad de mantener y catalogar
          determinada información que repose en sus bases o bancos de datos,
          como confidencial de acuerdo con las normas vigentes, sus estatutos y
          reglamentos.
        </p>
        <p>
          LA EMPRESA, procederá de acuerdo con la normatividad vigente y la
          reglamentación que para tal fin expida el Gobierno Nacional, a
          realizar el registro de sus bases de datos, ante El Registro Nacional
          de Bases de Datos (RNBD) que será administrado por la Superintendencia
          de Industria y Comercio. El RNBD., es el directorio público de las
          bases de datos sujetas a Tratamiento que operan en el país; y que será
          de libre consulta para los ciudadanos, de acuerdo con la normatividad
          que para tal efecto expida el Gobierno Nacional.
        </p>
        <p>XIII. SEGURIDAD DE LA INFORMACIÓN Y MEDIDAS DE SEGURIDAD</p>
        <p>
          Dando cumplimiento al principio de seguridad establecido en la
          normatividad vigente, LA EMPRESA adoptará las medidas técnicas,
          humanas y administrativas que sean necesarias para otorgar seguridad a
          los registros evitando su adulteración, pérdida, consulta, uso o
          acceso no autorizado o fraudulento.
        </p>
        <p>XIV. RESPONSABLE Y ENCARGADO DEL TRATAMIENTO DE DATOS PERSONALES</p>
        <p>
          LA EMPRESA será la responsable del tratamiento de los datos
          personales.
        </p>
        <p>XV. VIGENCIA</p>
        <p>
          La presente política rige a partir del 17 de mayo de 2017 y deja sin
          efectos los reglamentos o manuales especiales que se hubiesen podido
          adoptar con anterioridad por LA EMPRESA.
        </p>
        <br />
        <br />
        <br />
        <p>LA GERENCIA</p>
      </Wrapper>
    </Layout>
  );
}
