import React from "react";
import { Link } from "react-router-dom";
import styled, { withTheme } from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import WhatsappButton from "../components/whatsapp-button";

// @assets
import PhoneIcon from "../assets/icons/phone";
import MailIcon from "../assets/icons/mail";
import PointerIcon from "../assets/icons/pointer";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  ${MEDIA_QUERIES.landscape} {
    width: 50%;
    margin-bottom: 0;
    padding-right: 2rem;
  }

  ${MEDIA_QUERIES.desktop} {
    padding: 2rem;
  }
`;

const Title = styled.h3`
  text-align: center;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem 0;

  ${MEDIA_QUERIES.tablet} {
    flex-direction: row;
  }

  ${MEDIA_QUERIES.landscape} {
    justify-content: flex-start;
  }
`;

const Icon = styled.span`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.contactInfo.primaryColor};

  ${MEDIA_QUERIES.tablet} {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  svg {
    width: 1rem;
    height: 1rem;
    fill: ${({ theme }) => theme.contactInfo.secondaryColor};

    ${MEDIA_QUERIES.tablet} {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border-radius: 1.8rem;
  border: 1px solid ${({ theme }) => theme.contactInfo.primaryColor};
  color: ${({ theme }) => theme.contactInfo.primaryColor};
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;

  svg {
    height: 2.2rem;
    margin-right: 0.5rem;
    fill: ${({ theme }) => theme.contactInfo.primaryColor};
  }
`;

const Content = styled.p`
  text-align: center;

  strong {
    display: block;
  }
`;

function ContactInfo({ theme }) {
  return (
    <Wrapper>
      <Title>Contáctanos</Title>
      <Item>
        <Icon>
          <PhoneIcon color={theme.contactInfo.secondaryColor} />
        </Icon>

        <Content>
          <strong>Télefono principal</strong>
          +57 (4) 448 01 30
        </Content>
      </Item>

      <Item>
        <Icon>
          <MailIcon />
        </Icon>

        <Content>
          <strong>mercadeoservillantasla57@gmail.com</strong>
        </Content>
      </Item>

      <Item>
        <WhatsappButton>Contacta un asesor</WhatsappButton>
      </Item>

      <Item>
        <Button to="/sedes">
          <PointerIcon /> Mira nuestras sedes
        </Button>
      </Item>
    </Wrapper>
  );
}

export default withTheme(ContactInfo);
