// @vendors
import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";

//@components
import TextInput from "../text-input";
import Button from "../button";

const Container = styled.div`
  margin: 1rem auto 2.5rem;
  padding-left: 0.5rem;
`;

const Checkbox = styled.div`
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Input = styled.input`
  position: absolute;
  left: -100%;

  + label::after {
    content: none;
  }

  :checked + label::after {
    content: "";
  }
`;

const Label = styled.label`
  position: relative;
  padding-left: 1.6rem;
  font-size: 0.8rem;
  font-weight: bold;

  &::before,
  &::after {
    position: absolute;
  }

  &::before {
    content: "";
    display: inline-block;
    top: -2px;
    left: 0;
    height: 1.2rem;
    width: 1.2rem;
    border: 1px solid ${({ theme }) => theme.main.grayDarkerColor};
    border-radius: 4px;
  }

  &::after {
    content: "";
    display: inline-block;
    top: 3px;
    left: 5px;
    height: 6px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
  }
`;

const AddButton = styled.button`
  position: relative;
  padding-left: 2.5rem;
  border: 0;
  background: none;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 2rem;
  cursor: pointer;
  outline: none;

  &::before {
    content: "+";
    position: absolute;
    left: 0;
    width: 2rem;
    height: 2rem;
    padding: 0.3rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.main.primaryColor};
    color: ${({ theme }) => theme.main.backgroundColor};
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.2rem;
  }
`;

const AddCategory = styled.div`
  text-align: right;
`;

export default function DashboardCategories({
  selectedCat,
  categories,
  onChange,
  newCat,
  onChangeNewCat,
  sendNewCat,
}) {
  const [isActive, setIsActive] = useState(false);
  const [catSelected, setCatSelected] = useState(selectedCat);

  useEffect(() => {
    setCatSelected(selectedCat);
  }, [categories, selectedCat]);

  function handleActive() {
    setIsActive(!isActive);
  }

  return (
    <Fragment>
      <Container>
        {categories.map((category) => {
          const isChecked = catSelected.find((cat) => cat.id === category.id);

          return (
            <Checkbox key={category.id}>
              <Input
                type="checkbox"
                id={category.id}
                checked={isChecked ? true : false}
                onChange={(e) =>
                  onChange(e.target.checked, category, "categories")
                }
              />
              <Label htmlFor={category.id}>{category.name}</Label>
            </Checkbox>
          );
        })}
      </Container>
      {isActive ? (
        <AddCategory>
          <TextInput
            required
            value={newCat}
            onChange={onChangeNewCat}
            bordered
            placeholder="Agregar categoría"
          />
          <Button
            small
            onClick={() => {
              sendNewCat();
              setIsActive(false);
            }}
            disabled={newCat === ""}
          >
            Agregar
          </Button>
        </AddCategory>
      ) : (
        <AddButton type="button" onClick={handleActive}>
          Agregar categoría
        </AddButton>
      )}
    </Fragment>
  );
}
