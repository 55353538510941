// @vendors
import React, { useState } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @request
import { SEND_TO_EMAIL } from "../requests/mutations";
import { GET_EMAIL_SENDER } from "../requests/queries";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Modal from "./modal";
import TextInput from "./text-input";
import TermsBox from "./terms-box";
import Button from "./button";
import Loading from "./Loading";

// @assets
import ContactAvatar from "../assets/images/contact-avatar.jpg";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-align: center;
  padding: 1rem 0 2rem;

  h4 {
    color: ${({ theme }) => theme.contactModal.titleTextColor};
    margin-bottom: 5px;
  }

  p {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const Form = styled.form`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr) 1.2fr 25px;
  grid-row-gap: 10px;
  grid-column-gap: 15px;

  ${MEDIA_QUERIES.tabletMax} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr) 25px;
    grid-row-gap: 10px;
  }

  #termsBoxContainer {
    grid-column: 1/3;
    justify-self: center;

    ${MEDIA_QUERIES.tabletMax} {
      grid-column: 1/2;
    }
  }
`;

const FormButton = styled(Button).attrs(() => ({
  type: "submit",
}))`
  grid-column: 1/3;
  justify-self: center;
  align-self: center;

  ${MEDIA_QUERIES.tabletMax} {
    grid-column: 1/2;
  }
`;

const ContactPerson = styled.div`
  display: flex;
  align-items: center;

  ${MEDIA_QUERIES.tabletMax} {
    justify-content: center;
  }
`;

const ContactPersonTitle = styled.p`
  font-weight: bold;
`;

const ContactPersonName = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.contactModal.contactNameTextColor};
`;

const Contactimg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.contactModal.avararBorderRadiusColor};
  margin-right: 1rem;
`;

const Message = styled.p`
  grid-column: 1/3;
  justify-self: center;
  margin: 1rem 0;
  font-size: 1rem;
  text-align: center;

  ${MEDIA_QUERIES.tabletMax} {
    grid-column: 1/2;
  }
`;

function ContactModal({
  onCloseClick,
  author,
  tire: {
    brand,
    design,
    size: { width, profile, wheel },
  },
}) {
  const parsedTireName = `${brand.brand} ${design.design} - ${width.width}/${profile.profile}/${wheel.wheel}`;
  const [emailSender, setEmailSender] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { loading, error } = useQuery(GET_EMAIL_SENDER, {
    onCompleted({ sliders }) {
      if (sliders) {
        setEmailSender(sliders[0].author.email_sender);
      }
    },
  });
  const [emailMutation] = useMutation(SEND_TO_EMAIL, {
    onCompleted(data) {
      const { email } = data;
      setMessage(email.message);

      setTimeout(() => {
        setMessage("");
        onCloseClick();
      }, 4000);
    },
  });

  function handleEmail(e) {
    e.preventDefault();

    emailMutation({
      variables: {
        data: {
          name,
          email,
          phone,
          tire_name: parsedTireName,
          email_sender: emailSender,
        },
      },
    });
  }

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  return (
    <Modal onCloseClick={onCloseClick}>
      <Wrapper>
        <Title>
          <h4>Asesoría para mi llanta</h4>
          <p>{parsedTireName}</p>
        </Title>
        <Form onSubmit={handleEmail}>
          <TextInput
            id="name-field"
            value={name}
            label="Nombre"
            placeholder="Escribe tu nombre"
            required
            onChange={value => setName(value)}
          />
          <TextInput
            id="phone-field"
            type="number"
            value={phone}
            label="Teléfono"
            placeholder="Teléfono de contacto"
            required
            onChange={value => setPhone(value)}
          />
          <TextInput
            id="email-field"
            type="email"
            value={email}
            label="Email"
            placeholder="Email de contacto"
            required
            onChange={value => setEmail(value)}
          />
          <ContactPerson>
            <Contactimg src={ContactAvatar} alt="Asesor" />
            <div>
              <ContactPersonTitle>Tu asesor será</ContactPersonTitle>
              <ContactPersonName>Andrés</ContactPersonName>
            </div>
          </ContactPerson>
          <FormButton>Solicitar asesoría</FormButton>
          <TermsBox gray idTerm="contact-modal" />
          {message.length > 0 && <Message>{message}</Message>}
        </Form>
      </Wrapper>
    </Modal>
  );
}

export default ContactModal;
