// @vendors
import React from 'react';

// @components
import SizeFilterForm from "./filter-form-size"
import VehicleFilterForm from "./filter-form-vehicle"

// @styled-components
import { FilterSelectContainer } from "../filter-styles-components";

// @contants
import { MAIN_FILTER_CATEGORIES } from "../../../constants";

const getFilterForm = (activeCategory) => {
  let FormComponent;
  switch(activeCategory) {
    case MAIN_FILTER_CATEGORIES.size:
      FormComponent = SizeFilterForm;
      break;
    case MAIN_FILTER_CATEGORIES.vehicle:
      FormComponent = VehicleFilterForm;
      break;
    default:
      break
  }
  return FormComponent;
}

const MainFilterForm = ({ categoryState, activeCategory, handleModal }) => {
  const FormComponent = getFilterForm(activeCategory);
  return (
    <FilterSelectContainer gridArea="select-container">
      <FormComponent categoryState={categoryState} handleModal={handleModal} />
    </FilterSelectContainer>
  );
}

export default MainFilterForm;