// @vendors
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Btn = styled(Link)`
  display: inline-flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  border-radius: 1.8rem;
  color: ${({ theme }) => theme.main.defaultTextColor};
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.main.boxShadow};
`;

const Icon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.main.primaryColor};
  color: ${({ theme }) => theme.main.backgroundColor};
  font-size: 1.5rem;
  font-weight: normal;
`;

export default function DashboardBtn({ link, text }) {
  return (
    <Btn to={link}>
      <Icon>+</Icon>
      Nuevo {text}
    </Btn>
  );
}
