// @assets
import WheelIcon from "../assets/icons/wheel";
import CarIcon from "../assets/icons/car";
import OfferIcon from "../assets/icons/offer";
import BlogIcon from "../assets/icons/docs";
import ServicesIcon from "../assets/icons/gear";
import SliderIcon from "../assets/icons/picture";
import UploadIcon from "../assets/icons/upload";

const items = [
  {
    id: 1,
    title: "Llantas",
    icon: WheelIcon,
    link: "llantas",
  },
  {
    id: 2,
    title: "Carros",
    icon: CarIcon,
    link: "carros",
  },
  {
    id: 3,
    title: "Ofertas destacadas",
    icon: OfferIcon,
    link: "ofertas-destacadas",
  },
  {
    id: 4,
    title: "Ofertas",
    icon: BlogIcon,
    link: "ofertas",
  },
  {
    id: 5,
    title: "Servicios",
    icon: ServicesIcon,
    link: "servicios",
  },
  {
    id: 6,
    title: "Slider",
    icon: SliderIcon,
    link: "slider",
  },
  {
    id: 7,
    title: "Promo Modal",
    icon: OfferIcon,
    link: "promocion-modal",
  },
  {
    id: 8,
    title: "Subir inventario",
    icon: UploadIcon,
    link: "subir-inventario",
  },
];

export default items;
