// @vendors
import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @utils
import createPagination from "../../utils/createPagination";

// @requests
import { GET_TIRES } from "../../requests/queries/tires";
import { DELETE_TIRE } from "../../requests/mutations/tires";

// @components
import Layout from "../../containers/dashboard/Layout";
import SearchDashboard from "../../containers/dashboard/search";
import DashboardList from "../../containers/dashboard/list";
import Pagination from "../../components/pagination";
import Loading from "../../components/Loading";

// @assets
import ProductsIcon from "../../assets/icons/wheel";

export default function DashboardTires({ history, location: { state } }) {
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = useState("");
  const [querySearch, setQuerySearch] = useState("");

  const { data, loading, error, refetch } = useQuery(GET_TIRES, {
    variables: {
      numTires: itemsPerPage,
      currentBatch: itemsPerPage * (currentPage - 1),
      query: querySearch,
    },
  });
  const [deleteTire] = useMutation(DELETE_TIRE, {
    onCompleted: function ({ deleteTire }) {
      if (deleteTire) {
        refetch({
          numTires: itemsPerPage,
          currentBatch: itemsPerPage * (currentPage - 1),
          query: querySearch,
        });
      }
    },
  });

  useEffect(() => {
    refetch();

    if (state) {
      setNotification(state.notification);

      setTimeout(() => {
        setNotification("");
        history.replace({ pathname: "/administrador/llantas", state: "" });
      }, 2000);
    }
  }, [history, state, refetch]);

  function onDelete(tireId, image_path, icon_path, sizeId) {
    deleteTire({ variables: { id: tireId, sizeId } });
  }

  function handleSearch(e, query) {
    e.preventDefault();

    setQuerySearch(query);
  }

  function handlePagination(e, currentPage) {
    setCurrentPage(currentPage);
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { tires_dashboard, total_tires_dashboard } = data;
  const { pagesParsed, previousPage, nextPage } = createPagination(
    total_tires_dashboard,
    itemsPerPage,
    currentPage,
  );

  return (
    <Layout
      title="Administrador - Llantas"
      notification={notification}
      Icon={ProductsIcon}
      heading="Administrar llantas"
    >
      <SearchDashboard
        handleSearch={handleSearch}
        queryValue={querySearch}
        placeholder="Busqueda por referencia"
      />
      <DashboardList
        btnLink="llantas/nuevo"
        btnText="llanta"
        data={tires_dashboard}
        onDelete={onDelete}
      />
      {total_tires_dashboard && total_tires_dashboard.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          handlePagination={handlePagination}
          nextPage={nextPage}
          pagesParsed={pagesParsed}
          previousPage={previousPage}
        />
      )}
    </Layout>
  );
}
