// @vendors
import React, { useState, Fragment } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useMutation } from "@apollo/react-hooks";

// @requests
import { RESET_PASSWORD } from "../requests/mutations";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @assets
import logo from "../assets/images/logo-servillantas-black.png";

// @components
import Button from "../components/button";
import TextInput from "../components/text-input";
import CopyrightFooter from "../components/copyright-footer";

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
  border-top: 0.7rem solid ${({ theme }) => theme.login.primaryColor};

  ${MEDIA_QUERIES.tablet} {
    height: calc(100vh - 80px);
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  width: 90%;
  max-width: 350px;
  text-align: center;
`;

const Logo = styled.img`
  max-width: 100%;
`;

const Form = styled.form`
  padding: 3rem 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 ${({ theme }) => theme.main.boxShadow};

  input {
    margin-bottom: 1rem;
  }
`;

const Message = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.main.primary};
  text-align: center;
`;

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted(data) {
      const { resetPassword } = data;

      if (resetPassword.author) {
        setMessage("Se ha actualizado correctamente");
        setTimeout(() => setRedirectTo(true), 3000);
      }
    },
    onError(err) {
      if (err) {
        setMessage("El usuario no existe");

        setTimeout(() => setMessage(""), 3000);
      }
    },
  });

  function handleLogin(e) {
    e.preventDefault();
    resetPassword({ variables: { email, password } });

    setEmail("");
    setPassword("");
  }

  if (redirectTo) {
    return <Redirect to="/administrador" />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Reiniciar contraseña</title>
      </Helmet>

      <Wrapper>
        <Container>
          <Logo src={logo} />
          <Form onSubmit={handleLogin}>
            <TextInput
              type="email"
              id="email"
              value={email}
              placeholder="Correo electrónico"
              bordered
              onChange={setEmail}
            />
            <TextInput
              type="password"
              id="password"
              value={password}
              placeholder="Nueva contraseña"
              bordered
              onChange={setPassword}
            />
            <Button primary>Reiniciar</Button>

            {message !== "" && <Message>{message}</Message>}
          </Form>
        </Container>
      </Wrapper>

      <CopyrightFooter bgGray />
    </Fragment>
  );
}
