const steps = [
  {
    id: 1,
    title: "Busca tus llantas",
    description:
      'Desde el buscador que esta en la parte inicial de nuestro home o desde la opción del menú "Buscar llantas" puedes hacer la búsqueda por marca de vehículo y/o tamaño.',
  },
  {
    id: 2,
    title: "Selecciona tu llanta",
    description:
      "Una vez hayas ingresado los términos de búsqueda, el buscador te llevará a una pagina donde podras ver todas las opciones que hay disponibles para tu llantas.",
  },
  {
    id: 3,
    title: "Contacta un asesor",
    description:
      'Cuando tengas seleccionada la llanta que necesitas, debes darle click al botón "Quiero esta llanta", allí nos envías tus datos y en el menor tiempo posible un asesor te contactará para terminar tu compra.',
  },
];

export default steps;
