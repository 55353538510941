// @vendors
import React from "react";

export default function WheelIcon({ active, activeColor, disabledColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path id="a" d="M.005.031h31.964v31.891H.005z" />
      </defs>
      <g fillRule="evenodd">
        <g>
          <mask id="b" fill={active ? activeColor : disabledColor}>
            <use xlinkHref="#a" />
          </mask>
          <path
            fill={active ? activeColor : disabledColor}
            d="M15.984.031C7.204.031 0 7.218 0 15.977c0 8.758 7.205 15.945 15.984 15.945 8.78 0 15.985-7.187 15.985-15.945C31.969 7.218 24.764.03 15.984.03zm0 26.223c-5.68 0-10.302-4.61-10.302-10.277 0-5.667 4.622-10.278 10.302-10.278 5.681 0 10.303 4.61 10.303 10.278 0 5.667-4.622 10.277-10.303 10.277z"
          />
        </g>
        <path
          fill={active ? activeColor : disabledColor}
          d="M16 15a1 1 0 1 0 .001 2.001A1 1 0 0 0 16 15z"
        />
        <path
          fill={active ? activeColor : disabledColor}
          d="M19.939 12.442l1.567-2.304A8.402 8.402 0 0 0 17.71 8l-1.266 2.47h-1.888L13.29 8a8.403 8.403 0 0 0-3.797 2.138l1.568 2.304-.944 1.585-2.834-.165A8 8 0 0 0 7 16c0 .737.094 1.456.283 2.138l2.833-.166.945 1.585-1.568 2.304A8.405 8.405 0 0 0 13.29 24l1.266-2.47h1.888L17.71 24a8.403 8.403 0 0 0 3.796-2.139l-1.568-2.304.945-1.585 2.833.166c.19-.682.284-1.4.284-2.138 0-.737-.094-1.456-.283-2.138l-2.834.165-.944-1.585zM15.5 18.765c-1.562 0-2.833-1.24-2.833-2.765 0-1.525 1.27-2.765 2.833-2.765 1.562 0 2.833 1.24 2.833 2.765 0 1.524-1.271 2.765-2.833 2.765z"
        />
      </g>
    </svg>
  );
}
