// @vendors
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const Checkbox = styled.input`
  opacity: 0;

  + label::after {
    content: none;
  }

  :checked + label::after {
    content: "";
  }
`;

const Label = styled.label`
  position: relative;
  padding-left: 0.6rem;
  color: ${({ gray, theme }) =>
    gray ? theme.termsBox.textColorGray : theme.termsBox.textColor};
  font-size: 0.9rem;

  ::before,
  ::after {
    position: absolute;
    display: inline-block;
  }

  ::before {
    content: "";
    top: 0;
    left: -1rem;
    height: 16px;
    width: 16px;
    background-color: ${({ gray, theme }) =>
      gray ? theme.termsBox.textColorGray : theme.termsBox.primaryBackground};
  }

  ::after {
    content: "";
    top: 2px;
    left: -13px;
    height: 6px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-color: ${({ gray, theme }) =>
      gray ? theme.termsBox.checkAltColor : theme.termsBox.checkColor};
    transform: rotate(-45deg);
  }
`;

export default function TermsBox(props) {
  const idTerms = props.idTerm || "termsBox";

  return (
    <Wrapper id="termsBoxContainer">
      <Checkbox type="checkbox" id={idTerms} required />
      <Label htmlFor={idTerms} {...props}>
        Aceptar términos y condiciones
      </Label>
    </Wrapper>
  );
}

TermsBox.propTypes = {
  gray: PropTypes.bool,
};

TermsBox.defaultProps = {
  gray: false,
};
