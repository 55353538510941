import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/react-hooks";

// @requests
import { GET_EMAIL_SENDER } from "../requests/queries";
import { SEND_TO_EMAIL } from "../requests/mutations";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @data
import reasons from "../data/reasons";

// @components
import TextInput from "../components/text-input";
import TextBoxInput from "../components/textbox-input";
import SelectInput from "../components/select-input";
import Button from "../components/button";

const Wrapper = styled.form`
  width: 100%;
  text-align: center;

  ${MEDIA_QUERIES.landscape} {
    width: 50%;
    margin-bottom: 0;
  }

  ${MEDIA_QUERIES.desktop} {
    padding: 2rem;
  }
`;

const Title = styled.h3`
  margin-bottom: 2rem;
  text-align: center;
`;

const Message = styled.p`
  margin-top: 1.5rem;
  font-size: 1rem;
  text-align: center;
`;

export default function ContactForm() {
  const [emailSender, setEmailSender] = useState("");
  const [name, setName] = useState("");
  const [emailField, setEmailField] = useState("");
  const [phone, setPhone] = useState("");
  const [reason, setReason] = useState("");
  const [text, setText] = useState("");
  const [message, setMessage] = useState("");
  useQuery(GET_EMAIL_SENDER, {
    onCompleted: function({ sliders }) {
      if (sliders) {
        setEmailSender(sliders[0].author.email_sender);
      }
    },
  });
  const [email] = useMutation(SEND_TO_EMAIL, {
    onCompleted(data) {
      const { email } = data;
      setMessage(email.message);

      setTimeout(() => setMessage(""), 6000);
    },
  });

  function handleEmail(e) {
    e.preventDefault();

    email({
      variables: {
        data: {
          name,
          email: emailField,
          phone,
          reason: reason.value,
          message: text,
          email_sender: emailSender,
        },
      },
    });

    setName("");
    setEmailField("");
    setPhone("");
    setReason("");
    setText("");
  }

  return (
    <Wrapper onSubmit={handleEmail}>
      <Title>Envianos un mensaje</Title>
      <TextInput
        bordered
        required
        placeholder="Nombre"
        value={name}
        onChange={setName}
      />
      <TextInput
        type="email"
        bordered
        required
        placeholder="Email"
        value={emailField}
        onChange={setEmailField}
      />
      <TextInput
        type="number"
        bordered
        placeholder="Télefono"
        value={phone}
        onChange={setPhone}
      />
      <SelectInput
        placeholder="Motivo de contacto"
        options={reasons}
        onChange={setReason}
        value={reason}
      />
      <TextBoxInput
        required
        placeholder="Mensaje"
        value={text}
        onChange={setText}
      />
      <Button primary>Enviar</Button>
      {message.length > 0 && <Message>{message}</Message>}
    </Wrapper>
  );
}
