// @vendors
import React from "react";
import styled from "styled-components";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Wrapper = styled.section`
  margin: 2rem 5px;

  .rdw-editor-toolbar {
    border-color: ${({ theme }) => theme.main.grayColor};
  }

  .rdw-editor-main {
    min-height: 200px;
    padding: 0 1rem;
    border: 1px solid ${({ theme }) => theme.main.grayColor};

    img {
      max-width: 100%;
    }
  }
`;

export default function EditorWrapper({ content, onChange }) {
  return (
    <Wrapper>
      <Editor
        editorState={content}
        onEditorStateChange={editorState =>
          onChange(editorState, null, "editor")
        }
      />
    </Wrapper>
  );
}
