import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Button from "../components/button";

// @assets
import bgImage from "../assets/images/bg-tires-steps.jpg";

// @data
import steps from "../data/steps";

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  padding: 2rem;
  background-image: url(${bgImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${({ theme }) => theme.searchSteps.textColor};
  text-align: center;

  ${MEDIA_QUERIES.tablet} {
    padding: 3rem 5rem;
  }

  ${MEDIA_QUERIES.landscape} {
    height: 450px;
  }

  ${MEDIA_QUERIES.desktopXL} {
    height: 530px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${MEDIA_QUERIES.desktopXL} {
    width: 70%;
    margin: 0 auto;
  }
`;

const SearchStepContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.searchSteps.textColor};

  ${MEDIA_QUERIES.tablet} {
    padding: 2rem 1.5rem;
  }

  ${MEDIA_QUERIES.landscape} {
    width: calc(100% / 3 - 3rem);
    padding: 0 1.4rem;
    margin-bottom: 5rem;
    border-right: 1px solid ${({ theme }) => theme.searchSteps.textColor};
    border-bottom: 0;
  }

  :first-child {
    padding-top: 0;
  }

  :nth-child(3) {
    margin-bottom: 2rem;
    border: 0;

    ${MEDIA_QUERIES.landscape} {
      margin-bottom: 5rem;
    }
  }
`;

const SearchStepNumber = styled.span`
  color: ${({ theme }) => theme.searchSteps.textColorPrimary};
  font-size: 2rem;
  font-weight: bold;
`;

const Title = styled.h4`
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  font-size: 0.9rem;
`;

const Footer = styled.footer`
  p {
    margin-bottom: 1rem;
    font-weight: bold;
  }
`;

export default function SearchStepsSection() {
  return (
    <Wrapper>
      <Container>
        {steps.map(step => (
          <SearchStepContainer key={step.id}>
            <SearchStepNumber>{step.id}</SearchStepNumber>
            <Title>{step.title}</Title>
            <Description>{step.description}</Description>
          </SearchStepContainer>
        ))}

        <Footer>
          <p>Comienza ahora mismo a buscar tus llantas</p>
          <Button as={Link} to="/busqueda">
            Buscar llantas
          </Button>
        </Footer>
      </Container>
    </Wrapper>
  );
}
