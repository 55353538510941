// @vendors
import React from "react";
import styled, { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { string } from "prop-types";

// @utils
import removeAccents from "../utils/removeAccents";

// @components
import ServiceCard from "../components/service-card";

const Wrapper = styled.section`
  box-sizing: border-box;
  min-height: 530px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 54px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

function ServicesSection({
  match: {
    params: { slug },
  },
  title,
  services,
  isSingleService,
}) {
  const listServices = isSingleService
    ? services.filter(service => removeAccents(service.name) !== slug)
    : services;

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Container>
        {listServices.map(service => {
          return (
            <ServiceCard
              key={service.id}
              icon={service.icon}
              link={removeAccents(service.name)}
            >
              {service.name}
            </ServiceCard>
          );
        })}
      </Container>
    </Wrapper>
  );
}

export default withRouter(withTheme(ServicesSection));

ServicesSection.propTypes = {
  title: string,
};

ServicesSection.defaultProps = {
  title: "",
};
