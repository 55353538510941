// @vendors
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { string, object, number, bool } from "prop-types";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Button from "./button";

const Wrapper = styled.div`
  display: inline-block;
  width: 90%;
  max-width: 530px;
  margin: 1rem 0;

  ${MEDIA_QUERIES.desktop} {
    margin: 2rem;
  }
`;

const Container = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.tireCard.cardBorderColor};
  height: 251px;
  margin-top: calc(27px + 15px);
  display: flex;
  padding-right: 36px;
  box-sizing: border-box;
`;

const Image = styled.img`
  display: block;
  width: auto;
  height: 150px;
  position: relative;
  bottom: calc(27px + 15px);
  left: 0;

  ${MEDIA_QUERIES.tablet} {
    height: 250px;
  }

  ${MEDIA_QUERIES.landscape} {
    left: 15px;
  }
`;

export const BrandImage = styled.img`
  width: 138px;
  height: 46px;
  transform: translateY(-50%);
`;

export const TireContent = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${MEDIA_QUERIES.landscape} {
    margin-left: 45px;
  }
`;

export const TireInfo = styled.div`
  margin-top: ${({ withoutbrand }) => (withoutbrand ? "15px" : "-15px")};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 0 0 10px 10px;
`;

export const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3px;
  border-bottom: 1px solid ${({ theme }) => theme.tireCard.separatorColor};
  align-self: stretch;
  text-align: left;
`;

export const CardTitleReference = styled.h5`
  color: ${({ theme }) => theme.tireCard.RefAndSurfaceTextColor};
`;

export const StyledTireInfo = styled.p`
  font-weight: bold;

  span {
    color: ${({ theme }) => theme.tireCard.RefAndSurfaceTextColor};
  }
`;

export const PreviousPrice = styled.p`
  color: ${({ theme }) => theme.tireCard.previousPriceColor};
  text-decoration: line-through;
  font-weight: bold;
`;

export const ActualPrice = styled.div`
  color: ${({ theme }) => theme.tireCard.priceColor};
  font-size: 20px;
  font-weight: bold;

  p {
    display: inline;
  }
`;

const SaleBand = styled.div`
  right: -32px;
  bottom: 20px;
  position: absolute;
  height: 0;
  width: 135px;
  border: 33px solid ${({ theme }) => theme.tireCard.saleBandBgColor};
  border-bottom: none;
  border-right-color: transparent;
  border-left-color: transparent;
  transform: rotateZ(-45deg);

  span {
    color: ${({ theme }) => theme.tireCard.saleBandTextColor};
    position: absolute;
    top: -28px;
    left: -5px;
    right: 0;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
  }
`;

export default function TireCard({
  slug,
  image,
  brand,
  design,
  size,
  category,
  preference,
  price,
  discount,
  isOffer,
}) {
  return (
    <Wrapper>
      <Container>
        {image && <Image src={image} alt="tire" />}

        <TireContent>
          {brand.image && <BrandImage src={brand.image} alt={brand.brand} />}

          <TireInfo withoutbrand={!brand.image}>
            <CardTitle>
              <h5>{`${brand.brand} ${design.design}`}</h5>
              <CardTitleReference>
                {`${size.width.width}/${size.profile.profile}/R${size.wheel.wheel}`}
              </CardTitleReference>
            </CardTitle>
            <StyledTireInfo>{category.category}</StyledTireInfo>
            {preference && (
              <StyledTireInfo>
                Ideal para:{" "}
                <span className="text-gray">{preference.preference}</span>
              </StyledTireInfo>
            )}
            {discount !== 0 && (
              <PreviousPrice>
                antes:{" "}
                <NumberFormat
                  value={price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </PreviousPrice>
            )}
            <ActualPrice>
              <NumberFormat
                value={
                  discount !== 0
                    ? Math.ceil(price - (price * discount) / 100)
                    : price
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
              /<p>Unid.</p>
            </ActualPrice>
            <Button small as={Link} to={`/llantas/${slug}`}>
              Ver detalles
            </Button>
          </TireInfo>
          {discount !== 0 && (
            <SaleBand>
              <span>{isOffer ? `-${discount}%` : "OFERTA"}</span>
            </SaleBand>
          )}
        </TireContent>
      </Container>
    </Wrapper>
  );
}

TireCard.propTypes = {
  slug: string,
  image: string,
  brand: object,
  design: object,
  size: object,
  category: object,
  preference: object,
  price: number,
  discount: number,
  isOffer: bool,
};

TireCard.defaultProps = {
  slug: "",
  image: "",
  brand: {},
  design: {},
  size: {},
  category: {},
  preference: {},
  price: 0,
  discount: 0,
  isOffer: false,
};
