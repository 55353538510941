// @vendors
import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @assets
import bgMisionVision from "../assets/images/bg-reencauche.jpg";

const Wrapper = styled.section`
  position: relative;
  padding: 2rem 0;
  background: linear-gradient(
      ${({ theme }) => theme.environmental.backgroundGradientTop},
      ${({ theme }) => theme.environmental.backgroundGradientBottom} 80%
    ),
    url(${bgMisionVision});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  ${MEDIA_QUERIES.tablet} {
    padding: 0;
    height: 520px;
  }

  ${MEDIA_QUERIES.landscape} {
    height: 520px;
  }

  ${MEDIA_QUERIES.desktopXL} {
    height: 620px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1260px;
  margin: 0 auto;

  ${MEDIA_QUERIES.tablet} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${MEDIA_QUERIES.landscape} {
    flex-direction: row;
  }
`;

const Box = styled.div`
  margin: 1rem 1rem 2rem;
  color: ${({ theme }) => theme.main.backgroundColor};

  ${MEDIA_QUERIES.landscape} {
    margin: 0 1rem;
    width: 50%;
  }

  ${MEDIA_QUERIES.landscape} {
    margin: 0 2rem;
  }

  ${MEDIA_QUERIES.desktop} {
    margin: 0 5rem;
  }

  p {
    text-align: justify;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  text-align: center;
`;

export default function MisionVisionSection() {
  return (
    <Wrapper>
      <Container>
        <Box>
          <Title>Misión</Title>
          <p>
            Creamos experiencias de confianza, seguridad y transparencia, con soluciones integrales de innovación, generando así impacto positivo con calidad de vida en la sociedad.
          </p>
        </Box>
        <Box>
          <Title>Visión</Title>
          <p>
            En el 2020 Grupo Servireencauche será una empresa con desarrollo organizacional diferenciado con estrategias de mejoramiento continuo del sector automotriz, basados en innovación; consolidando nuestro liderazgo con tecnología y altos estándares de calidad que permitan el posicionamiento de nuestras marcas en el mercado nacional.
          </p>
        </Box>
      </Container>
    </Wrapper>
  );
}
