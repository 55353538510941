// @vendors
import React, { Fragment, useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";

// @components
import Layout from "../containers/Layout";
import MainFilter from "../components/main-filter/main-filter";
import ListProducts from "../containers/list-products";
import TireCard from "../components/tire-card";
import Loading from "../components/Loading";

// @utils
import {
  getSearchQueryFromUrl,
  parseTires,
  getSearchQueryFromState,
  buildTireSearchUrl,
  doesUrlMatchState,
} from "../utils/main-filter";

// @constants
import { MAIN_FILTER_TYPES } from "../hooks-store/main-filter-store";
import { MEDIA_QUERIES } from "../constants";

// @hooks
import { useStore } from "../hooks-store/store";

const SearchHeader = styled.header`
  margin: 8rem 0 4rem;
  text-align: center;

  h4 {
    color: ${({ theme }) => theme.listProducts.primaryColor};
  }
`;

const SearchDescription = styled.div`
  margin: 3rem 0 2rem;
  text-align: center;
`;

const SearchQuery = styled.h5`
  color: ${({ theme }) => theme.listProducts.primaryColor};
`;

const SearchList = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1260px;
  margin: 2rem auto 5rem;

  ${MEDIA_QUERIES.desktop} {
    justify-content: flex-start;
  }
`;

export default function Search({ location, history }) {
  const [orderPrice, setOrderPrice] = useState(undefined);
  const [orderBrand, setOrderBrand] = useState(undefined);
  const [{ mainFilterStore }, dispatch] = useStore();
  const {
    query,
    variables,
    searchString,
    valid,
    categoryState,
  } = getSearchQueryFromState(mainFilterStore);
  const updatedUrl = doesUrlMatchState(categoryState, location.search);
  const { loading, error, data } = useQuery(query, {
    variables,
    skip: !(valid && updatedUrl),
    fetchPolicy: "network-only",
  });

  function handleChangePrice(item) {
    setOrderPrice(item.value);
  }

  function handleChangeBrand(item) {
    setOrderBrand(item.value);
  }

  useEffect(() => {
    const {
      action,
      newState,
      valid: validUrl,
      category,
    } = getSearchQueryFromUrl(location.search);
    if (validUrl) {
      dispatch(MAIN_FILTER_TYPES.changeCategory, category);
      dispatch(action, newState);
    }
  }, [location.search, dispatch]);

  const onSearch = useCallback(() => {
    const search = buildTireSearchUrl(mainFilterStore);
    history.push({
      pathname: "/busqueda",
      search,
    });
    setOrderPrice(undefined);
    setOrderBrand(undefined);
  }, [history, mainFilterStore]);

  let tireData = null;

  if (data) {
    if (orderPrice || orderBrand) {
      const parsedData = parseTires(data, mainFilterStore.activeCategory);
      tireData = parsedData
        .sort((a, b) =>
          orderPrice !== "price_DESC" ? a.price - b.price : b.price - a.price,
        )
        .filter((item) =>
          orderBrand ? item.brand.brand === orderBrand : true,
        );
    } else {
      tireData = parseTires(data, mainFilterStore.activeCategory);
    }
  }

  return (
    <Layout title={tireData ? "Resultados para: " : "Nueva busqueda"}>
      {tireData ? null : (
        <SearchHeader>
          <h2>Llantas para todo tipo de terreno</h2>
          <h4>Encuentra tus llantas aquí</h4>
        </SearchHeader>
      )}

      <MainFilter
        mainFilterStore={mainFilterStore}
        isNewSearch={!tireData}
        onSearch={() => onSearch()}
      />
      {loading ? <Loading /> : null}
      {error ? <div>Ocurrió un error</div> : null}
      {tireData && !loading && !error ? (
        <Fragment>
          <SearchDescription>
            <h3>Resultados para:</h3>
            <SearchQuery>{searchString}</SearchQuery>
            <p>{tireData.length} llantas encontradas</p>
          </SearchDescription>

          {tireData.length ? (
            <>
              <ListProducts
                handleChangePrice={handleChangePrice}
                handleChangeBrand={handleChangeBrand}
                totalTires={parseTires(data, mainFilterStore.activeCategory)}
              />
              <SearchList>
                {tireData.map((tire) => (
                  <TireCard
                    key={tire.id}
                    slug={tire.slug}
                    image={tire.image}
                    brand={tire.brand}
                    design={tire.design}
                    size={tire.size}
                    category={tire.category}
                    preference={tire.preference}
                    price={tire.price}
                    discount={tire.discount}
                    isOffer={!!tire.discount}
                  />
                ))}
              </SearchList>
            </>
          ) : (
            <div>No se encontraron llantas</div>
          )}
        </Fragment>
      ) : null}
    </Layout>
  );
}
