// @vendors
import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { array } from "prop-types";
import { useHistory } from "react-router-dom";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import MainFilter from "../components/main-filter/main-filter";

// @utils
import { buildTireSearchUrl } from "../utils/main-filter";

// @hooks
import { useStore } from "../hooks-store/store";

const Wrapper = styled.section`
  display: flex;
  position: relative;
  z-index: 1;
  height: 700px; /* Original value 900px */
  flex-direction: column;
  justify-content: center;

  ::before {
    content: "";
    left: 0;
    top: 0;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.heroHome.imageOverlayColor};
  }

  .slick-slide img {
    width: 100%;
    height: 703px; /* Original value 903px */
    object-fit: cover;
  }
`;

const Container = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 1180px;
  margin-top: 2rem;
  transform: translate(-50%, -50%);

  ${MEDIA_QUERIES.desktop} {
    margin-top: 0;
  }
`;

const Title = styled.h1`
  align-self: center;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.heroHome.titleColor};
  font-size: 32px;
  text-align: center;

  ${MEDIA_QUERIES.desktop} {
    font-size: 48px;
  }
`;

const SubTitle = styled.h3`
  text-align: center;
  align-self: center;
  color: ${({ theme }) => theme.heroHome.subTitleColor};
  text-shadow: 2px 2px 3px ${({ theme }) => theme.main.boxShadow};
`;

const settings = {
  arrows: false,
  fade: true,
  slidesToShow: 1,
  infinite: true,
  autoplay: true,
  swipe: false,
  speed: 1000,
};

export default function HeroHome({ sliders }) {
  const { mainFilterStore } = useStore()[0];
  const history = useHistory();

  const onSearch = () => {
    const search = buildTireSearchUrl(mainFilterStore);
    history.push({
      pathname: "/busqueda",
      search,
    });
  };

  return (
    <Wrapper>
      <Slider {...settings}>
        {sliders.map((slide, index) => {
          if (slide.image !== null && slide.image !== "") {
            return (
              <img
                key={slide.id}
                src={slide.image}
                alt={`Imagen ${index + 1}`}
              />
            );
          }
          return null;
        })}
      </Slider>

      <Container>
        <Title>Llantas para todo tipo de terreno</Title>
        <SubTitle>Encuentra tus llantas aquí</SubTitle>
        <MainFilter
          mainFilterStore={mainFilterStore}
          onSearch={onSearch}
          isHomeSearch
          fromHomepage
        />
      </Container>
    </Wrapper>
  );
}

HeroHome.propTypes = {
  sliders: array,
};

HeroHome.defaultProps = {
  sliders: [],
};
