// This is the output format
// DD MM YYYY - 16 Ago 2019
const monthNames = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

function formatDateTime(dateTime) {
  const parseDate = new Date(dateTime);
  const day = parseDate.getDate();
  const month = monthNames[parseDate.getMonth()];
  const year = parseDate.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
}

export default formatDateTime;
