// @vendors
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// @constants
import { MEDIA_QUERIES } from "../constants";

const StyledThinButton = styled.button.attrs(({ disabled }) => ({
  disabled,
}))`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.35s ease-in-out;
  border-style: solid;
  border-width: 1px;
  padding: 0 10px;
  border-radius: 11px;
  font-size: 12px;
  font-weight: normal;
  height: 22px;
  background-color: ${({ theme }) => theme.button.primaryBackground};
  text-decoration: none;

  background-color: transparent;
  border-color: ${({ theme }) => theme.button.primaryBackground};
  color: ${({ theme }) => theme.button.primaryBackground};
  transition: border-color 0.35s ease-in-out, color 0.35s ease-in-out;

  :hover {
    border-color: ${({ theme }) => theme.button.backgroundHoverColor};
    color: ${({ theme }) => theme.button.backgroundHoverColor};
  }

  :focus {
    outline: none;
  }

  ${MEDIA_QUERIES.mobileMax} {
    padding: 0 5px;
    height: 15px;
    font-size: 11px;
  }
`;

export default function ThinButton(props) {
  return <StyledThinButton {...props}>{props.children}</StyledThinButton>;
}

ThinButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  flat: PropTypes.bool,
  disabled: PropTypes.bool,
};

ThinButton.defaultProps = {
  children: "",
  flat: false,
  disabled: false,
};
