// @vendors
import React from "react";

// @components
import Login from "../pages/login";

// @utils
import { getCookie } from "../utils/cookies";

const withAuth = (WrappedComponent, props) => {
  const hasAuthToken = getCookie("token") || false;

  return hasAuthToken ? <WrappedComponent {...props} /> : <Login />;
};

export default withAuth;
