// @vendors
import React from "react";
import styled from "styled-components";
import { number, array, func } from "prop-types";

// @assets
import ArrowDown from "../assets/icons/arrow-down";

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${({ center, right }) =>
    center ? "center" : right ? "flex-end" : "flex-start"};
  max-width: 1260px;
  margin: 5rem auto;
`;

const Btn = styled.button`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.pagination.primaryColor};
  cursor: pointer;
  outline: none;

  :hover {
    opacity: 0.7;
  }

  svg {
    width: 26px;
    height: 26px;
    transform: rotate(${({ previous }) => (previous ? "-270" : "270")}deg);
    fill: ${({ theme }) => theme.pagination.activeColor};
  }
`;

const PageNum = styled.button`
  margin: 0.5rem 0.8rem;
  padding: 0;
  border: 0;
  background: none;
  color: ${({ theme, active }) =>
    active ? theme.pagination.activeColor : theme.pagination.primaryColor};
  font-size: 1rem;
  font-weight: bolder;
  cursor: pointer;
  outline: none;

  :hover {
    color: ${({ theme }) => theme.pagination.activeColor};
  }
`;

export default function Pagination({
  currentPage,
  handlePagination,
  nextPage,
  pagesParsed,
  previousPage,
}) {
  return (
    <Wrapper center>
      <Btn
        type="button"
        previous
        onClick={e => handlePagination(e, previousPage)}
      >
        <ArrowDown />
      </Btn>
      {pagesParsed.map((page, index) => (
        <PageNum
          key={page}
          active={index + 1 === currentPage}
          onClick={e => handlePagination(e, page)}
        >
          {page}
        </PageNum>
      ))}
      <Btn type="button" onClick={e => handlePagination(e, nextPage)}>
        <ArrowDown />
      </Btn>
    </Wrapper>
  );
}

Pagination.propTypes = {
  currentPage: number,
  handlePagination: func,
  nextPage: number,
  pagesParsed: array,
  previousPage: number,
};

Pagination.defaultProps = {
  currentPage: 1,
  handlePagination: function() {},
  nextPage: 2,
  pagesParsed: [],
  previousPage: 1,
};
