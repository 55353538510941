function getCookie(name) {
  const cookieName = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return cookieName ? cookieName[2] : null;
}

function setCookie(name, value, days) {
  const createdAt = new Date();
  createdAt.setTime(createdAt.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = `${name}=${value};path=/;expires=${createdAt.toUTCString()}`;
}

function deleteCookie(name) {
  setCookie(name, "", -1);
}

export { getCookie, setCookie, deleteCookie };
