// @vendors
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";

// utils
import createPagination from "../utils/createPagination";

// @requests
import { GET_TIRES_CATEGORY } from "../requests/queries/tires";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Layout from "../containers/Layout";
import CategoryHero from "../containers/category-hero";
import FiltersCategory from "../containers/filters-category";
import TireCard from "../components/tire-card";
import Pagination from "../components/pagination";
import Loading from "../components/Loading";
import Environmental from "../containers/environmental-section";

// @assets
import carImg from "../assets/images/hero-car-category.png";
import vanImg from "../assets/images/hero-van-category.png";
import truckImg from "../assets/images/hero-truck-category.png";
import carBg from "../assets/images/bg-car-category.jpg";
import vanBg from "../assets/images/bg-van-category.jpg";

const CategoriesProducts = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1280px;
  margin: 2rem auto 5rem;

  ${MEDIA_QUERIES.desktop} {
    justify-content: flex-start;
  }
`;

function parsedCategory(category) {
  let parsedCategory = undefined;

  switch (category) {
    case "automoviles":
      parsedCategory = "Automovil";
      break;
    case "camionetas":
      parsedCategory = "Camioneta";
      break;
    case "camiones":
      parsedCategory = "Camion";
      break;
    default:
      break;
  }

  return parsedCategory;
}

export default function Categories({ match: { params } }) {
  const category = params && params.category;
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [orderPrice, setOrderPrice] = useState("price_DESC");
  const [orderBrand, setOrderBrand] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setItemsPerPage(10);
    setCurrentPage(1);
    setOrderPrice("price_DESC");
    setOrderBrand(undefined);
  }, [category]);

  let paramsToSend;

  if (orderBrand) {
    paramsToSend = {
      numTires: itemsPerPage,
      currentBatch: itemsPerPage * (currentPage - 1),
      category: parsedCategory(category),
      priceOrder: orderPrice,
      brand: orderBrand,
    };
  } else {
    paramsToSend = {
      numTires: itemsPerPage,
      currentBatch: itemsPerPage * (currentPage - 1),
      category: parsedCategory(category),
      priceOrder: orderPrice,
    };
  }

  const { loading, error, data } = useQuery(GET_TIRES_CATEGORY, {
    variables: paramsToSend,
  });

  function handleChangeNum(item) {
    setItemsPerPage(item.value);
    setCurrentPage(1);
  }

  function handleChangePrice(item) {
    setOrderPrice(item.value);
    setCurrentPage(1);
  }

  function handleChangeBrand(item) {
    setOrderBrand(item.value);
    setCurrentPage(1);
  }

  function handlePagination(e, currentPage) {
    window.scrollTo(0, 0);
    setCurrentPage(currentPage);
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { tires_category, total_tires_category } = data;
  let totalTiresBrand;
  if (orderBrand) {
    totalTiresBrand = total_tires_category.filter(
      (tire) => tire.brand.brand === orderBrand,
    );
  }

  const { pagesParsed, previousPage, nextPage } = createPagination(
    orderBrand ? totalTiresBrand : total_tires_category,
    itemsPerPage,
    currentPage,
  );

  return (
    <Layout title={category} navbarTransparent>
      <CategoryHero
        title={category}
        image={
          category === "automoviles"
            ? carImg
            : category === "camionetas"
            ? vanImg
            : truckImg
        }
        bgHero={
          category === "automoviles"
            ? carBg
            : category === "camionetas"
            ? vanBg
            : vanBg
        }
      />
      <FiltersCategory
        handleChangeNum={handleChangeNum}
        handleChangePrice={handleChangePrice}
        handleChangeBrand={handleChangeBrand}
        currentNum={itemsPerPage}
        currentPrice={orderPrice}
        categoryTires={total_tires_category}
      />

      <CategoriesProducts>
        {tires_category.map((tire) => (
          <TireCard key={tire.id} {...tire} />
        ))}
      </CategoriesProducts>

      {total_tires_category && total_tires_category.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          handlePagination={handlePagination}
          nextPage={nextPage}
          pagesParsed={pagesParsed}
          previousPage={previousPage}
        />
      )}

      <Environmental />
    </Layout>
  );
}
