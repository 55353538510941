// @vendors
import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";

// @requests
import { GET_SIZES } from "../../requests/queries/tires";
import {
  GET_CAR,
  GET_CAR_BRANDS,
  GET_CAR_REFERENCES,
  GET_CAR_MODELS,
} from "../../requests/queries/cars";
import {
  CREATE_CAR,
  UPDATE_CAR,
  CREATE_CAR_BRAND,
  CREATE_CAR_REFERENCE,
  CREATE_CAR_MODEL,
} from "../../requests/mutations/cars";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import Layout from "../../containers/dashboard/Layout";
import Loading from "../../components/Loading";
import Button from "../../components/button";
import SelectInput from "../../components/select-input";
import AddInput from "../../containers/dashboard/add-input";
import Updated from "../../components/dashboard/updated";

// @assets
import CarIcon from "../../assets/icons/car";
import TrashIcon from "../../assets/icons/trash";

const Wrapper = styled.form`
  display: block;
  width: 90%;
  max-width: 1260px;
  margin: 3rem auto;
`;

const Label = styled.label`
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  font-weight: bold;
`;

const LayoutColumns = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERIES.landscape} {
    flex-direction: row;
    align-items: ${({ start }) => (start ? "flex-start" : "flex-end")};
  }
`;

const Column33 = styled.div`
  ${MEDIA_QUERIES.landscape} {
    width: calc(100% / 3);
  }

  &:last-child {
    margin-right: 0;
  }
`;

const NewBtn = styled.button`
  margin: 0.5rem 0;
  border: 0;
  background: none;
  color: ${({ theme }) => theme.main.primaryColor};
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  outline: none;
`;

const InactiveMessage = styled.p`
  margin: 0.7rem 0;
  padding: 0 0.2rem;
  color: ${({ theme }) => theme.main.dangerColor};
  font-size: 0.9rem;
  font-weight: bold;
`;

const SizeContainer = styled.div`
  width: 90%;
  max-width: 550px;
  margin: 2rem auto;
`;

const SizeList = styled.ul`
  padding-left: 0.5rem;
  list-style-type: none;
`;

const SizeItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
`;

const DeleteSize = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.main.dangerColor};
    height: 1.2rem;
  }
`;

const SubmitContainer = styled.div`
  margin-top: 4rem;
  text-align: center;
`;

export default function DashboardCar({
  match: {
    params: { slug },
  },
}) {
  const isNewCar = slug === "nuevo";
  const [isSaved, setIsSaved] = useState(false);
  const [slugName, setSlugName] = useState("");
  const [carId, setCarId] = useState("");
  const [brand, setBrand] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [activeNewBrand, setActiveNewBrand] = useState(false);
  const [newBrand, setNewBrand] = useState("");
  const [reference, setReference] = useState("");
  const [referenceOptions, setReferenceOptions] = useState([]);
  const [activeNewReference, setActiveNewReference] = useState(false);
  const [newReference, setNewReference] = useState("");
  const [model, setModel] = useState("");
  const [modelOptions, setModelOptions] = useState([]);
  const [activeNewModel, setActiveNewModel] = useState(false);
  const [newModel, setNewModel] = useState("");
  const [sizes, setSizes] = useState([]);
  const [sizesRemoved, setSizesRemoved] = useState([]);
  const [sizesOptions, setSizesOptions] = useState([]);

  const { loading, error } = useQuery(GET_SIZES, {
    onCompleted: data => {
      const { get_sizes } = data;

      if (get_sizes) {
        const sizesParsed = get_sizes.map(
          ({
            id,
            width: { width },
            profile: { profile },
            wheel: { wheel },
          }) => {
            return {
              value: id,
              label: `${width}/${profile}/R${wheel}`,
            };
          },
        );
        setSizesOptions(sizesParsed);
      }
    },
  });
  useQuery(GET_CAR, {
    variables: {
      slug: slug !== "nuevo" ? slug : "",
    },
    onCompleted: function({ car }) {
      if (car) {
        getCarReferences({ variables: { carBrand: car.car_brand.id } });

        setSlugName(car.slug);
        setCarId(car.id);
        setBrand({ value: car.car_brand.id, label: car.car_brand.name });
        setReference({
          value: car.car_reference.id,
          label: car.car_reference.name,
        });
        setModel({ value: car.car_model.id, label: car.car_model.year });

        const sizesParsed = car.sizes.map(
          ({
            id,
            width: { width },
            profile: { profile },
            wheel: { wheel },
          }) => {
            return {
              value: id,
              label: `${width}/${profile}/R${wheel}`,
            };
          },
        );

        const filterSizesOptions = sizesOptions.filter(size => {
          let exist = false;

          sizesParsed.forEach(sizeParsed => {
            if (sizeParsed.value === size.value) {
              exist = true;
            }
          });

          return !exist;
        });

        setSizes(sizesParsed);
        setSizesOptions([...filterSizesOptions]);
      }
    },
  });
  useQuery(GET_CAR_BRANDS, {
    onCompleted: ({ carBrands }) => {
      const brandsParsed = carBrands.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        };
      });

      setBrandOptions(brandsParsed);
    },
  });
  useQuery(GET_CAR_MODELS, {
    onCompleted: ({ carModels }) => {
      const modelsParsed = carModels.map(({ id, year }) => {
        return {
          value: id,
          label: year,
        };
      });

      setModelOptions(modelsParsed);
    },
  });
  const [getCarReferences] = useLazyQuery(GET_CAR_REFERENCES, {
    onCompleted: function({ carReferences }) {
      const referencesParsed = carReferences.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        };
      });

      setReferenceOptions(referencesParsed);
    },
  });
  const [createCarBrand] = useMutation(CREATE_CAR_BRAND, {
    onCompleted: function({ createCarBrand }) {
      const newBrand = {
        label: createCarBrand.name,
        value: createCarBrand.id,
      };
      const brandsWithoutDuplicates = brandOptions.filter(
        ({ value }) => value !== newBrand.value,
      );

      setBrandOptions([...brandsWithoutDuplicates, newBrand]);
      setBrand(newBrand);
      setActiveNewBrand(false);
      setNewBrand("");
      getCarReferences({ variables: { carBrand: newBrand.value } });
      setReference("");
    },
  });
  const [createCarReference] = useMutation(CREATE_CAR_REFERENCE, {
    onCompleted: function({ createCarReference }) {
      const newReference = {
        label: createCarReference.name,
        value: createCarReference.id,
      };
      const referencesWithoutDuplicates = referenceOptions.filter(
        ({ value }) => value !== newReference.value,
      );

      setReferenceOptions([...referencesWithoutDuplicates, newReference]);
      setReference(newReference);
      setActiveNewReference(false);
      setNewReference("");
    },
  });
  const [createCarModel] = useMutation(CREATE_CAR_MODEL, {
    onCompleted: function({ createCarModel }) {
      const newModel = {
        label: createCarModel.year,
        value: createCarModel.id,
      };
      const modelsWithoutDuplicates = modelOptions.filter(
        ({ value }) => value !== newModel.value,
      );

      setModelOptions([...modelsWithoutDuplicates, newModel]);
      setModel(newModel);
      setActiveNewModel(false);
      setNewModel("");
    },
  });

  const [createCar] = useMutation(CREATE_CAR, {
    onCompleted: function({ createCar }) {
      if (createCar) {
        setIsSaved(true);
        window.location.reload();
      }
    },
  });

  const [updateCar] = useMutation(UPDATE_CAR, {
    onCompleted: function({ updateCar }) {
      if (updateCar) {
        setIsSaved(true);
        window.location.reload();
      }
    },
  });

  function onChange(event, other, type) {
    switch (type) {
      case "brand":
        setBrand(event);
        getCarReferences({ variables: { carBrand: event.value } });
        setReference("");
        break;
      case "newBrand":
        setNewBrand(event);
        break;
      case "reference":
        setReference(event);
        break;
      case "newReference":
        setNewReference(event);
        break;
      case "model":
        setModel(event);
        break;
      case "newModel":
        setNewModel(event);
        break;
      default:
        break;
    }
  }

  function handleBrand(e) {
    e.preventDefault();

    createCarBrand({ variables: { carBrand: newBrand } });
  }

  function handleReference(e) {
    e.preventDefault();

    createCarReference({
      variables: { carBrand: brand.value, carReference: newReference },
    });
  }

  function handleModel(e) {
    e.preventDefault();

    createCarModel({ variables: { carModel: parseInt(newModel, 10) } });
  }

  function handleSize(value) {
    const newSize = value;
    const currentSizes = sizes;
    const filterSizesOptions = sizesOptions.filter(
      size => size.value !== newSize.value,
    );

    setSizes([...currentSizes, newSize]);
    setSizesOptions([...filterSizesOptions]);
  }

  function handleRemoveSize(value) {
    const removedSize = value;
    const filterSizes = sizes.filter(size => size.value !== removedSize.value);

    setSizes([...filterSizes]);

    if (!isNewCar) {
      setSizesRemoved([...sizesRemoved, removedSize]);
    } else {
      setSizesOptions([...sizesOptions, removedSize]);
    }
  }

  function handleCar(e) {
    e.preventDefault();

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const parsedSlug = `${currentDay}${currentMonth}${currentYear}-${brand.label}-${reference.label}-${model.label}`;
    const parsedSizes = sizes.map(size => size.value);
    const sizesRemovedParsed = sizesRemoved.map(
      sizeRemoved => sizeRemoved.value,
    );

    const dataCar = {
      slug: isNewCar ? parsedSlug : slugName,
      car_reference_id: reference.value,
      car_model_id: model.value,
      sizes: parsedSizes,
    };

    if (isNewCar) {
      createCar({
        variables: {
          data: {
            car_brand_id: brand.value,
            ...dataCar,
          },
        },
      });
    } else {
      updateCar({
        variables: {
          id: carId,
          data: dataCar,
          size_to_remove: {
            sizes: sizesRemovedParsed,
          },
        },
      });
    }
  }

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  if (isSaved)
    return (
      <Updated
        to="/administrador/carros"
        message={`Se ha ${isNewCar ? "creado" : "actualizado"} correctamente`}
      />
    );

  return (
    <Layout
      title={`Administrador - ${isNewCar ? "Nuevo carro" : slug}`}
      Icon={CarIcon}
      heading={`${isNewCar ? "Nuevo" : "Actualizar"} carro`}
      nested
    >
      <Wrapper onSubmit={handleCar}>
        <LayoutColumns start>
          <Column33>
            <Label htmlFor="brand">Marca</Label>
            {activeNewBrand ? (
              <AddInput
                type="text"
                name="newBrand"
                value={newBrand}
                onChange={onChange}
                onClose={() => setActiveNewBrand(false)}
                onSave={handleBrand}
                placeholder="Nueva marca"
              />
            ) : (
              <Fragment>
                <SelectInput
                  id="brand"
                  options={brandOptions}
                  value={brand}
                  placeholder="Marca"
                  onChange={onChange}
                  isDisabled={!isNewCar}
                />
                {isNewCar && (
                  <NewBtn onClick={() => setActiveNewBrand(true)}>
                    + Agregar marca
                  </NewBtn>
                )}
              </Fragment>
            )}
          </Column33>
          <Column33>
            <Label htmlFor="reference">Referencia</Label>
            {activeNewReference ? (
              <AddInput
                type="text"
                name="newReference"
                value={newReference}
                onChange={onChange}
                onClose={() => setActiveNewReference(false)}
                onSave={handleReference}
                placeholder="Nueva referencia"
              />
            ) : (
              <Fragment>
                <SelectInput
                  id="reference"
                  options={referenceOptions}
                  value={reference}
                  placeholder="Referencia"
                  onChange={onChange}
                  isDisabled={brand === ""}
                />
                {brand !== "" ? (
                  <NewBtn onClick={() => setActiveNewReference(true)}>
                    + Agregar referencia
                  </NewBtn>
                ) : (
                  <InactiveMessage>Debes seleccionar una marca</InactiveMessage>
                )}
              </Fragment>
            )}
          </Column33>
          <Column33>
            <Label htmlFor="model">Modelo</Label>
            {activeNewModel ? (
              <AddInput
                type="text"
                name="newModel"
                value={newModel}
                onChange={onChange}
                onClose={() => setActiveNewModel(false)}
                onSave={handleModel}
                placeholder="Nuevo modelo"
              />
            ) : (
              <Fragment>
                <SelectInput
                  id="model"
                  options={modelOptions}
                  value={model}
                  placeholder="Modelo"
                  onChange={onChange}
                />
                <NewBtn onClick={e => setActiveNewModel(true)}>
                  + Agregar modelo
                </NewBtn>
              </Fragment>
            )}
          </Column33>
        </LayoutColumns>

        <SizeContainer>
          <Label>Dimensiones</Label>

          {sizes.length > 0 && (
            <SizeList>
              {sizes.map(size => {
                return (
                  <SizeItem key={size.value}>
                    {size.label}
                    <DeleteSize
                      type="button"
                      onClick={() => handleRemoveSize(size)}
                    >
                      <TrashIcon />
                    </DeleteSize>
                  </SizeItem>
                );
              })}
            </SizeList>
          )}

          <SelectInput
            closeMenuOnSelect
            inputValue=""
            options={sizesOptions}
            placeholder="Selecciona las dimensiones que apliquen"
            onChange={handleSize}
          />
        </SizeContainer>

        <SubmitContainer>
          <Button
            type="submit"
            disabled={
              brand === "" ||
              reference === "" ||
              model === "" ||
              sizes.length <= 0
            }
          >
            {isNewCar ? "Guardar y publicar" : "Actualizar"}
          </Button>
        </SubmitContainer>
      </Wrapper>
    </Layout>
  );
}
