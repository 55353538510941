import React from "react";
import styled from "styled-components";

// @components
import WhatsappButton from "../components/whatsapp-button";

const Wrapper = styled.section`
  margin-bottom: 5rem;
  text-align: center;
`;

const Title = styled.h3`
  margin-bottom: 1rem;
`;

function Cta() {
  return (
    <Wrapper>
      <Title>¿Buscas una asesoría personalizada?</Title>
      <WhatsappButton>Contacta un asesor</WhatsappButton>
    </Wrapper>
  );
}

export default Cta;
