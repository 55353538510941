import gql from "graphql-tag";

const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!) {
    createCategory(name: $name) {
      id
      name
    }
  }
`;

const CREATE_POST = gql`
  mutation createPost($data: CreatePostInput!) {
    createPost(data: $data) {
      id
      title
      image
      body
    }
  }
`;

const UPDATE_POST = gql`
  mutation updatePost($id: ID!, $data: UpdatePostInput!) {
    updatePost(id: $id, data: $data) {
      id
      title
      image
      body
    }
  }
`;

const DELETE_POST = gql`
  mutation deletePost($id: ID!) {
    deletePost(id: $id) {
      id
      title
    }
  }
`;

export { CREATE_CATEGORY, CREATE_POST, UPDATE_POST, DELETE_POST };
