// @vendors
import React from 'react';
import uniqWith from 'lodash.uniqwith';
import isEqual from 'lodash.isequal';
import capitalize from "lodash.capitalize"
import { useQuery } from "@apollo/react-hooks";

// @constants
import { MAIN_FILTER_TYPES } from "../../../../hooks-store/main-filter-store"

// @components
import { StyledFilterModalOption } from "../../filter-styles-components";

// @store-hooks
import { useStore } from "../../../../hooks-store/store";

// @queries
import { GET_CAR_REFERENCE_MODELS } from "../../../../requests/queries/cars";

const ReferenceOptions = ({ stateBrand, stateReference }) => {
  const dispatch = useStore(false)[1];
  const { loading, error, data: { car_models } = { car_models: []} } = useQuery(GET_CAR_REFERENCE_MODELS, {
    variables: { carBrand: stateBrand, carReference: stateReference }
  });
  if (loading) {
    return <div>Loading</div>
  }

  if (error) {
    return <div>Ocurrió un error cargando los modelos</div>
  }

  return uniqWith(
    car_models.map(({ car_model }) => ({ year: car_model.year, id: car_model.id })),
    isEqual
  ).map(model => (
      <StyledFilterModalOption
        key={model.id}
        filterOption
        onClick={() => {
          dispatch(
            MAIN_FILTER_TYPES.updateVehicleField,
            { fieldName: "model", value: model.year }
          )
        }}
      >
        {capitalize(model.year)}
      </StyledFilterModalOption>
    )
  );
}

export default ReferenceOptions;
