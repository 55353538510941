// @vendors
import React from "react";

export default function DocsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="42"
      viewBox="0 0 36 42"
    >
      <path
        fillRule="evenodd"
        d="M29.571 10.5C28.202 10.5 27 9.239 27 7.875V2.663l6.429 7.837H29.57zM27 21.021H15.429c-.71 0-1.286-.588-1.286-1.311 0-.725.576-1.311 1.286-1.311H27c.71 0 1.286.586 1.286 1.31 0 .724-.576 1.312-1.286 1.312zm0 6.556H15.429c-.71 0-1.286-.588-1.286-1.311 0-.725.576-1.311 1.286-1.311H27c.71 0 1.286.586 1.286 1.31 0 .724-.576 1.312-1.286 1.312zm-2.571 11.798H5.143c-1.35 0-2.572-1.288-2.572-2.625V10.5c0-1.952 1.574-2.625 3.858-2.625V31.5c0 2.702 2.422 5.25 5.142 5.25 0 0 13.94-.014 15.44-.014 0 1.592-1.023 2.639-2.582 2.639zM27 .041L11.571 0C8.851 0 6.43 2.548 6.43 5.25l-1.485.035C2.223 5.285 0 7.798 0 10.5v26.25C0 39.452 2.422 42 5.143 42h19.286c2.72 0 5.142-2.548 5.142-5.25h1.286c2.72 0 5.143-2.548 5.143-5.25V10.53L27 .04z"
      />
    </svg>
  );
}
