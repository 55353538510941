// @vendors
import React, { useState } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @requests
import { GET_SLIDERS } from "../../requests/queries";
import { UPDATE_SLIDER, DELETE_IMAGE } from "../../requests/mutations";

// @components
import Layout from "../../containers/dashboard/Layout";
import DashboardImage from "../../components/dashboard/image";
import Button from "../../components/button";
import Loading from "../../components/Loading";
import Updated from "../../components/dashboard/updated";

// @assets
import PictureIcon from "../../assets/icons/picture";

const Wrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: 900px;
  margin: 3rem auto;
`;

const Submit = styled.div`
  width: 100%;
  text-align: center;
`;

export default function DashboardSlider() {
  const ENV = process.env.REACT_APP_ENV || "dev";
  const [sliders, setSliders] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const { loading, error } = useQuery(GET_SLIDERS, {
    onCompleted(data) {
      const { sliders } = data;
      setSliders(sliders);
    },
  });
  const [updateSlider] = useMutation(UPDATE_SLIDER);
  const [deleteImage] = useMutation(DELETE_IMAGE);

  function onChange(event, sliderN, type, pathImg) {
    const values = [...sliders];

    values[sliderN].image = event;
    values[sliderN].image_path = pathImg;

    if (values[0]["__typename"]) delete values[0]["__typename"];
    if (values[1]["__typename"]) delete values[1]["__typename"];
    if (values[2]["__typename"]) delete values[2]["__typename"];
    if (values[3]["__typename"]) delete values[3]["__typename"];
    if (values[4]["__typename"]) delete values[4]["__typename"];

    setSliders(values);
  }

  function onDelete(event, sliderN) {
    const values = [...sliders];

    deleteImage({ variables: { path_img: event } });

    values[sliderN].image = "";
    values[sliderN].image_path = "";

    if (values[0]["__typename"]) delete values[0]["__typename"];
    if (values[1]["__typename"]) delete values[1]["__typename"];
    if (values[2]["__typename"]) delete values[2]["__typename"];
    if (values[3]["__typename"]) delete values[3]["__typename"];
    if (values[4]["__typename"]) delete values[4]["__typename"];

    setSliders(values);
  }

  function handleSliders(e) {
    e.preventDefault();

    updateSlider({ variables: { data: sliders } });
    setIsSaved(true);
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;
  if (isSaved) return <Updated />;

  return (
    <Layout
      title="Administrador - Slider"
      Icon={PictureIcon}
      heading="Administrar sliders"
    >
      <Wrapper onSubmit={handleSliders}>
        {sliders.map((item, index) => (
          <DashboardImage
            key={item.id}
            image={item.image}
            pathImg={item.image_path}
            sizes="1920px x 1080px"
            folder={ENV === "dev" ? `dev/sliders` : "sliders"}
            onChange={onChange}
            onDelete={onDelete}
            position={index}
          />
        ))}
        <Submit>
          <Button>Guardar y publicar</Button>
        </Submit>
      </Wrapper>
    </Layout>
  );
}
