// @vendors
import uniqWith from 'lodash.uniqwith';
import isEqual from 'lodash.isequal';

// @queries
import { GET_TIRES_BY_VEHICLE, GET_TIRES_BY_SIZE } from '../requests/queries/tires';

// @constants
import { MAIN_FILTER_CATEGORIES } from "../constants";
import { MAIN_FILTER_TYPES } from "../hooks-store/main-filter-store";

export const buildTireSearchUrl = (mainFilterStore) => {
  const activeCategory = mainFilterStore.activeCategory;
  const categoryState = mainFilterStore[mainFilterStore.activeCategory];
  switch(activeCategory) {
    case MAIN_FILTER_CATEGORIES.vehicle:
      const carCaregory = `${encodeURIComponent('category')}=${encodeURIComponent(activeCategory)}`;
      const brand = `${encodeURIComponent('brand')}=${encodeURIComponent(categoryState.brand)}`;
      const reference = `${encodeURIComponent('reference')}=${encodeURIComponent(categoryState.reference)}`;
      const model = `${encodeURIComponent('model')}=${encodeURIComponent(categoryState.model)}`;
      const searchVehicle = [
        carCaregory,
        brand,
        reference,
        model
      ].join("&");

      return `?${searchVehicle}`;
    case MAIN_FILTER_CATEGORIES.size:
      const sizeCaregory = `${encodeURIComponent('category')}=${encodeURIComponent(activeCategory)}`;
      const width = `${encodeURIComponent('width')}=${encodeURIComponent(categoryState.width)}`;
      const profile = `${encodeURIComponent('profile')}=${encodeURIComponent(categoryState.profile)}`;
      const wheel = `${encodeURIComponent('wheel')}=${encodeURIComponent(categoryState.wheel)}`;

      const searchSize = [
        sizeCaregory,
        width,
        profile,
        wheel
      ].join("&");
      return `?${searchSize}`;
    default:
      break
  }
}

export const getSearchQueryFromUrl = (locationSearch) => {
  if (!locationSearch) return { action: null, newState: null, valid: null };
  let action, newState, valid;
  const category = String(new URLSearchParams(locationSearch).get('category'));
  switch(category) {
    case MAIN_FILTER_CATEGORIES.vehicle:
      action = MAIN_FILTER_TYPES.updateVehicle;
      newState = {
        brand: String(new URLSearchParams(locationSearch).get('brand')),
        reference: String(new URLSearchParams(locationSearch).get('reference')),
        model: Number(new URLSearchParams(locationSearch).get('model')),
      };
      valid = newState.brand && newState.reference && newState.model;
      break;
    case MAIN_FILTER_CATEGORIES.size:
      action = MAIN_FILTER_TYPES.updateSize;
      newState = {
        width: Number(new URLSearchParams(locationSearch).get('width')),
        profile: Number(new URLSearchParams(locationSearch).get('profile')),
        wheel: String(new URLSearchParams(locationSearch).get('wheel')),
      }
      valid = newState.width && (newState.profile || newState.profile === 0) && newState.wheel;
      break;
    default:
      break
  }
  return { action, newState, valid, category }
}

export const getSearchQueryFromState = (mainFilterStore) => {
  let query, variables, searchString, valid, categoryState;
  switch(mainFilterStore.activeCategory) {
    case MAIN_FILTER_CATEGORIES.vehicle:
      query = GET_TIRES_BY_VEHICLE;
      variables = {
        carBrand: mainFilterStore[MAIN_FILTER_CATEGORIES.vehicle].brand,
        carModel: mainFilterStore[MAIN_FILTER_CATEGORIES.vehicle].model,
        carReference: mainFilterStore[MAIN_FILTER_CATEGORIES.vehicle].reference
      };
      categoryState = {
        brand: mainFilterStore[MAIN_FILTER_CATEGORIES.vehicle].brand,
        reference: mainFilterStore[MAIN_FILTER_CATEGORIES.vehicle].reference,
        model: mainFilterStore[MAIN_FILTER_CATEGORIES.vehicle].model
      };
      searchString = `${variables.carBrand} ${variables.carReference} ${variables.carModel}`;
      valid = variables.carBrand && variables.carReference && variables.carModel;
      break;
    case MAIN_FILTER_CATEGORIES.size:
      query = GET_TIRES_BY_SIZE;
      variables = {
        width: mainFilterStore[MAIN_FILTER_CATEGORIES.size].width,
        profile: mainFilterStore[MAIN_FILTER_CATEGORIES.size].profile,
        wheel: mainFilterStore[MAIN_FILTER_CATEGORIES.size].wheel
      };
      categoryState = {
        width: mainFilterStore[MAIN_FILTER_CATEGORIES.size].width,
        profile: mainFilterStore[MAIN_FILTER_CATEGORIES.size].profile,
        wheel: mainFilterStore[MAIN_FILTER_CATEGORIES.size].wheel
      };
      searchString = `${variables.width}/${variables.profile}/R${variables.wheel}`;
      valid = variables.width && (variables.profile || variables.profile === 0) && variables.wheel;
      break;
    default:
      break
  }
  return { query, variables, searchString, valid, categoryState };
}

export const isFilterFullfilled = (mainFilterStore) => {
  const activeCategory = mainFilterStore.activeCategory;
  const categoryState = mainFilterStore[mainFilterStore.activeCategory];
  switch(activeCategory) {
    case MAIN_FILTER_CATEGORIES.vehicle:
      return categoryState.brand && categoryState.reference && categoryState.model;
    case MAIN_FILTER_CATEGORIES.size:
      return categoryState.width && (categoryState.profile || categoryState.profile === 0) && categoryState.wheel;
    default:
      break
  }
}

export const parseTires = (tireData, activeCategory) => {
  let tireResults = [];
  switch(activeCategory) {
    case MAIN_FILTER_CATEGORIES.vehicle:
      const { tires_car } = tireData;
      if (tires_car.length) {
        tires_car.forEach(car => {
          if (car.sizes.length) {
            tireResults = [
              ...tireResults,
              ...car.sizes.reduce((acc, size) => [...acc, ...size.tires], [])
            ]
          }
        });
      }
      break;
    case MAIN_FILTER_CATEGORIES.size:
      const { tires_size } = tireData;
      if (tires_size.length) {
        tireResults = tires_size.reduce((acc, size) => [...acc, ...size.tires], [])
      }
      break;
    default:
      break
  }
  return uniqWith(tireResults, isEqual);
}

export const doesUrlMatchState = (storeState, urlSearch) => {
  const { newState: urlState } = getSearchQueryFromUrl(urlSearch);
  return isEqual(storeState, urlState);
}
