// @vendors
import React from "react";
import PropTypes from "prop-types";

// @styled-components
import {
  StyledModalStep,
  StyledModalStepNumber,
  StyledModalStepTitle,
  ClearModalStepButton,
  SearchModalValue,
  ModalStepInfo
} from "../filter-styles-components";

export default function FilterModalStep({
  active,
  children,
  number,
  value,
  onStepDelete
}) {
  return (
    <StyledModalStep>
      <StyledModalStepNumber active={active}>{number}</StyledModalStepNumber>
      <StyledModalStepTitle active={active}>{children}</StyledModalStepTitle>
      {(!!value || value === 0) && (
        <ModalStepInfo>
          <ClearModalStepButton onClick={onStepDelete}>X</ClearModalStepButton>
          <SearchModalValue>{ value }</SearchModalValue>
        </ModalStepInfo>
      )}
    </StyledModalStep>
  );
}

FilterModalStep.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

FilterModalStep.defaultProps = {
  active: false,
};
