import gql from "graphql-tag";

const GET_OFFERS = gql`
  {
    cover_offers {
      id
      image
      image_path
      title
      description
      url
    }
  }
`;
const GET_SLIDERS = gql`
  {
    sliders {
      id
      image
      image_path
    }
  }
`;

const GET_EMAIL_SENDER = gql`
  query sliders {
    sliders {
      author {
        email_sender
      }
    }
  }
`;
const GET_AUTHOR = gql`
  query author($id: ID!) {
    author(id: $id) {
      id
      email_sender
    }
  }
`;

const GET_MODAL_PROMOTIONS = gql`
  query modal_promotions {
    modal_promotions {
      id
      image
      image_path
      title
      description
      btn_text
      btn_url
    }
  }
`;

export {
  GET_OFFERS,
  GET_SLIDERS,
  GET_MODAL_PROMOTIONS,
  GET_AUTHOR,
  GET_EMAIL_SENDER,
};
