// @vendors
import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @assets
import bgHero from "../assets/images/bg-hero-home.jpg";

const Wrapper = styled.section`
  position: relative;
  height: 450px;
  background-image: url(${({ bgimg }) => bgimg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  ${MEDIA_QUERIES.desktopXL} {
    height: 600px;
  }

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.main.overlayBg};
  }
`;

const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 1260px;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.heroContact.secondaryColor};
`;

const Subtitle = styled.h5`
  color: ${({ theme }) => theme.heroContact.primaryColor};
`;

function HeroContact() {
  return (
    <Wrapper bgimg={bgHero}>
      <Container>
        <Title>¿En que podemos ayudarte?</Title>
        <Subtitle>Contáctanos o déjanos un mensaje para contáctarte</Subtitle>
      </Container>
    </Wrapper>
  );
}

export default HeroContact;
