import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import ServicePageCard from "../components/service-page-card";

const Wrapper = styled.section`
  width: 80%;
  max-width: 1180px;
  margin: 0 auto 4rem;

  ${MEDIA_QUERIES.landscape} {
    margin-bottom: 8rem;
  }

  ${MEDIA_QUERIES.desktop} {
    width: auto;
  }

  ${MEDIA_QUERIES.desktopXL} {
    max-width: 1260px;
  }

  .slick-arrow {
    width: 1.5rem;
    height: 1.5rem;

    ${MEDIA_QUERIES.tablet} {
      width: 2rem;
      height: 2rem;
    }

    ${MEDIA_QUERIES.desktopXL} {
      width: 3rem;
      height: 3rem;
    }

    ::before  {
      color: ${({ theme }) => theme.servicesCarousel.primaryColor};
      font-size: 1.5rem;
      opacity: 1;

      ${MEDIA_QUERIES.tablet} {
        font-size: 2rem;
      }

      ${MEDIA_QUERIES.desktopXL} {
        font-size: 3rem;
      }
    }
  }

  .slick-prev {
    left: -1.5rem;

    ${MEDIA_QUERIES.tablet} {
      left: -2rem;
    }

    ${MEDIA_QUERIES.desktopXL} {
      left: -3rem;
    }
  }

  .slick-next {
    right: -1.5rem;

    ${MEDIA_QUERIES.tablet} {
      right: -2rem;
    }

    ${MEDIA_QUERIES.desktopXL} {
      right: -3rem;
    }
  }
`;

export default function ServicesCarousel({ services }) {
  const settings = {
    dots: false,
    slidesToShow: services.length < 4 ? services.length : 4,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: services.length < 3 ? services.length : 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: services.length < 2 ? services.length : 2,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <Slider {...settings}>
        {services.map(service => (
          <ServicePageCard key={service.id} {...service} />
        ))}
      </Slider>
    </Wrapper>
  );
}
