// @vendors
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// @icons
import ArrowDown from "../assets/icons/arrow-down";

const StyledSelectButton = styled.button`
  width: 100%;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 ${({ theme }) => theme.main.boxShadow};
  background-color: ${({ theme }) => theme.selectButton.backgroundColor};
  cursor: pointer;

  :focus {
    outline: none;
  }

  span {
    font-size: 15px;
    color: ${({ theme }) => theme.selectButton.textColor};
  }
`;

const SelectButton = ({ children, color, onClick, disabled }) => (
  <StyledSelectButton onClick={onClick} disabled={disabled}>
    <span>{children}</span>
    <ArrowDown color={color} />
  </StyledSelectButton>
);

export default SelectButton;

SelectButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

SelectButton.defaultProps = {
  onClick: () => {},
};
