// @vendors
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import sha1 from "sha1";
import { string, func } from "prop-types";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @assets
import ImgFallback from "../../assets/images/img-dumb-admin.jpg";

const Wrapper = styled.div`
  min-width: 300px;
  margin-bottom: 2rem;
  text-align: center;

  ${MEDIA_QUERIES.landscape} {
    margin-bottom: 3rem;
  }
`;

const Title = styled.h4`
  margin-bottom: 2rem;
  text-transform: none;
`;

const Image = styled.img`
  width: 230px;
  height: 200px;
  margin: 0 auto 2rem;
  object-fit: cover;
`;

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  width: 250px;
  margin: 0 auto 1rem;
`;

const InputFile = styled.input`
  position: absolute;
  left: -100vw;
  opacity: 0;
`;

const Btn = styled.label`
  display: inline-block;
  margin-bottom: 1rem;
  padding: 0.8rem 2rem;
  border-radius: 1.4rem;
  background-color: ${({ theme }) => theme.main.lightGreenColor};
  transition: background-color 0.25s ease-out;
  color: ${({ theme }) => theme.main.backgroundColor};
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.main.darkerGreenColor};
  }
`;

const BtnDelete = styled.button`
  display: inline-block;
  padding: 0.8rem 2rem;
  border: 0;
  border-radius: 1.4rem;
  background-color: ${({ theme }) => theme.main.dangerColor};
  transition: background-color 0.25s ease-out;
  color: ${({ theme }) => theme.main.backgroundColor};
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
`;

const Sizes = styled.span`
  display: block;
  margin-bottom: 3rem;
  font-size: 12px;
`;

export default function DashboardImage({
  position,
  heading,
  image,
  pathImg,
  sizes,
  folder,
  onChange,
  onDelete,
}) {
  const CLOUD_NAME = "dht1qcln2";
  const API_KEY = "753523436996576";
  const API_SECRET = "QXxtV8Q8QMde5RNPsfV1b7jhvHY";
  const timestamp = Math.floor(Date.now() / 1000);

  const [imageSrc, setImageSrc] = useState("");
  let fileInput = useRef(null);

  useEffect(() => {
    setImageSrc(image);
  }, [image]);

  function handleFile() {
    let formData = new FormData();
    const currentFile = fileInput.current.files[0];
    const payloadToSign = `folder=${folder}&timestamp=${timestamp}&unique_filename=false&use_filename=true`;
    const signature = sha1(`${payloadToSign}${API_SECRET}`);

    formData.append("file", currentFile);
    formData.append("api_key", API_KEY);
    formData.append("timestamp", timestamp);
    formData.append("signature", signature);
    formData.append("folder", folder);
    formData.append("use_filename", true);
    formData.append("unique_filename", false);

    axios
      .post(
        `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/upload`,
        formData,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        },
      )
      .then(response => {
        const { data } = response;

        setImageSrc(data.secure_url);
        onChange(data.secure_url, position, "image", data.public_id);
      })
      .catch(err => console.error(err));
  }

  function handleRemove(pathImg) {
    setImageSrc("");
    onDelete(pathImg, position);
  }

  return (
    <Wrapper>
      {heading && <Title>{heading}</Title>}
      <Image src={imageSrc ? imageSrc : ImgFallback} />
      <InputContainer>
        <InputFile
          type="file"
          id={position}
          ref={fileInput}
          onChange={handleFile}
        />
        <Btn htmlFor={position}>{imageSrc ? "Cambiar" : "Subir"} imagen</Btn>
        {imageSrc && (
          <BtnDelete onClick={() => handleRemove(pathImg)}>Eliminar</BtnDelete>
        )}
      </InputContainer>
      {sizes && (
        <Sizes>
          Tamaño recomendado: <br />
          {sizes}
        </Sizes>
      )}
    </Wrapper>
  );
}

DashboardImage.propTypes = {
  position: string,
  heading: string,
  image: string,
  pathImg: string,
  sizes: string,
  folder: string,
  onChange: func,
  onDelete: func,
};

DashboardImage.defaultProps = {
  position: "",
  heading: "",
  image: "",
  pathImg: "",
  sizes: "",
  folder: "",
  onChange: function() {},
  onDelete: function() {},
};
