import gql from "graphql-tag";

const CREATE_TIRE = gql`
  mutation createTire($data: CreateTireInput!, $size: SizeInput!) {
    createTire(data: $data, size: $size) {
      id
      slug
      reference
      available
      price
      treadwear
      tread_depth
      benefits
      discount
      speed_index
      load_index
      image
    }
  }
`;

const UPDATE_TIRE = gql`
  mutation updateTire($id: ID!, $data: UpdateTireInput!, $size: SizeInput!) {
    updateTire(id: $id, data: $data, size: $size) {
      id
      slug
      reference
      available
      price
      treadwear
      tread_depth
      benefits
      discount
      speed_index
      load_index
      image
    }
  }
`;

const DELETE_TIRE = gql`
  mutation deleteTire($id: ID!, $sizeId: ID!) {
    deleteTire(id: $id, sizeId: $sizeId) {
      id
      reference
      size {
        id
      }
    }
  }
`;

const CREATE_WIDTH = gql`
  mutation createWidth($width: Int!) {
    createWidth(width: $width) {
      id
      width
    }
  }
`;

const CREATE_PROFILE = gql`
  mutation createProfile($profile: Int!) {
    createProfile(profile: $profile) {
      id
      profile
    }
  }
`;

const CREATE_WHEEL = gql`
  mutation createWheel($wheel: String!) {
    createWheel(wheel: $wheel) {
      id
      wheel
    }
  }
`;

const CREATE_TIRE_BRAND = gql`
  mutation createTireBrand($brand: String!, $image: String) {
    createTireBrand(brand: $brand, image: $image) {
      id
      brand
      image
    }
  }
`;

const CREATE_TIRE_DESIGN = gql`
  mutation createTireDesign($design: String!, $brand: String!) {
    createTireDesign(design: $design, brand: $brand) {
      id
      design
    }
  }
`;

const CREATE_PREFERENCE = gql`
  mutation createPreference($preference: String!) {
    createPreference(preference: $preference) {
      id
      preference
    }
  }
`;

export {
  CREATE_TIRE,
  UPDATE_TIRE,
  DELETE_TIRE,
  CREATE_WIDTH,
  CREATE_PROFILE,
  CREATE_WHEEL,
  CREATE_TIRE_BRAND,
  CREATE_TIRE_DESIGN,
  CREATE_PREFERENCE,
};
