// @vendors
import React from "react";

export default function CarIcon({ active, activeColor, disabledColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="47"
      height="21"
      viewBox="0 0 47 21"
    >
      <defs>
        <path id="a" d="M.006.004h46.5v20.954H.005z" />
      </defs>
      <g fillRule="evenodd">
        <path
          fill={active ? activeColor : disabledColor}
          d="M10 13a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
        />
        <g>
          <mask id="b" fill={active ? activeColor : disabledColor}>
            <use xlinkHref="#a" />
          </mask>
          <path
            fill={active ? activeColor : disabledColor}
            d="M11.387 4.956L9.135 7.168h13.391V.071c-4.203.245-8.168 1.984-11.14 4.885zM33.114 6.761V1.518A17.598 17.598 0 0 0 26.072 0h-2.3v7.168h8.885a.435.435 0 0 0 .457-.407zM36.502 13.655c-2.074 0-3.754 1.634-3.755 3.651 0 2.017 1.681 3.652 3.755 3.652 2.073 0 3.754-1.635 3.754-3.652-.002-2.015-1.681-3.649-3.754-3.651z"
          />
          <path
            fill={active ? activeColor : disabledColor}
            d="M41.788 10.399c.002-1.117.935-2.022 2.084-2.02h2.45c-.373-.92-1.29-1.521-2.308-1.514h-3.891a.632.632 0 0 1-.487-.26 16.735 16.735 0 0 0-5.276-4.476V6.76c-.012.904-.774 1.628-1.703 1.619H7.665l-3.627.24C1.768 8.774.004 10.608 0 12.822v4.24h4.489c.128-2.524 2.318-4.625 4.994-4.625 2.674 0 4.865 2.101 4.993 4.626h17.032c.128-2.525 2.32-4.626 4.994-4.626 2.675 0 4.866 2.101 4.994 4.626h3.51c.827.002 1.498-.65 1.5-1.453v-3.192h-2.634c-1.149.003-2.082-.902-2.084-2.019z"
          />
        </g>
        <path
          fill={active ? activeColor : disabledColor}
          d="M43 10c0 .267.077.522.213.71.136.187.32.292.511.29H46V9h-2.276c-.191-.002-.375.103-.51.29A1.225 1.225 0 0 0 43 10z"
        />
      </g>
    </svg>
  );
}
