import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  z-index: 1;
  top: 7rem;
  right: 2rem;
  max-width: 600px;
  padding: 0.8rem 2rem;
  border-radius: 1.4rem;
  background-color: ${({ theme }) => theme.main.primaryColor};
  color: ${({ theme }) => theme.main.backgroundColor};
`;

export default function Alert({ message }) {
  return <Container>{message}</Container>;
}
