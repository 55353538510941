// @vendors
import React, { useState } from "react";
import styled from "styled-components";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @utils
import removeAccents from "../../utils/removeAccents";

// @requests
import { GET_POST, GET_CATEGORIES } from "../../requests/queries/posts";
import {
  CREATE_CATEGORY,
  CREATE_POST,
  UPDATE_POST,
} from "../../requests/mutations/posts";
import { DELETE_IMAGE } from "../../requests/mutations";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import Layout from "../../containers/dashboard/Layout";
import DashboardImage from "../../components/dashboard/image";
import Editor from "../../components/dashboard/editor";
import DashboardCategories from "../../components/dashboard/categories";
import Button from "../../components/button";
import TextInput from "../../components/text-input";
import Loading from "../../components/Loading";
import Updated from "../../components/dashboard/updated";

// @assets
import BlogIcon from "../../assets/icons/docs";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1260px;
  margin: 3rem auto;

  ${MEDIA_QUERIES.tablet} {
    flex-direction: row;
  }
`;

const Images = styled.div`
  ${MEDIA_QUERIES.tablet} {
    width: 30%;
  }
`;

const Content = styled.div`
  ${MEDIA_QUERIES.tablet} {
    width: 70%;
  }
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  font-weight: bold;
`;

const SubmitContainer = styled.div`
  margin-top: 4rem;
`;

export default function DashboardPost({
  match: {
    params: { slug },
  },
}) {
  const ENV = process.env.REACT_APP_ENV || "dev";
  const isNewPost = slug === "nuevo";
  const [isSaved, setIsSaved] = useState(false);
  const [idPost, setIdPost] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [editorContent, setEditorContent] = useState(EditorState.createEmpty());
  const [selectedCat, setSelectedCat] = useState([]);
  const [newCat, setNewCat] = useState("");
  const { data, loading, error } = useQuery(GET_CATEGORIES);
  useQuery(GET_POST, {
    variables: {
      slug: slug !== "nuevo" ? slug : "",
    },
    onCompleted({ post }) {
      if (post) {
        const content = convertFromRaw(JSON.parse(post.body));

        setIdPost(post.id);
        setTitle(post.title);
        setImage(post.image);
        setImagePath(post.image_path);
        setEditorContent(EditorState.createWithContent(content));
        setSelectedCat(post.categories);
      }
    },
  });
  const [createCategory] = useMutation(CREATE_CATEGORY, {
    update(cache, { data: { createCategory } }) {
      const { categories } = cache.readQuery({
        query: GET_CATEGORIES,
      });
      cache.writeQuery({
        query: GET_CATEGORIES,
        data: { categories: categories.concat([createCategory]) },
      });
    },
    onCompleted({ createCategory }) {
      setSelectedCat([...selectedCat, createCategory]);
    },
  });
  const [createPost] = useMutation(CREATE_POST, {
    onCompleted: function({ createPost }) {
      if (createPost) {
        setIsSaved(true);
      }
    },
  });
  const [updatePost] = useMutation(UPDATE_POST, {
    onCompleted: function({ updatePost }) {
      if (updatePost) {
        setIsSaved(true);
      }
    },
  });
  const [deleteImage] = useMutation(DELETE_IMAGE);

  function onChange(event, catN, type, pathImg) {
    if (type === "title") {
      setTitle(event);
    }

    if (type === "image") {
      setImage(event);
      setImagePath(pathImg);
    }

    if (type === "editor") {
      setEditorContent(event);
    }

    if (type === "categories") {
      let categories = [...selectedCat];

      if (event) {
        categories.push(catN);
      } else {
        categories = categories.filter(cat => cat.id !== catN.id);
      }

      setSelectedCat(categories);
    }
  }

  function onDelete(event) {
    deleteImage({ variables: { path_img: event } });

    setImage("");
    setImagePath("");
  }

  function handleNewCat(event) {
    setNewCat(event);
  }

  function sendNewCat() {
    createCategory({
      variables: { name: newCat },
    });

    setNewCat("");
  }

  function handlePost(e) {
    e.preventDefault();

    const content = convertToRaw(editorContent.getCurrentContent());
    const dataPost = {
      title,
      slug: removeAccents(title),
      image: image,
      image_path: imagePath,
      body: JSON.stringify(content),
      categories: selectedCat.map(cat => cat.id),
    };

    if (isNewPost) {
      createPost({
        variables: {
          data: dataPost,
        },
      });
    } else {
      updatePost({
        variables: {
          id: idPost,
          data: dataPost,
        },
      });
    }
  }

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  if (isSaved)
    return (
      <Updated
        to="/administrador/ofertas"
        message={`Se ha ${isNewPost ? "creado" : "actualizado"} correctamente`}
      />
    );

  const { categories } = data;

  return (
    <Layout
      title={`Administrador - ${isNewPost ? "Nueva oferta" : title}`}
      Icon={BlogIcon}
      heading={`${isNewPost ? "Nueva" : "Actualizar"} oferta`}
      nested
    >
      <Wrapper onSubmit={handlePost}>
        <Images>
          <DashboardImage
            image={image}
            pathImg={imagePath}
            sizes="960px x 385px"
            folder={ENV === "dev" ? `dev/blog` : "blog"}
            onChange={onChange}
            onDelete={onDelete}
            position="image_cover"
          />
        </Images>
        <Content>
          <Label htmlFor="title">Título</Label>
          <TextInput
            bordered
            required
            id="title"
            name="title"
            value={title}
            placeholder="Escriba el título de la oferta"
            onChange={onChange}
          />
          <Editor content={editorContent} onChange={onChange} />
          {categories.length > 0 && <Label>Seleccionar categoría</Label>}
          <DashboardCategories
            selectedCat={selectedCat}
            categories={categories}
            onChange={onChange}
            newCat={newCat}
            onChangeNewCat={handleNewCat}
            sendNewCat={sendNewCat}
          />
          <SubmitContainer>
            <Button>{isNewPost ? "Guardar y publicar" : "Actualizar"}</Button>
          </SubmitContainer>
        </Content>
      </Wrapper>
    </Layout>
  );
}
