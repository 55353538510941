// @vendors
import React from "react";
import styled from "styled-components";

const HeroOffersWrapper = styled.header`
  position: relative;
  margin: 8rem auto 6rem;
  text-align: center;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 13rem;
    height: 13rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.heroOffers.iconColor};
    opacity: 0.2;
  }
`;

const HeroOffersIcon = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.heroOffers.iconColor};
  font-size: 4rem;
  font-weight: bold;
`;

const HeroOffersTitle = styled.h1`
  color: ${({ theme }) => theme.heroOffers.primaryColor};
`;

const HeroOffersDescription = styled.h5`
  color: ${({ theme }) => theme.heroOffers.textColor};
`;

function HeroOffers() {
  return (
    <HeroOffersWrapper>
      <HeroOffersIcon>%</HeroOffersIcon>
      <HeroOffersTitle>Descuentos</HeroOffersTitle>
      <HeroOffersDescription>
        Encuentra aquí todas los descuentos que tenemos para ti
      </HeroOffersDescription>
    </HeroOffersWrapper>
  );
}

export default HeroOffers;
