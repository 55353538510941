// @vendors
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

//@constants
import { MEDIA_QUERIES } from "../../constants";

// @assets
import LongArrowIcon from "../../assets/icons/long-arrow";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  max-width: 1260px;
  margin: 3rem auto;

  ${MEDIA_QUERIES.tablet} {
    flex-direction: row;
  }

  ${MEDIA_QUERIES.desktopXL} {
    margin: 5rem auto;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.main.primaryColor};

  ${MEDIA_QUERIES.tablet} {
    margin-bottom: 0;
  }
`;

const Title = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`;

const Icon = styled.span`
  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    fill: ${({ theme }) => theme.main.primaryColor};
  }
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: ${({ theme }) => theme.main.defaultTextColor};
  font-weight: bold;
  text-decoration: none;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.main.boxShadow};

  svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
    transform: rotate(-180deg);
  }
`;

function checkTypeHeading(title) {
  if (title.includes("Nueva oferta") || title.includes("Actualizar oferta"))
    return "ofertas";
  if (title.includes("Nuevo servicio") || title.includes("Actualizar servicio"))
    return "servicios";
  if (title.includes("Nueva llanta") || title.includes("Actualizar llanta"))
    return "llantas";
  if (title.includes("Nuevo carro") || title.includes("Actualizar carro"))
    return "carros";
}

export default function DashboardHeading({ Image, title, nested }) {
  return (
    <Wrapper>
      <TitleContainer>
        <Icon>
          <Image />
        </Icon>
        <Title>{title}</Title>
      </TitleContainer>
      <BackLink
        to={`/administrador${nested ? `/${checkTypeHeading(title)}` : ""}`}
      >
        <LongArrowIcon />
        Volver
      </BackLink>
    </Wrapper>
  );
}
