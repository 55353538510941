// @vendors
import React from 'react';
import uniqWith from 'lodash.uniqwith';
import isEqual from'lodash.isequal';
import { useQuery } from "@apollo/react-hooks";

// @constants
import { MAIN_FILTER_TYPES } from "../../../../hooks-store/main-filter-store"

// @cstyled-omponents
import { StyledFilterModalOption } from "../../filter-styles-components";

// @store-hooks
import { useStore } from "../../../../hooks-store/store";

// @queries
import { GET_WIDTHS_PROFILES } from "../../../../requests/queries/tires";

const ProfileOptions = ({ stateWidth }) => {
  const dispatch = useStore(false)[1];
  const { loading, error, data: { get_widths_profiles } = { get_widths_profiles: [] } } = useQuery(GET_WIDTHS_PROFILES, {
    variables: { width: stateWidth }
  });
  if (loading) {
    return <div>Loading</div>
  }

  if (error) {
    return <div>Ocurrió un error cargando los perfiles</div>
  }

  return uniqWith(
    get_widths_profiles.map(({ profile }) => ({ profile: profile.profile, id: profile.id })),
    isEqual
  ).map(profile => (
      <StyledFilterModalOption
        key={profile.id}
        filterOption
        onClick={() => {
          dispatch(
            MAIN_FILTER_TYPES.updateSizeField,
            { fieldName: "profile", value: profile.profile }
          )
        }}
      >
        {profile.profile}
      </StyledFilterModalOption>
    )
  );
}

export default ProfileOptions;