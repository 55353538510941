// @vendors
import React, { useState, useEffect } from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import Layout from "../../containers/dashboard/Layout";
import DashboardItems from "../../containers/dashboard/items";
import DashboardEmail from "../../containers/dashboard/email";

const Container = styled.section`
  width: 90%;
  max-width: 1260px;
  margin: 2rem auto;

  ${MEDIA_QUERIES.tablet} {
    margin: 4rem auto;
  }

  ${MEDIA_QUERIES.desktopXL} {
    margin: 6rem auto;
  }
`;

export default function DashboardHome({ history, location: { state } }) {
  const [notification, setNotification] = useState("");

  useEffect(() => {
    if (state) {
      setNotification(state.notification);

      setTimeout(() => {
        setNotification("");
        history.replace({ pathname: "/administrador", state: "" });
      }, 2000);
    }
  }, [history, state]);

  return (
    <Layout title="Administrador - Inicio" notification={notification} isHome>
      <Container>
        <DashboardItems />
        <DashboardEmail />
      </Container>
    </Layout>
  );
}
