// @vendors
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { string } from "prop-types";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Button from "./button";

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  text-align: center;
  box-shadow: 0 0 2px 0 ${({ theme }) => theme.main.boxShadow};

  ${MEDIA_QUERIES.tablet} {
    height: 350px;
  }

  ${MEDIA_QUERIES.landscape} {
    width: calc(100% / 3);
  }
`;

const Image = styled.div`
  margin-bottom: 1.5rem;

  img {
    height: 100px;

    ${MEDIA_QUERIES.tablet} {
      height: 120px;
    }
  }
`;

const Title = styled.h4`
  margin-bottom: 1rem;
`;

export default function ThumbnailCategory({ image, title, category }) {
  return (
    <Wrapper>
      <Image>
        <img src={image} alt={title} />
      </Image>
      <Title>{title}</Title>
      <Button flat primary as={Link} to={`/categoria/${category}`}>
        Ver llantas
      </Button>
    </Wrapper>
  );
}

ThumbnailCategory.propTypes = {
  image: string,
  title: string,
  category: string,
};

ThumbnailCategory.defaultProps = {
  image: "",
  title: "",
  category: "",
};
