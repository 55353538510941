// @vendors
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

// @components
import ScrollToTop from "./components/scrollToTop";
import withAuth from "./hocs/withAuth";

// @pages
import HomePage from "./pages/home";
import Tire from "./pages/tire";
import Search from "./pages/search";
import Categories from "./pages/categories";
import Offers from "./pages/offers";
import Branches from "./pages/branches";
import Services from "./pages/services";
import Service from "./pages/service";
import Contact from "./pages/contact";
import Blog from "./pages/blog";
import Post from "./pages/post";
import Faqs from "./pages/faqs";
import AboutUs from "./pages/about-us";
import DashboardHome from "./pages/dashboard/home";
import DashboardOffers from "./pages/dashboard/offers";
import DashboardTires from "./pages/dashboard/tires";
import DashboardTire from "./pages/dashboard/tire";
import DashboardBlog from "./pages/dashboard/blog";
import DashboardPost from "./pages/dashboard/post";
import DashboardCars from "./pages/dashboard/cars";
import DashboardCar from "./pages/dashboard/car";
import DashboardServices from "./pages/dashboard/services";
import DashboardService from "./pages/dashboard/service";
import DashboardSlider from "./pages/dashboard/slider";
import DashboardModalPromotion from "./pages/dashboard/modal-promotion";
import DashboardInventory from "./pages/dashboard/inventory";
import ResetPassword from "./pages/reset-password";
import Privacy from "./pages/privacy";
import Environment from "./pages/environment";
import ModalPromotion from "./containers/modal-promotion";

// @constants
const ADMIN_SCOPE = "/administrador";

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <ModalPromotion />
      <Route exact path="/" component={HomePage} />
      <Route path="/llantas/:slug" component={Tire} />
      <Route path="/busqueda" component={Search} />
      <Route path="/categoria/:category" component={Categories} />
      <Route path="/descuentos" component={Offers} />
      <Route path="/sedes" component={Branches} />
      <Route exact path="/servicios" component={Services} />
      <Route path="/servicios/:slug" component={Service} />
      <Route path="/contacto" component={Contact} />
      <Route exact path="/ofertas" component={Blog} />
      <Route path="/ofertas/:slug" component={Post} />
      <Route path="/faqs" component={Faqs} />
      <Route path="/quienes-somos" component={AboutUs} />

      <Route
        exact
        path={`${ADMIN_SCOPE}`}
        render={props => {
          return withAuth(DashboardHome, { ...props });
        }}
      />
      <Route
        path={`${ADMIN_SCOPE}/ofertas-destacadas`}
        render={props => {
          return withAuth(DashboardOffers, { ...props });
        }}
      />
      <Route
        exact
        path={`${ADMIN_SCOPE}/llantas`}
        render={props => {
          return withAuth(DashboardTires, { ...props });
        }}
      />
      <Route
        path={`${ADMIN_SCOPE}/llantas/:slug`}
        render={props => {
          return withAuth(DashboardTire, { ...props });
        }}
      />
      <Route
        exact
        path={`${ADMIN_SCOPE}/carros`}
        render={props => {
          return withAuth(DashboardCars, { ...props });
        }}
      />
      <Route
        path={`${ADMIN_SCOPE}/carros/:slug`}
        render={props => {
          return withAuth(DashboardCar, { ...props });
        }}
      />
      <Route
        exact
        path={`${ADMIN_SCOPE}/ofertas`}
        render={props => {
          return withAuth(DashboardBlog, { ...props });
        }}
      />
      <Route
        path={`${ADMIN_SCOPE}/ofertas/:slug`}
        render={props => {
          return withAuth(DashboardPost, { ...props });
        }}
      />
      <Route
        exact
        path={`${ADMIN_SCOPE}/servicios`}
        render={props => {
          return withAuth(DashboardServices, { ...props });
        }}
      />
      <Route
        path={`${ADMIN_SCOPE}/servicios/:slug`}
        render={props => {
          return withAuth(DashboardService, { ...props });
        }}
      />
      <Route
        path={`${ADMIN_SCOPE}/slider`}
        render={props => {
          return withAuth(DashboardSlider, { ...props });
        }}
      />
      <Route
        path={`${ADMIN_SCOPE}/promocion-modal`}
        render={props => {
          return withAuth(DashboardModalPromotion, { ...props });
        }}
      />
      <Route
        path={`${ADMIN_SCOPE}/subir-inventario`}
        render={props => {
          return withAuth(DashboardInventory, { ...props });
        }}
      />
      <Route exact path="/reiniciar-contrasena" component={ResetPassword} />
      <Route exact path="/politica-privacidad" component={Privacy} />
      <Route exact path="/politica-ambiental" component={Environment} />
    </Router>
  );
}
