// @vendors
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { string, object, func } from "prop-types";

// @utils
import formatDateTime from "../../utils/formatDateTime";

// @constants
import { MEDIA_QUERIES } from "../../constants";

const Wrapper = styled.article`
  margin-bottom: 1.5rem;
  padding: 1rem;
  box-shadow: 0 1px 3px 0 ${({ theme }) => theme.main.boxShadow};
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  width: 80%;
  font-size: 1rem;
  text-transform: none;

  ${MEDIA_QUERIES.landscape} {
    width: 90%;
  }
`;

const Published = styled.span`
  display: inline-block;
  font-size: 0.9rem;
  text-align: right;
`;

const Actions = styled.div``;

const Btn = styled.button`
  display: inline-block;
  margin-right: 1rem;
  padding: 0;
  border: 0;
  background: none;
  color: ${({ theme, remove }) =>
    remove ? theme.dashboard.dangerColor : theme.dashboard.btnPrimaryColor};
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
`;

const BtnEdit = styled(Link)`
  display: inline-block;
  margin-right: 1rem;
  padding: 0;
  border: 0;
  color: ${({ theme }) => theme.dashboard.btnPrimaryColor};
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
`;

function getPageType(type) {
  switch (type) {
    case "oferta":
      return "ofertas";
    case "servicio":
      return "servicios";
    case "carro":
      return "carros";
    default:
      return "llantas";
  }
}

export default function DashboardListItem({
  id,
  reference,
  size,
  slug,
  title,
  name,
  image_path,
  icon_path,
  created_at,
  type,
  car_brand,
  car_reference,
  car_model,
  onDelete,
}) {
  return (
    <Wrapper>
      <Info>
        <Title>
          {type === "oferta"
            ? title
            : type === "llanta"
            ? reference
            : type === "carro"
            ? `${car_brand.name} ${car_reference.name} ${car_model.year}`
            : name}
        </Title>
        {type === "oferta" && (
          <Published>{formatDateTime(created_at)}</Published>
        )}
      </Info>
      <Actions>
        <BtnEdit to={`/administrador/${getPageType(type)}/${slug}`}>
          Editar
        </BtnEdit>
        <Btn
          type="button"
          onClick={() =>
            onDelete(id, image_path, icon_path, size ? size.id : undefined)
          }
          remove
        >
          Eliminar
        </Btn>
      </Actions>
    </Wrapper>
  );
}

DashboardListItem.propTypes = {
  id: string,
  reference: string,
  size: object,
  slug: string,
  title: string,
  name: string,
  image_path: string,
  icon_path: string,
  created_at: string,
  type: string,
  car_brand: object,
  car_reference: object,
  car_model: object,
  onDelete: func,
};

DashboardListItem.defaultProps = {
  id: "",
  reference: "",
  size: {},
  slug: "",
  title: "",
  name: "",
  image_path: "",
  icon_path: "",
  created_at: "",
  type: "",
  car_brand: {},
  car_reference: {},
  car_model: {},
  onDelete: function() {},
};
