// @vendors
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";

// @utils
import removeAccents from "../utils/removeAccents";

// @requests
import { GET_SERVICES } from "../requests/queries/services";

// @components
import WhatsappButton from "./whatsapp-button";
import CopyrightFooter from "./copyright-footer";
import Loading from "./Loading";

// @assets
import FacebookIcon from "../assets/icons/facebook";
import InstagramIcon from "../assets/icons/instagram";

const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.footer.backgroundColor};
  padding: 50px calc(15% - (300px - 2 * 10%));
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Section = styled.div`
  margin: 0 20px;
  padding: 10px;
  min-width: 250px;
`;

const Title = styled.h5`
  margin: 0;
  color: ${({ theme }) => theme.footer.titleTextColor};
  display: inline-block;
  border-bottom: ${({ theme }) => theme.footer.titleLineColor} 1px solid;
  padding-bottom: 10px;
`;

const List = styled.menu`
  margin-top: 20px;
  padding: 0;
`;

const ListItem = styled(Link)`
  display: block;
  margin: 10px 0;
  color: ${({ theme }) => theme.footer.linkTextColor};
  text-decoration: none;
`;

const ListItemSpecial = styled.a`
  display: block;
  margin: 10px 0;
  color: ${({ theme }) => theme.footer.linkTextColor};
  text-decoration: none;
`;

const SocialMedia = styled.div`
  margin-top: 1rem;
`;

const Icon = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.footer.linkTextColor};

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${({ theme }) => theme.footer.titleLineColor};
  }
`;

export default function Footer() {
  const { loading, error, data } = useQuery(GET_SERVICES);

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { services } = data;

  return (
    <Fragment>
      <Wrapper>
        <Section>
          <Title>Nosotros</Title>
          <List>
            <ListItem to="/quienes-somos">Quienes somos</ListItem>
            <ListItem to="/busqueda">Buscar llantas</ListItem>
            <ListItemSpecial
              href="http://www.servireencauche.com/"
              target="_blank"
            >
              Reencauche
            </ListItemSpecial>
            <ListItemSpecial
              href="http://servireencauche.pagoagil.co/"
              target="_blank"
            >
              Zona de pagos
            </ListItemSpecial>
            <ListItem to="/ofertas">Ofertas</ListItem>
            <ListItem to="/politica-privacidad">
              Política de privacidad
            </ListItem>
            <ListItem to="/politica-ambiental">Política ambiental</ListItem>
          </List>
        </Section>
        <Section>
          <Title>Servicios</Title>
          <List>
            {services.map(service => (
              <ListItem
                to={`/servicios/${removeAccents(service.name)}`}
                key={service.id}
              >
                {service.name}
              </ListItem>
            ))}
          </List>
        </Section>
        <Section>
          <Title>Contacto</Title>
          <List>
            <ListItem to="/contacto">Contáctenos</ListItem>
            <ListItem to="/sedes">Sedes</ListItem>
            <ListItem to="/faqs">FAQS</ListItem>
            <WhatsappButton isSmall>Contacta un asesor</WhatsappButton>
            <SocialMedia>
              <Icon
                href="https://www.facebook.com/ServillantasLa57/"
                target="_blank"
                rel="noopener"
              >
                <FacebookIcon />
              </Icon>
              <Icon
                href="https://www.instagram.com/servillantasla57/"
                target="_blank"
                rel="noopener"
              >
                <InstagramIcon />
              </Icon>
            </SocialMedia>
          </List>
        </Section>
      </Wrapper>
      <CopyrightFooter />
    </Fragment>
  );
}
