// @vendors
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { string, bool, element, arrayOf } from "prop-types";

// @components
import Navbar from "../components/navbar";
import Footer from "../components/footer";

export default function Layout({
  title,
  navbarTransparent,
  metaDescription,
  children,
}) {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Navbar transparent={navbarTransparent} />
      {children}
      <Footer />
    </Fragment>
  );
}

Layout.propTypes = {
  title: string,
  navbarTransparent: bool,
  metaDescription: string,
  children: arrayOf(element),
};

Layout.defaultProps = {
  title: "",
  metaDescription: "",
  navbarTransparent: false,
};
