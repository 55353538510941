import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

// @utils
import { setCookie, getCookie } from "../utils/cookies";

// @requests
import { GET_MODAL_PROMOTIONS } from "../requests/queries";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Modal from "../components/modal";
import Button from "../components/button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow-y: auto;

  ${MEDIA_QUERIES.tablet} {
    align-items: center;
    flex-direction: row;
    width: 760px;
    height: 535px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  min-height: 250px;
  object-fit: cover;

  ${MEDIA_QUERIES.tablet} {
    width: 50%;
    height: 535px;
  }
`;

const Info = styled.div`
  padding: 2rem;

  ${MEDIA_QUERIES.tablet} {
    width: 50%;
  }
`;

const Title = styled.h1`
  margin-bottom: 1.2rem;
  color: ${({ theme }) => theme.main.primaryColor};
  font-size: 1.4rem;

  ${MEDIA_QUERIES.tablet} {
    font-size: 1.8rem;
  }
`;

const Description = styled.p`
  margin-bottom: 1.2rem;
`;

const DismissText = styled.button`
  display: inline-block;
  margin-top: 1.2rem;
  border: 0;
  background: none;
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

function ModalPromotion({ location: { pathname } }) {
  const isModalClicked = getCookie("modalPromotion");
  const isAdmin = pathname.includes("administrador");
  const isResetPass = pathname === "/reiniciar-contrasena";
  const [readyToShow, setReadyToShow] = useState(false);

  const { loading, error, data } = useQuery(GET_MODAL_PROMOTIONS);

  useEffect(() => {
    window.addEventListener("load", function() {
      setReadyToShow(!isModalClicked && !isAdmin && !isResetPass);
    });
  }, [isModalClicked, isAdmin, isResetPass]);

  function handleClose(e) {
    e.preventDefault();

    setReadyToShow(false);
    setCookie("modalPromotion", true, 20);
  }

  if (loading) return null;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { modal_promotions } = data;
  const modalPromotion = modal_promotions && modal_promotions[0];

  return readyToShow && modalPromotion && modalPromotion.title ? (
    <Modal noPadding onCloseClick={handleClose}>
      <Container>
        <Image src={modalPromotion.image} />
        <Info>
          <Title>{modalPromotion.title}</Title>
          <Description>{modalPromotion.description}</Description>
          {modalPromotion.btn_text && (
            <Button as="a" href={modalPromotion.btn_url}>
              {modalPromotion.btn_text}
            </Button>
          )}
          <DismissText type="button" onClick={handleClose}>
            No me interesa en el momento
          </DismissText>
        </Info>
      </Container>
    </Modal>
  ) : null;
}

export default withRouter(ModalPromotion);
