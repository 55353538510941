// @vendors
import React from "react";
import styled from "styled-components";

// @components
import SearchSelect from "../components/search-select";

// @data
const optionsPrices = [
  { label: "De mayor a menor", value: "price_DESC" },
  { label: "De menor a mayor", value: "price_ASC" },
];

const Filters = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Title = styled.h5`
  margin-right: 0.5rem;
`;

export default function ListProducts({
  handleChangePrice,
  handleChangeBrand,
  currentPrice,
  totalTires,
}) {
  const parseCurrentPrice = {
    label:
      currentPrice === "price_ASC" ? "De menor a mayor" : "De mayor a menor",
    value: currentPrice,
  };

  let brandOptions = [{ label: "Todas", value: "" }];
  let brands = [];
  totalTires.forEach((item) => {
    if (!brands.includes(item.brand.brand)) {
      brandOptions.push({
        label: item.brand.brand,
        value: item.brand.brand,
      });

      brands.push(item.brand.brand);
    }
  });

  return (
    <Filters>
      <Title>Filtrar por: </Title>
      <SearchSelect
        options={brandOptions}
        placeholder="Marca"
        onChange={(e) => handleChangeBrand(e)}
      />
      <SearchSelect
        options={optionsPrices}
        placeholder="Precio"
        defaultValue={parseCurrentPrice}
        onChange={(e) => handleChangePrice(e)}
      />
    </Filters>
  );
}
