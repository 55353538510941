// @vendors
import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import ContactInfo from "./contact-info";
import ContactForm from "./contact-form";
import SocialMediaSection from "./social-media-section";

const Wrapper = styled.section``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -6rem;
  width: 90%;
  max-width: 1260px;
  margin: 0 auto;
  padding: 2rem 1rem;
  border-top: 0.3rem solid
    ${({ theme }) => theme.contactContentSection.primaryColor};
  background-color: ${({ theme }) =>
    theme.contactContentSection.secondaryColor};
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.main.boxShadow};

  ${MEDIA_QUERIES.tablet} {
    padding: 3rem;
  }

  ${MEDIA_QUERIES.landscape} {
    flex-direction: row;
  }

  ${MEDIA_QUERIES.desktop} {
    padding: 3rem 5rem;
  }

  ${MEDIA_QUERIES.desktopXL} {
    top: -10rem;
    padding: 3rem 7rem;
  }
`;

export default function ContactContentSection() {
  return (
    <Wrapper>
      <Container>
        <ContactInfo />
        <ContactForm />
      </Container>
      <SocialMediaSection />
    </Wrapper>
  );
}
