import { servillantasColors } from "./colors";

const lightTheme = {
  main: {
    defaultTextColor: servillantasColors.black,
    backgroundColor: servillantasColors.white,
    primaryColor: servillantasColors.primary,
    pseColor: servillantasColors.pse,
    dangerColor: servillantasColors.lightRed,
    grayColor: servillantasColors.disabled,
    grayDarkerColor: servillantasColors.gray,
    lightGreenColor: servillantasColors.lightGreen,
    darkerGreenColor: servillantasColors.darkerGreen,
    overlayBg: servillantasColors.darkImageOverlay,
    boxShadow: servillantasColors.darkImageOverlay,
  },
  dashboard: {
    btnPrimaryColor: servillantasColors.jungleGreen,
    dangerColor: servillantasColors.lightRed,
  },
  select: {
    primary: {
      backgroundColor: servillantasColors.black,
      textColor: servillantasColors.white,
      arrowColor: servillantasColors.primary,
    },
    secondary: {
      backgroundColor: servillantasColors.disabled,
      textColor: servillantasColors.black,
      arrowColor: servillantasColors.primary,
    },
    ternary: {
      backgroundColor: servillantasColors.white,
      textColor: servillantasColors.black,
      arrowColor: servillantasColors.primary,
    },
  },
  button: {
    primaryBackground: servillantasColors.primary,
    disabledBackground: servillantasColors.disabled,
    textColor: servillantasColors.white,
    backgroundHoverColor: servillantasColors.green,
    filterOptionBackgroundColor: servillantasColors.darkWhite,
    filterOptionTextColor: servillantasColors.black,
    filterOptionHoverTextColor: servillantasColors.disabled,
  },
  termsBox: {
    primaryBackground: servillantasColors.darkerGreen,
    checkColor: servillantasColors.lightGreen,
    checkAltColor: servillantasColors.white,
    textColor: servillantasColors.white,
    textColorGray: servillantasColors.gray,
  },
  promotion: {
    backgroundColor: `${servillantasColors.lightGreen}E6`,
    backgroundColorAlt: `${servillantasColors.orange}E6`,
    textColor: servillantasColors.white,
  },
  mainFilter: {
    backgroundColor: servillantasColors.primary,
    textColor: servillantasColors.white,
    textColorDisabled: servillantasColors.disabled,
    selectBackgroundColor: servillantasColors.white,
    backgroundHoverColor: servillantasColors.green,
  },
  filterStep: {
    textColor: servillantasColors.white,
    textColorDisabled: servillantasColors.disabled,
    textPrimaryColor: servillantasColors.primary,
  },
  selectButton: {
    backgroundColor: servillantasColors.white,
    textColor: servillantasColors.disabled,
  },
  navbar: {
    backgroundColor: servillantasColors.white,
    textColor: servillantasColors.black,
    textColorTransparent: servillantasColors.white,
    burgerColor: servillantasColors.lightGreen,
  },
  tireCard: {
    cardBorderColor: servillantasColors.disabled,
    RefAndSurfaceTextColor: servillantasColors.gray,
    separatorColor: servillantasColors.primary,
    saleBandBackgroundColor: servillantasColors.lightRed,
    previousPriceColor: servillantasColors.lightRed,
    priceColor: servillantasColors.primary,
    saleBandTextColor: servillantasColors.white,
    saleBandBgColor: servillantasColors.lightRed,
  },
  footer: {
    backgroundColor: servillantasColors.black,
    titleLineColor: servillantasColors.primary,
    linkTextColor: servillantasColors.white,
    titleTextColor: servillantasColors.white,
    textColorPrimary: servillantasColors.lightGreen,
  },
  modal: {
    backgroundColor: servillantasColors.white,
    closeBackground: servillantasColors.black,
    closeSvgColor: servillantasColors.white,
  },
  heroHome: {
    titleColor: servillantasColors.white,
    subTitleColor: servillantasColors.primary,
    imageOverlayColor: servillantasColors.darkImageOverlay,
  },
  environmental: {
    backgroundGradientTop: `${servillantasColors.lightGreen}b3`,
    backgroundGradientBottom: `${servillantasColors.black}cc`,
    textColor: servillantasColors.white,
    textColorPrimary: servillantasColors.lightGreen,
  },
  searchSteps: {
    textColor: servillantasColors.white,
    textColorPrimary: servillantasColors.lightGreen,
  },
  branchCard: {
    backgroundColor: servillantasColors.white,
    titleColor: servillantasColors.white,
    titleBackgroundColor: servillantasColors.lightGreen,
    iconColor: servillantasColors.lightGreen,
    linkColor: servillantasColors.lightGreen,
  },
  branchesSection: {
    imageOverlayColor: servillantasColors.darkImageOverlay,
    branchesBackground: servillantasColors.lightGreen,
    InfoTitleTextColor: servillantasColors.white,
    infoTextColor: servillantasColors.white,
    titleBorderColor: servillantasColors.primary,
  },
  newsletter: {
    backgroundColor: servillantasColors.green,
    textColor: servillantasColors.white,
    colorLine: servillantasColors.lightGreen,
    backgroundColorInput: servillantasColors.darkerGreen,
  },
  benefits: {
    backgroundColor: servillantasColors.disabled,
    checkColor: servillantasColors.primary,
  },
  serviceCard: {
    backgroundColor: servillantasColors.white,
    textColor: servillantasColors.black,
    iconColor: servillantasColors.primary,
  },
  tireDetails: {
    dataTitleBackgroundColor: servillantasColors.disabled,
    dataValueBackgroundColor: servillantasColors.black,
    dataValueTextColor: servillantasColors.white,
  },
  textInput: {
    textColor: servillantasColors.black,
    borderColor: servillantasColors.disabled,
    focusedBorderColor: servillantasColors.primary,
    placeHolderTextColor: servillantasColors.disabled,
  },
  textBoxInput: {
    textColor: servillantasColors.black,
    borderColor: servillantasColors.disabled,
    focusedBorderColor: servillantasColors.primary,
    placeHolderTextColor: servillantasColors.disabled,
  },
  selectInput: {
    focusedBorderColor: servillantasColors.primary,
    placeHolderTextColor: servillantasColors.disabled,
  },
  contactModal: {
    titleTextColor: servillantasColors.primary,
    avararBorderRadiusColor: servillantasColors.primary,
    contactNameTextColor: servillantasColors.gray,
  },
  categoryHero: {
    textColor: servillantasColors.white,
    lineColor: servillantasColors.primary,
  },
  filterModal: {
    activeTextColor: servillantasColors.primary,
  },
  modalFilterStep: {
    activeColor: servillantasColors.primary,
    disabledColor: servillantasColors.disabled,
    optionTextColor: servillantasColors.black,
  },
  pagination: {
    primaryColor: servillantasColors.black,
    activeColor: servillantasColors.primary,
  },
  relatedProducts: {
    primaryColor: servillantasColors.disabled,
    activeColor: servillantasColors.primary,
  },
  listProducts: {
    primaryColor: servillantasColors.primary,
  },
  appointmentForm: {
    titleColor: servillantasColors.primary,
  },
  heroService: {
    iconColor: servillantasColors.primary,
  },
  branches: {
    primaryColor: servillantasColors.black,
    secondaryColor: servillantasColors.primary,
    activeColor: servillantasColors.lightGreen,
    ternaryColor: servillantasColors.white,
  },
  reencaucheSection: {
    primaryColor: servillantasColors.primary,
    secondaryColor: servillantasColors.white,
  },
  services: {
    primaryColor: servillantasColors.primary,
  },
  servicePageCard: {
    primaryColor: servillantasColors.primary,
    secondaryColor: servillantasColors.white,
  },
  servicesCarousel: {
    primaryColor: servillantasColors.primary,
  },
  heroContact: {
    primaryColor: servillantasColors.primary,
    secondaryColor: servillantasColors.white,
  },
  contactContentSection: {
    primaryColor: servillantasColors.primary,
    secondaryColor: servillantasColors.white,
  },
  contactInfo: {
    primaryColor: servillantasColors.primary,
    secondaryColor: servillantasColors.white,
  },
  socialMediaSection: {
    primaryColor: servillantasColors.primary,
    secondaryColor: servillantasColors.white,
    bgColor: servillantasColors.black,
  },
  blogFilter: {
    primaryColor: servillantasColors.black,
    secondaryColor: servillantasColors.white,
    activeColor: servillantasColors.primary,
  },
  postCard: {
    primaryColor: servillantasColors.primary,
    secondaryColor: servillantasColors.black,
  },
  singlePost: {
    primaryColor: servillantasColors.primary,
    secondaryColor: servillantasColors.gray,
  },
  shareSocial: {
    primaryColor: servillantasColors.gray,
    secondaryColor: servillantasColors.white,
    facebookColor: servillantasColors.facebook,
    whatsappColor: servillantasColors.whatsapp,
    twitterColor: servillantasColors.twitter,
    linkedinColor: servillantasColors.linkedin,
  },
  faqs: {
    primaryColor: servillantasColors.primary,
  },
  faqsCategorySection: {
    primaryColor: servillantasColors.primary,
  },
  accordion: {
    primaryColor: servillantasColors.gray,
    borderColor: servillantasColors.disabled,
  },
  howDoesServiceWork: {
    descriptionBackgroundColor: servillantasColors.primary,
    descriptionFontColor: servillantasColors.white,
  },
  heroOffers: {
    primaryColor: servillantasColors.primary,
    iconColor: servillantasColors.orange,
    textColor: servillantasColors.gray,
  },
  login: {
    primaryColor: servillantasColors.primary,
  },
};

export default lightTheme;
