// @vendors
import React from "react";
import styled from "styled-components";
import queryString from "query-string";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Layout from "../containers/Layout";
import BranchesPageSection from "../containers/branches-page-section";
import Cta from "../containers/cta-section";

const Wrapper = styled.section``;

const Header = styled.header`
  margin: 8rem 0 2rem;
  text-align: center;

  ${MEDIA_QUERIES.landscape} {
    margin-bottom: 4rem;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.branches.primaryColor};
`;

const Description = styled.h5`
  color: ${({ theme }) => theme.branches.secondaryColor};
`;

export default function Branches({ location }) {
  const queryStringParsed = queryString.parse(location.search);

  return (
    <Layout title="Sedes">
      <Wrapper>
        <Header>
          <Title>Sedes de Servicio</Title>
          <Description>Encuentra la sede de servicio más cercana</Description>
        </Header>
        <BranchesPageSection
          lat={Number(queryStringParsed.lat)}
          lng={Number(queryStringParsed.lng)}
        />
        <Cta />
      </Wrapper>
    </Layout>
  );
}
