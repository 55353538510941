// @vendors
import React from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";

// @components
import Button from "./button";

// @styledComponents
import {
  BrandImage,
  CardTitle,
  CardTitleReference,
  TireContent,
  TireInfo,
  StyledTireInfo,
  ActualPrice,
  PreviousPrice,
} from "./tire-card";

// @constants
import { MEDIA_QUERIES } from "../constants";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 620px;
  margin-top: 5rem;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;

  ${MEDIA_QUERIES.landscape} {
    flex-direction: row;
  }
`;

const Content = styled(TireContent)`
  padding: 0;

  ${MEDIA_QUERIES.tabletMax} {
    margin-left: 0;
  }
`;

const Image = styled.img`
  width: auto;
  height: 430px;
  margin-right: 3rem;

  ${MEDIA_QUERIES.landscapeMax} {
    height: 200px;
    margin: 1rem 0;
  }
`;

const TireDetailsBrandImage = styled(BrandImage)`
  transform: translateY(0%);
  margin: 0 0 0.8rem;
`;

const Info = styled(TireInfo)`
  margin-top: 0;

  ${MEDIA_QUERIES.tabletMax} {
    padding: 0;
    align-items: center;
  }
`;

const Specifications = styled.div`
  margin: 15px 0 37px;
  width: 100%;
  max-width: 480px;
`;

const TireDataTitle = styled.p`
  display: inline-block;
  white-space: nowrap;
  flex-shrink: 1;
  flex-grow: 1;
  background-color: ${({ theme }) =>
    theme.tireDetails.dataTitleBackgroundColor};
`;

const TireDataValue = styled.div`
  min-width: 160px;
  flex-shrink: 0;

  p {
    display: inline-block;
    color: ${({ theme }) => theme.tireDetails.dataValueTextColor};
    background-color: ${({ theme }) =>
      theme.tireDetails.dataValueBackgroundColor};
  }

  ${MEDIA_QUERIES.tabletMax} {
    flex-shrink: 1;
    min-width: 0px;
    p {
      display: block;
    }
  }
`;

const TireDetailsData = styled.div`
  margin: 3px 0;
  display: flex;

  p {
    padding: 10px;
    font-weight: bold;
  }

  ${MEDIA_QUERIES.tabletMax} {
    flex-grow: 0;
    flex-wrap: wrap;
  }
`;

const TireDetailsPrice = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 20px;
    white-space: nowrap;
  }

  ${MEDIA_QUERIES.tabletMax} {
    width: 100%;
    flex-direction: column;

    button {
      margin: 12px 0;
    }
  }
`;

const Heading = styled(CardTitle)`
  margin-bottom: 0.8rem;
`;

const SalesPrice = styled(PreviousPrice)`
  margin-right: 0.5rem;
`;

export default function TireDetails({
  handleOpenModal,
  tire: {
    image,
    size,
    brand,
    design,
    category,
    preference,
    speed_index,
    load_index,
    treadwear,
    tread_depth,
    price,
    discount,
  },
}) {
  return (
    <Wrapper>
      <Container>
        {image && (
          <Image
            src={image}
            alt={`${size.width.width}/${size.profile.profile}/R${size.wheel.wheel}`}
          />
        )}
        <Content>
          <Info>
            <Heading>
              <h2>{`${brand.brand} ${design.design}`}</h2>
              <CardTitleReference as="h2">
                {`${size.width.width}/${size.profile.profile}/R${size.wheel.wheel}`}
              </CardTitleReference>
            </Heading>
            {brand.image && (
              <TireDetailsBrandImage src={brand.image} alt={brand.brand} />
            )}
            {/* <TireDetailsBrandImage src={brendImage} alt={brand.brand} /> */}
            <StyledTireInfo>{category.category}</StyledTireInfo>
            {preference && (
              <StyledTireInfo>
                Ideal para:{" "}
                <span className="text-gray">{preference.preference}</span>
              </StyledTireInfo>
            )}
            <Specifications>
              {speed_index && (
                <TireDetailsData>
                  <TireDataTitle>Índice de velocidad</TireDataTitle>
                  <TireDataValue>
                    <p>V - {speed_index} km/h</p>
                  </TireDataValue>
                </TireDetailsData>
              )}
              {load_index && (
                <TireDetailsData>
                  <TireDataTitle>Índice de carga</TireDataTitle>
                  <TireDataValue>
                    <p>{load_index}</p>
                  </TireDataValue>
                </TireDetailsData>
              )}
              {treadwear && (
                <TireDetailsData>
                  <TireDataTitle>Treadwear</TireDataTitle>
                  <TireDataValue>
                    <p>{treadwear}</p>
                  </TireDataValue>
                </TireDetailsData>
              )}
              {tread_depth && (
                <TireDetailsData>
                  <TireDataTitle>Profundidad de banda</TireDataTitle>
                  <TireDataValue>
                    <p>{tread_depth}</p>
                  </TireDataValue>
                </TireDetailsData>
              )}
            </Specifications>
            <TireDetailsPrice>
              {discount !== 0 && (
                <SalesPrice>
                  <NumberFormat
                    value={price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </SalesPrice>
              )}
              <ActualPrice>
                <NumberFormat
                  value={
                    discount !== 0
                      ? Math.ceil(price - (price * discount) / 100)
                      : price
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                /<p>Unid.</p>
              </ActualPrice>
              <Button onClick={handleOpenModal}>Quiero esta llanta</Button>
            </TireDetailsPrice>
          </Info>
        </Content>
      </Container>
    </Wrapper>
  );
}
