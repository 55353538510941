// @vendors
import React from "react";
import styled, { css } from "styled-components";
import { string, number, bool, func, oneOfType } from "prop-types";

const inputFieldBorder = css`
  ${({ bordered, theme }) =>
    bordered
      ? `
      border: 1px solid ${theme.textInput.borderColor}
    `
      : `
      border: none;
      border-bottom: 1px solid ${theme.textInput.borderColor}
    `}
`;

const inputFieldFocusedBorder = css`
  ${({ bordered, theme }) =>
    bordered
      ? `
      border: 1px solid ${theme.textInput.focusedBorderColor}
    `
      : `
      border-bottom: 1px solid ${theme.textInput.focusedBorderColor}
    `}
`;

const StyledTextInput = styled.div`
  display: ${({ small }) => (small ? "inline-flex" : "flex")};
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin: 5px;
`;

const InputLabel = styled.label`
  display: ${({ bordered }) => (bordered ? "none" : "inline-block")};
  font-weight: bold;
  color: ${({ theme }) => theme.textInput.textColor};
  transition: color 0.15s ease-in-out;
`;

const InputField = styled.input`
  display: block;
  width: 100%;
  order: 1;
  padding: ${({ bordered }) =>
    bordered ? "15px 15px 15px 25px" : "8px 8px 4px 0"};
  ${inputFieldBorder};
  font-size: 16px;
  transition: border 0.15s ease-in-out;

  :focus {
    outline: none;
    ${inputFieldFocusedBorder}
  }

  :focus + label {
    color: ${({ theme }) => theme.textInput.focusedBorderColor};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textInput.placeHolderTextColor};
  }
`;

const TextInput = ({
  id,
  label,
  bordered,
  small,
  disabled,
  required,
  value,
  onChange,
  placeholder,
  type,
  number,
  name,
}) => (
  <StyledTextInput small={small}>
    <InputField
      type={type}
      bordered={bordered}
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      onChange={e => onChange(e.target.value, number, e.target.name)}
    />
    {label.length !== 0 && (
      <InputLabel htmlFor={id} bordered={bordered}>
        {label}
      </InputLabel>
    )}
  </StyledTextInput>
);

export default TextInput;

TextInput.propTypes = {
  type: string,
  bordered: bool,
  disabled: bool,
  required: bool,
  id: string,
  small: bool,
  label: string,
  value: oneOfType([string, number]),
  onChange: func,
  placeholder: string,
};

TextInput.defaultProps = {
  type: "text",
  bordered: false,
  small: false,
  id: "",
  label: "",
  value: "",
  onChange: function() {},
  placeholder: "",
  disabled: false,
};
