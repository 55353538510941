import React, { useState } from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import BranchPageCard from "../components/branch-page-card";
import BranchesMap from "../components/branches-map";

// @mocked-data
import branches from "../data/branches";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1260px;
  margin: 0 auto 7rem;

  ${MEDIA_QUERIES.landscape} {
    flex-direction: row;
  }
`;

const List = styled.div`
  width: 100%;
  max-height: 400px;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;

  ${MEDIA_QUERIES.landscape} {
    width: 30%;
    max-height: 500px;
    margin-right: 1rem;
    margin-bottom: 0;
    text-align: left;
  }
`;

const Map = styled.div`
  position: relative;
  width: 100%;
  height: 400px;

  ${MEDIA_QUERIES.landscape} {
    width: 70%;
    height: auto;
  }
`;

export default function BranchesPageSection({ lat, lng }) {
  const [currentLat, setCurrentLat] = useState(
    isNaN(lat) ? branches[0].lat : lat,
  );
  const [currentLng, setCurrentLng] = useState(
    isNaN(lng) ? branches[0].lng : lng,
  );

  function handleActiveBranch(lat, lng) {
    setCurrentLat(lat);
    setCurrentLng(lng);
  }

  return (
    <Wrapper>
      <List>
        {branches.map(branch => (
          <BranchPageCard
            key={branch.title}
            {...branch}
            isActive={branch.lat === currentLat && branch.lng === currentLng}
            handleActiveBranch={handleActiveBranch}
          />
        ))}
      </List>

      <Map>
        <BranchesMap
          apiKey="AIzaSyCDoRCFyHstTApdzx4ufdlMojNMzNc13Mg"
          lat={currentLat}
          lng={currentLng}
        />
      </Map>
    </Wrapper>
  );
}
