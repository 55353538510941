import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

import CloseIcon from "../assets/icons/close";

const Wrapper = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.main.boxShadow};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 80%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.modal.backgroundColor};

  ${MEDIA_QUERIES.tablet} {
    min-width: 600px;
  }
`;

const Close = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.modal.closeBackground};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERIES.tablet} {
    top: 0.8rem;
    right: 0.8rem;
  }

  svg {
    width: 1rem;
    height: 1rem;
    fill: ${({ theme }) => theme.modal.closeSvgColor};
  }
`;

const ModalContent = styled.div`
  padding: ${({ noPadding }) => (noPadding ? "0" : "1rem")};
  flex-grow: 1;
  display: flex;
`;

export default function Modal({ children, onCloseClick, noPadding }) {
  return (
    <Wrapper>
      <Container>
        <Close onClick={onCloseClick} type="button">
          <CloseIcon />
        </Close>
        <ModalContent noPadding={noPadding}>{children}</ModalContent>
      </Container>
    </Wrapper>
  );
}
