import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

// @components
import Loading from "./Loading";

function BranchesMap({ google, lat, lng }) {
  return (
    <Map
      google={google}
      zoom={18}
      initialCenter={{
        lat,
        lng,
      }}
      center={{
        lat,
        lng,
      }}
    >
      <Marker position={{ lat, lng }} />
    </Map>
  );
}

export default GoogleApiWrapper(props => ({
  apiKey: props.apiKey,
  LoadingContainer: Loading,
}))(BranchesMap);
