import React from "react";
import styled from "styled-components";

// @components
import PostCard from "../components/post-card";

const Wrapper = styled.section`
  margin-bottom: 5rem;
  text-align: center;
`;

const Title = styled.h3`
  margin-bottom: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default function RelatedPosts({ posts }) {
  return (
    <Wrapper>
      <Title>Ofertas relacionados</Title>
      <Container>
        {posts.map(post => (
          <PostCard key={post.id} {...post} />
        ))}
      </Container>
    </Wrapper>
  );
}
