// @vendors
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { string, bool, element, elementType, arrayOf } from "prop-types";

// @components
import Alert from "../../components/alert";
import DashboardHeader from "../../components/dashboard/header";
import DashboardHeading from "../../components/dashboard/heading";
import CopyrightFooter from "../../components/copyright-footer";

export default function Layout({
  title,
  notification,
  Icon,
  heading,
  nested,
  isHome,
  children,
}) {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {notification !== "" && <Alert message={notification} />}
      <DashboardHeader />
      {!isHome && (
        <DashboardHeading Image={Icon} title={heading} nested={nested} />
      )}
      {children}
      <CopyrightFooter bgGray />
    </Fragment>
  );
}

Layout.propTypes = {
  title: string,
  Icon: elementType,
  children: arrayOf(element),
  notification: string,
  heading: string,
  nested: bool,
  isHome: bool,
};

Layout.defaultProps = {
  title: "",
  notification: "",
  heading: "",
  nested: false,
  isHome: false,
};
