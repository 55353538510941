// @vendors
import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @icons
import PointerIcon from "../assets/icons/pointer";
import PhoneIcon from "../assets/icons/phone";
import LongArrow from "../assets/icons/long-arrow";

// @styles
import { MEDIA_QUERIES } from "../constants";

const Container = styled.div`
  display: inline-block;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 150px;
  height: 280px;
  margin: 0.6rem auto;
  box-shadow: 0 2px 5px 0 ${({ theme }) => theme.main.boxShadow};
  background-color: ${({ theme }) => theme.branchCard.backgroundColor};
  text-align: left;

  ${MEDIA_QUERIES.mobileMax} {
    max-width: 95%;
    width: 95%;
  }
`;

const Title = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 86px;
  padding: 22px 30px;
  background-color: ${({ theme }) => theme.branchCard.titleBackgroundColor};
  display: flex;
  align-items: center;

  h3 {
    color: ${({ theme }) => theme.branchCard.titleColor};
    font-size: 16px;

    ${MEDIA_QUERIES.landscape} {
      font-size: 18px;
    }
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 17px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    flex-shrink: 0;
    height: 2rem;
  }

  p {
    font-size: 14px;

    ${MEDIA_QUERIES.tablet} {
      font-size: 16px;
    }
  }
`;

const InfoContainer = styled.div`
  overflow: hidden;
`;

const LinkContainer = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Info = styled.p`
  margin-left: 5px;
`;

const SeeMapLink = styled.p`
  cursor: pointer;
  font-weight: bold;
  color: ${({ theme }) => theme.branchCard.linkColor};
`;

function BranchCard({ title, address, city, country, phone, theme, link }) {
  return (
    <Container>
      <Title>
        <h3>{title}</h3>
      </Title>
      <Content>
        <div>
          <Section>
            <PointerIcon color={theme.branchCard.iconColor} />
            <InfoContainer>
              <Info>{address}</Info>
              <Info>
                {city}, {country}
              </Info>
            </InfoContainer>
          </Section>
          {phone && (
            <Section>
              <PhoneIcon color={theme.branchCard.iconColor} />
              <Info>{phone}</Info>
            </Section>
          )}
        </div>
        <Link to={`/sedes?${link}`}>
          <LinkContainer>
            <SeeMapLink>Ver mapa</SeeMapLink>
            <LongArrow color={theme.branchCard.iconColor} />
          </LinkContainer>
        </Link>
      </Content>
    </Container>
  );
}

export default withTheme(BranchCard);

BranchCard.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};
