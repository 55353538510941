import gql from "graphql-tag";

const GET_SERVICES = gql`
  {
    services {
      id
      slug
      image
      image_path
      icon
      icon_path
      name
      how_works
      description
      author {
        email_sender
      }
    }
  }
`;

const GET_SERVICE = gql`
  query getService($slug: String!) {
    service(slug: $slug) {
      id
      slug
      name
      description
      how_works
      icon
      icon_path
      image
      image_path
    }
  }
`;

export { GET_SERVICES, GET_SERVICE };
