// @vendors
import React from 'react';
import { useQuery } from "@apollo/react-hooks";

// @constants
import { MAIN_FILTER_TYPES } from "../../../../hooks-store/main-filter-store"

// @components
import { StyledFilterModalOption } from "../../filter-styles-components";

// @store-hooks
import { useStore } from "../../../../hooks-store/store";

// @queries
import { GET_WIDTHS } from "../../../../requests/queries/tires";

const WidhOptions = () => {
  const dispatch = useStore(false)[1];
  const { loading, error, data: { widths } = { widths: []} } = useQuery(GET_WIDTHS);
  if (loading) {
    return <div>Loading</div>
  }

  if (error) {
    return <div>Ocurrió un error cargando los anchos</div>
  }

  return widths.map(width => (
      <StyledFilterModalOption
        key={width.id}
        filterOption
        onClick={() => {
          dispatch(
            MAIN_FILTER_TYPES.updateSizeField,
            { fieldName: "width", value: width.width }
          )
        }}
      >
        {width.width}
      </StyledFilterModalOption>
    )
  );
}

export default WidhOptions;