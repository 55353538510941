import gql from "graphql-tag";

const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      name
    }
  }
`;

const GET_POST = gql`
  query getPost($slug: String!) {
    post(slug: $slug) {
      id
      slug
      title
      image
      image_path
      body
      categories {
        id
        name
      }
    }
  }
`;

const GET_POSTS = gql`
  query initialData($category: String, $numPosts: Int, $currentBatch: Int) {
    posts(
      category: $category
      exclude: ""
      numPosts: $numPosts
      currentBatch: $currentBatch
    ) {
      id
      slug
      title
      image
      created_at
      categories {
        id
        name
      }
    }

    total_posts(category: $category) {
      id
    }
  }
`;

const GET_POSTS_DASHBOARD = gql`
  query posts($numPosts: Int, $currentBatch: Int) {
    posts(numPosts: $numPosts, currentBatch: $currentBatch) {
      id
      slug
      title
      image_path
      created_at
    }

    total_posts {
      id
    }
  }
`;

export { GET_CATEGORIES, GET_POST, GET_POSTS, GET_POSTS_DASHBOARD };
