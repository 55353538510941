// @vendors
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + label {
      background: ${({ theme }) => theme.main.lightGreenColor};

      &::after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
      }
    }
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 60px;
    height: 30px;
    background: ${({ theme }) => theme.main.grayDarkerColor};
    display: block;
    border-radius: 100px;
    position: relative;

    &:active {
      &::after {
        width: 30px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      background: ${({ theme }) => theme.main.backgroundColor};
      border-radius: 90px;
      transition: 0.3s;
    }
  }
`;

function ToggleInput({ id, onChange, value }) {
  return (
    <Wrapper>
      <input type="checkbox" id={id} onChange={onChange} checked={value} />
      <label htmlFor={id}>Toggle</label>
    </Wrapper>
  );
}

export default ToggleInput;
