// @vendors
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Slider from "react-slick";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import TireCard from "../components/tire-card";

const Wrapper = styled.section`
  max-width: 1260px;
  margin: 5rem auto;

  ${MEDIA_QUERIES.desktopXL} {
    margin: 7rem auto;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    margin: 1rem 0;
    padding-left: 0;
    list-style-type: none;

    button {
      width: 26px;
      height: 26px;
      margin: 0 0.3rem;
      padding: 0;
      border: 0;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.relatedProducts.primaryColor};
      color: ${({ theme }) => theme.relatedProducts.primaryColor};
      outline: none;
      cursor: pointer;

      :hover {
        background-color: ${({ theme }) => theme.relatedProducts.activeColor};
        color: ${({ theme }) => theme.relatedProducts.activeColor};
      }

      ::before {
        content: "";
        color: ${({ theme }) => theme.relatedProducts.primaryColor};
      }
    }

    .slick-active {
      button {
        background-color: ${({ theme }) => theme.relatedProducts.activeColor};
        color: ${({ theme }) => theme.relatedProducts.activeColor};
      }
    }
  }
`;

const Title = styled.h4`
  text-align: center;
`;

export default function RelatedProducts({ tires }) {
  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    infinite: tires.length > 2,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <Title>Llantas relacionados</Title>
      <Slider {...settings}>
        {tires.map((tire) => (
          <TireCard key={tire.id} {...tire} />
        ))}
      </Slider>
    </Wrapper>
  );
}

RelatedProducts.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
};

RelatedProducts.defaultProps = {
  children: [],
};
