// @vendors
import React from "react";
import styled from "styled-components";

// @components
import SearchSelect from "../components/search-select";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @data
const optionsNumPage = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
];

const optionsPrices = [
  { label: "De mayor a menor", value: "price_DESC" },
  { label: "De menor a mayor", value: "price_ASC" },
];

const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  max-width: 1180px;
  width: 50%;
  margin: 1rem auto;

  ${MEDIA_QUERIES.tablet} {
    width: 95%;
    margin: 4rem auto 2rem;
  }

  ${MEDIA_QUERIES.desktop} {
    width: 100%;
  }

  ${MEDIA_QUERIES.desktopXL} {
    max-width: 1280px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;

  ${MEDIA_QUERIES.tablet} {
    width: 75%;
    margin-bottom: 0;
  }

  ${MEDIA_QUERIES.desktop} {
    width: 85%;
  }
`;

const Title = styled.h6`
  width: 100%;
`;

const NumResults = styled.div`
  width: 100%;
  text-align: center;

  ${MEDIA_QUERIES.tablet} {
    width: 20%;
  }

  ${MEDIA_QUERIES.desktop} {
    width: 15%;
  }
`;

export default function FiltersCategory({
  handleChangeBrand,
  handleChangePrice,
  currentPrice,
  currentNum,
  handleChangeNum,
  categoryTires,
}) {
  const parseCurrentNum = {
    label: String(currentNum),
    value: currentNum,
  };

  const parseCurrentPrice = {
    label:
      currentPrice === "price_ASC" ? "De menor a mayor" : "De mayor a menor",
    value: currentPrice,
  };

  let brandOptions = [{ label: "Todas", value: "" }];
  let brands = [];
  categoryTires.forEach((item) => {
    if (!brands.includes(item.brand.brand)) {
      brandOptions.push({
        label: item.brand.brand,
        value: item.brand.brand,
      });

      brands.push(item.brand.brand);
    }
  });

  return (
    <Wrapper>
      <Container>
        <Title>Filtrar por: </Title>
        <SearchSelect
          options={brandOptions}
          placeholder="Marca"
          onChange={(e) => handleChangeBrand(e)}
        />
        <SearchSelect
          options={optionsPrices}
          placeholder="Precio"
          defaultValue={parseCurrentPrice}
          onChange={(e) => handleChangePrice(e)}
        />
      </Container>
      <NumResults>
        <Title>Mostrar </Title>
        <SearchSelect
          options={optionsNumPage}
          placeholder={String(currentNum)}
          defaultValue={parseCurrentNum}
          type="secondary"
          onChange={(e) => handleChangeNum(e)}
        />
      </NumResults>
    </Wrapper>
  );
}
