// @images
import carImage from "../assets/images/hero-car-category.png";
import vanImage from "../assets/images/hero-van-category.png";
import truckImage from "../assets/images/hero-truck-category.png";

const categories = [
  {
    id: 1,
    image: carImage,
    title: "AUTOMÓVILES",
    category: "automoviles"
  },
  {
    id: 2,
    image: vanImage,
    title: "CAMIONETAS",
    category: "camionetas"
  },
  {
    id: 3,
    image: truckImage,
    title: "CAMIONES",
    category: "camiones"
  }
];

export default categories;
