import gql from "graphql-tag";

const GET_TIRES = gql`
  query tires($numTires: Int, $currentBatch: Int, $query: String) {
    tires_dashboard(
      numTires: $numTires
      currentBatch: $currentBatch
      query: $query
    ) {
      id
      slug
      reference
      size {
        id
      }
    }

    total_tires_dashboard(query: $query) {
      id
    }
  }
`;

const GET_TIRE = gql`
  query tire($slug: String!, $width: Int, $profile: Int, $wheel: String) {
    tire(slug: $slug) {
      id
      slug
      reference
      available
      price
      brand {
        id
        brand
        image
      }
      design {
        id
        design
      }
      category {
        id
        category
      }
      size {
        id
        width {
          id
          width
        }
        profile {
          id
          profile
        }
        wheel {
          id
          wheel
        }
      }
      preference {
        id
        preference
      }
      treadwear
      tread_depth
      benefits
      discount
      speed_index
      load_index
      image
    }

    get_related_tires(
      width: $width
      profile: $profile
      wheel: $wheel
      currentTire: $slug
    ) {
      id
      slug
      image
      brand {
        id
        brand
        image
      }
      design {
        id
        design
      }
      category {
        id
        category
      }
      preference {
        id
        preference
      }
      size {
        id
        width {
          id
          width
        }
        profile {
          id
          profile
        }
        wheel {
          id
          wheel
        }
      }
      price
      discount
    }
  }
`;

const GET_TIRES_CATEGORY = gql`
  query tires_category(
    $numTires: Int
    $currentBatch: Int
    $category: String
    $priceOrder: String
    $brand: String
  ) {
    tires_category(
      numTires: $numTires
      currentBatch: $currentBatch
      category: $category
      priceOrder: $priceOrder
      brand: $brand
    ) {
      id
      slug
      image
      brand {
        id
        brand
        image
      }
      design {
        id
        design
      }
      size {
        width {
          width
        }
        profile {
          profile
        }
        wheel {
          wheel
        }
      }
      category {
        id
        category
      }
      preference {
        id
        preference
      }
      price
      discount
    }

    total_tires_category(category: $category) {
      id
      brand {
        id
        brand
      }
    }
  }
`;

const GET_TIRES_OFFERS = gql`
  query tires_offers(
    $numTires: Int
    $currentBatch: Int
    $priceOrder: String
    $brand: String
  ) {
    tires_offers(
      numTires: $numTires
      currentBatch: $currentBatch
      priceOrder: $priceOrder
      brand: $brand
    ) {
      id
      slug
      image
      brand {
        id
        brand
        image
      }
      design {
        id
        design
      }
      size {
        width {
          width
        }
        profile {
          profile
        }
        wheel {
          wheel
        }
      }
      category {
        id
        category
      }
      preference {
        id
        preference
      }
      price
      discount
    }

    total_tires_offers {
      id
      brand {
        id
        brand
      }
    }
  }
`;

const GET_SIZES = gql`
  query sizes {
    get_sizes {
      id
      width {
        id
        width
      }
      profile {
        id
        profile
      }
      wheel {
        id
        wheel
      }
    }

    widths {
      id
      width
    }

    profiles {
      id
      profile
    }

    wheels {
      id
      wheel
    }
  }
`;

const GET_TIRE_BRANDS = gql`
  query tireBrands {
    tireBrands {
      id
      brand
      image
    }
  }
`;

const GET_TIRE_DESIGNS = gql`
  query tireDesigns($brand: ID) {
    tireDesigns(brand: $brand) {
      id
      design
    }
  }
`;

const GET_TIRE_CATEGORIES = gql`
  query tireCategories {
    tireCategories {
      id
      category
    }
  }
`;

const GET_PREFERENCES = gql`
  query preferences {
    preferences {
      id
      preference
    }
  }
`;

const GET_WIDTHS = gql`
  query get_widths {
    widths {
      width
      id
    }
  }
`;

const GET_WIDTHS_SIZE = gql`
  query get_widths {
    get_widths {
      width {
        width
      }
    }
  }
`;

const GET_WIDTHS_PROFILES = gql`
  query get_widths_profiles($width: Int!) {
    get_widths_profiles(width: $width) {
      profile {
        profile
        id
      }
    }
  }
`;

const GET_WIDTHS_PROFILES_WHEELS = gql`
  query get_widths_profiles_wheels($width: Int!, $profile: Int!) {
    get_widths_profiles_wheels(width: $width, profile: $profile) {
      wheel {
        wheel
        id
      }
    }
  }
`;

const GET_TIRES_BY_VEHICLE = gql`
  query tires_car($carBrand: String!, $carModel: Int!, $carReference: String!) {
    tires_car(
      carBrand: $carBrand
      carModel: $carModel
      carReference: $carReference
    ) {
      sizes {
        tires {
          id
          slug
          reference
          available
          price
          brand {
            id
            brand
            image
          }
          design {
            design
          }
          category {
            category
          }
          size {
            width {
              width
            }
            profile {
              profile
            }
            wheel {
              wheel
            }
          }
          preference {
            preference
          }
          treadwear
          tread_depth
          benefits
          discount
          speed_index
          load_index
          image
        }
      }
    }
  }
`;
const GET_TIRES_BY_SIZE = gql`
  query tires_size($width: Int!, $profile: Int!, $wheel: String!) {
    tires_size(width: $width, profile: $profile, wheel: $wheel) {
      tires {
        id
        slug
        reference
        available
        price
        brand {
          id
          brand
          image
        }
        design {
          design
        }
        category {
          category
        }
        size {
          width {
            width
          }
          profile {
            profile
          }
          wheel {
            wheel
          }
        }
        preference {
          preference
        }
        treadwear
        tread_depth
        benefits
        discount
        speed_index
        load_index
        image
      }
    }
  }
`;

export {
  GET_TIRES,
  GET_TIRE,
  GET_TIRES_CATEGORY,
  GET_TIRES_OFFERS,
  GET_SIZES,
  GET_TIRE_BRANDS,
  GET_TIRE_DESIGNS,
  GET_TIRE_CATEGORIES,
  GET_PREFERENCES,
  GET_WIDTHS,
  GET_WIDTHS_SIZE,
  GET_WIDTHS_PROFILES,
  GET_WIDTHS_PROFILES_WHEELS,
  GET_TIRES_BY_VEHICLE,
  GET_TIRES_BY_SIZE,
};
