// @vendors
import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";

// @requests
import { GET_TIRE } from "../requests/queries/tires";

// @components
import Layout from "../containers/Layout";
import TireDetails from "../components/tire-details";
import Benefits from "../components/benefits";
import RelatedProducts from "../containers/related-products";
import Newsletter from "../components/newsletter";
import ContactModal from "../components/contact-modal";
import Loading from "../components/Loading";

export default function Tire({
  match: {
    params: { slug },
  },
}) {
  const parseSlug = slug.split("-");
  parseSlug.shift();
  parseSlug.splice(3, parseSlug.length);
  const width = parseInt(parseSlug[0], 10);
  const profile = parseInt(parseSlug[1], 10);
  const wheel = parseSlug[2].split("r")[1];
  const [activeModal, setActiveModal] = useState(false);
  const { data, loading, error } = useQuery(GET_TIRE, {
    variables: {
      slug,
      width,
      profile,
      wheel,
    },
  });

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const { tire, get_related_tires } = data;
  const parsedBenefits = JSON.parse(tire.benefits);

  return (
    <Layout
      title={`${tire.size.width.width} ${tire.size.profile.profile} ${tire.size.wheel.wheel} ${tire.brand.brand} ${tire.design.design}`}
    >
      <TireDetails handleOpenModal={() => setActiveModal(true)} tire={tire} />
      <Benefits benefits={parsedBenefits} />

      {get_related_tires && get_related_tires.length > 0 && (
        <RelatedProducts tires={get_related_tires} />
      )}
      <Newsletter />
      {activeModal && (
        <ContactModal onCloseClick={() => setActiveModal(false)} tire={tire} />
      )}
    </Layout>
  );
}
