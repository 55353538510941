import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

const HeroBlogWrapper = styled.header`
  width: 90%;
  max-width: 1200px;
  margin: 8rem auto 4rem;
  text-align: center;

  ${MEDIA_QUERIES.desktop} {
    margin-top: 10rem;
  }
`;

const HeroBlogTitle = styled.h1`
  margin-bottom: 1rem;
`;

const HeroBlogDescription = styled.p``;

function HeroBlog() {
  return (
    <HeroBlogWrapper>
      <HeroBlogTitle>Ofertas</HeroBlogTitle>
      <HeroBlogDescription>
        Encuentra aquí contenido de valor e información importante relacionada
        con nuestros servicios.
      </HeroBlogDescription>
    </HeroBlogWrapper>
  );
}

export default HeroBlog;
