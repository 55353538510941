// @vendors
import React from "react";

export default function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="27"
      viewBox="0 0 29 27"
    >
      <path
        fillRule="evenodd"
        d="M17.106 14.538L13.722 17.8a.905.905 0 0 0 0 1.314c.189.181.436.272.682.272.248 0 .494-.09.682-.273l4.827-4.65c.351-.155.6-.527.6-.962 0-.435-.248-.807-.6-.962l-4.827-4.65a.99.99 0 0 0-1.364 0 .905.905 0 0 0 0 1.314l3.383 3.26H.961c-.53 0-.961.465-.961 1.038s.43 1.038.961 1.038h16.145zM15.113 27C22.77 27 29 20.943 29 13.5S22.77 0 15.113 0C9.368 0 4.295 3.36 2.188 8.562a.914.914 0 0 0 .534 1.194.958.958 0 0 0 1.228-.52C5.77 4.742 10.152 1.84 15.113 1.84c6.613 0 11.993 5.23 11.993 11.659 0 6.428-5.38 11.66-11.993 11.66-4.961 0-9.343-2.903-11.163-7.394a.957.957 0 0 0-1.228-.52.913.913 0 0 0-.534 1.194C4.295 23.64 9.368 27 15.113 27z"
      />
    </svg>
  );
}
