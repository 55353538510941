import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// @utils
import formatDateTime from "../utils/formatDateTime";

// @constants
import { MEDIA_QUERIES } from "../constants";

const Wrapper = styled(Link)`
  display: inline-block;
  width: 100%;
  margin-bottom: 2rem;
  text-align: left;
  text-decoration: none;

  ${MEDIA_QUERIES.tablet} {
    margin: 0 0.8rem 2rem;
  }

  ${MEDIA_QUERIES.landscape} {
    width: calc((100% / 3) - 1.6rem);
  }

  &:link {
    color: ${({ theme }) => theme.postCard.secondaryColor};
  }

  &:visited {
    color: ${({ theme }) => theme.postCard.secondaryColor};
  }

  &:hover {
    color: ${({ theme }) => theme.postCard.primaryColor};
  }
`;

const Img = styled.img`
  width: 100%;
  height: 280px;
  margin-bottom: 0.5rem;
  object-fit: cover;

  ${MEDIA_QUERIES.desktopXL} {
    height: 320px;
  }
`;

const Title = styled.h6`
  margin-bottom: 1rem;
  text-transform: none;

  ${MEDIA_QUERIES.landscape} {
    min-height: 2.5rem;
  }
`;

function PostCard({ id, slug, image, title, created_at }) {
  return (
    <Wrapper to={`/ofertas/${slug}`}>
      <Img src={image} />
      <Title>{title}</Title>
      <span>{formatDateTime(created_at)}</span>
    </Wrapper>
  );
}

export default PostCard;
