// @vendors
import React, { useState } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @utils
import { getCookie } from "../../utils/cookies";

//@requests
import { GET_AUTHOR } from "../../requests/queries";
import { UPDATE_EMAIL_SENDER } from "../../requests/mutations";

//@constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import TextInput from "../../components/text-input";
import Button from "../../components/button";
import Loading from "../../components/Loading";
import Alert from "../../components/alert";

const Wrapper = styled.div``;

const Title = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 450px;

  ${MEDIA_QUERIES.tablet} {
    flex-wrap: nowrap;
  }

  ${MEDIA_QUERIES.desktopXL} {
    max-width: 550px;
  }

  div {
    width: 100%;
    margin-bottom: 1rem;

    ${MEDIA_QUERIES.tablet} {
      width: 70%;
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
`;

export default function DashboardEmail() {
  const authorId = getCookie("author");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { loading, error } = useQuery(GET_AUTHOR, {
    variables: { id: authorId },
    onCompleted({ author }) {
      setEmail(author.email_sender);
    },
  });
  const [updateAuthorEmailSender] = useMutation(UPDATE_EMAIL_SENDER, {
    onCompleted({ updateAuthorEmailSender }) {
      if (updateAuthorEmailSender) {
        setMessage("Se ha actualizado correctamente");
      } else {
        setMessage("Ha ocurrido un error intenta nuevamente");
      }

      setTimeout(() => setMessage(""), 2000);
    },
  });

  function handleEmail(e) {
    e.preventDefault();

    updateAuthorEmailSender({ variables: { id: authorId, email } });
  }

  if (loading) return <Loading inline width="450" widthDesktopXL="550" />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  return (
    <Wrapper>
      <Title>Configura el correo principal</Title>
      <Form onSubmit={handleEmail}>
        <TextInput
          bordered
          placeholder="Correo electrónico"
          value={email}
          onChange={setEmail}
          type="email"
        />
        <Button>Guardar</Button>
      </Form>
      {message.length > 0 && <Alert message={message} />}
    </Wrapper>
  );
}
