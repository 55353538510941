import { createGlobalStyle } from "styled-components";
import { MEDIA_QUERIES } from "../../constants";

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }

  body {
    margin: 0;
    font-size: 16px;
    color: ${({ theme }) => theme.main.defaultTextColor};
    background-color: ${({ theme }) => theme.main.backgroundColor};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
  }

  h1 {
    font-size: 40px;

    ${MEDIA_QUERIES.tablet} {
      font-size: 48px;
    }
  }

  h2 {
    font-size: 30px;

    ${MEDIA_QUERIES.tablet} {
      font-size: 36px;
    }
  }

  h3 {
    font-size: 22px;

    ${MEDIA_QUERIES.tablet} {
      font-size: 28px;
    }
  }

  h4 {
    font-size: 20px;

    ${MEDIA_QUERIES.tablet} {
      font-size: 24px;
    }
  }

  h5 {
    font-size: 16px;

    ${MEDIA_QUERIES.tablet} {
      font-size: 20px;
    }
  }

  h6 {
    font-size: 14px;

    ${MEDIA_QUERIES.tablet} {
      font-size: 18px;
    }
  }

  p {
    margin: 0;
    line-height: 1.4;
    font-size: 14px;

    ${MEDIA_QUERIES.tablet} {
      font-size: 16px;
    }

    ${MEDIA_QUERIES.desktopXL} {
      font-size: 18px;
    }
  }

  .slick-track {
    div {
      outline: none;
    }
  }

  .slick-slide {
    > div {
      text-align: center;
    }
  }
`;

export default GlobalStyle;
