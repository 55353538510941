// @vendors
import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";

// @requests
import {
  GET_TIRE,
  GET_SIZES,
  GET_TIRE_BRANDS,
  GET_TIRE_DESIGNS,
  GET_TIRE_CATEGORIES,
  GET_PREFERENCES,
} from "../../requests/queries/tires";
import {
  CREATE_WIDTH,
  CREATE_PROFILE,
  CREATE_WHEEL,
  CREATE_TIRE_BRAND,
  CREATE_TIRE_DESIGN,
  CREATE_PREFERENCE,
  CREATE_TIRE,
  UPDATE_TIRE,
} from "../../requests/mutations/tires";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import Layout from "../../containers/dashboard/Layout";
import DashboardImage from "../../components/dashboard/image";
import TextInput from "../../components/text-input";
import Button from "../../components/button";
import ToggleInput from "../../components/dashboard/toggle-input";
import Loading from "../../components/Loading";
import SelectInput from "../../components/select-input";
import AddInput from "../../containers/dashboard/add-input";
import Updated from "../../components/dashboard/updated";

// @assets
import TireIcon from "../../assets/icons/wheel";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1260px;
  margin: 3rem auto;

  ${MEDIA_QUERIES.landscape} {
    flex-direction: row;
  }
`;

const Images = styled.div`
  ${MEDIA_QUERIES.landscape} {
    width: 30%;
    margin-right: 2rem;
  }
`;

const Label = styled.label`
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  font-weight: bold;
`;

const LabelContainer = styled.div`
  margin-top: 1.5rem;
  padding-left: 0.5rem;

  p {
    font-size: 14px;
    font-weight: bold;
  }
`;

const Content = styled.div`
  ${MEDIA_QUERIES.landscape} {
    width: 70%;
  }

  hr {
    margin: 2rem auto;
  }
`;

const LayoutColumns = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERIES.landscape} {
    flex-direction: row;
    align-items: ${({ start }) => (start ? "flex-start" : "flex-end")};
  }
`;

const Column25 = styled.div`
  ${MEDIA_QUERIES.desktop} {
    width: calc(100% / 4);
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Column33 = styled.div`
  ${MEDIA_QUERIES.landscape} {
    width: calc(100% / 3);
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Column50 = styled.div`
  ${MEDIA_QUERIES.landscape} {
    width: calc(100% / 2);
  }

  &:last-child {
    margin-right: 0;
  }
`;

const BenefitsList = styled.ul`
  margin-bottom: 3rem;
  padding-left: 1.2rem;

  li {
    margin-bottom: 1rem;
  }
`;

const AddBenefit = styled.div`
  text-align: right;
`;

const Btn = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border: 0;
  border-radius: 1.8rem;
  background: transparent;
  color: ${({ theme }) => theme.main.defaultTextColor};
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.main.boxShadow};
  outline: none;
  cursor: pointer;
`;

const Icon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.main.primaryColor};
  color: ${({ theme }) => theme.main.backgroundColor};
  font-size: 1.5rem;
  font-weight: normal;
`;

const NewBtn = styled.button`
  margin: 0.5rem 0;
  border: 0;
  background: none;
  color: ${({ theme }) => theme.main.primaryColor};
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  outline: none;
`;

const InactiveMessage = styled.p`
  margin: 0.7rem 0;
  padding: 0 0.2rem;
  color: ${({ theme }) => theme.main.dangerColor};
  font-size: 0.9rem;
  font-weight: bold;
`;

const ContainerBrandInput = styled.div`
  display: flex;

  > div  {
    ${MEDIA_QUERIES.landscape} {
      width: calc(100% / 2);
    }
  }
`;

const SubmitContainer = styled.div`
  margin-top: 4rem;
`;

export default function DashboardTire({
  match: {
    params: { slug },
  },
}) {
  const ENV = process.env.REACT_APP_ENV || "dev";
  const isNewTire = slug === "nuevo";
  const [isSaved, setIsSaved] = useState(false);
  const [slugName, setSlugName] = useState("");
  const [tireId, setTireId] = useState("");
  const [tireImage, setTireImage] = useState("");
  const [reference, setReferenceName] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [available, setAvailable] = useState(false);
  const [width, setWidth] = useState("");
  const [widthOptions, setWidthOptions] = useState([]);
  const [newWidth, setNewWidth] = useState("");
  const [activeNewWidth, setActiveNewWidth] = useState(false);
  const [profile, setProfile] = useState("");
  const [profileOptions, setProfileOptions] = useState([]);
  const [newProfile, setNewProfile] = useState("");
  const [activeNewProfile, setActiveNewProfile] = useState(false);
  const [wheel, setWheel] = useState("");
  const [wheelOptions, setWheelOptions] = useState([]);
  const [newWheel, setNewWheel] = useState("");
  const [activeNewWheel, setActiveNewWheel] = useState(false);
  const [brand, setBrand] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [newBrand, setNewBrand] = useState("");
  const [activeNewBrand, setActiveNewBrand] = useState(false);
  const [brandImage, setBrandImage] = useState("");
  const [newBrandImage, setNewBrandImage] = useState("");
  const [design, setDesign] = useState("");
  const [newDesign, setNewDesign] = useState("");
  const [activeNewDesign, setActiveNewDesign] = useState(false);
  const [designOptions, setDesignOptions] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [preference, setPreference] = useState("");
  const [newPreference, setNewPreference] = useState("");
  const [activeNewPreference, setActiveNewPreference] = useState(false);
  const [preferenceOptions, setPreferenceOptions] = useState([]);
  const [treadwear, setTreadwear] = useState("");
  const [treadDepth, setTreadDepth] = useState("");
  const [speedIndex, setSpeedIndex] = useState("");
  const [loadIndex, setLoadIndex] = useState("");
  const [benefits, setBenefits] = useState([]);
  const [isBenefitsActive, setIsBenefitsActive] = useState(false);
  const [benefitValue, setBenefitValue] = useState("");

  useQuery(GET_TIRE, {
    variables: {
      slug: slug !== "nuevo" ? slug : "",
    },
    onCompleted: function({ tire }) {
      if (tire) {
        getTiresDesigns({ variables: { brand: tire.brand.id } });

        setSlugName(tire.slug);
        setTireId(tire.id);
        setTireImage(tire.image);
        setReferenceName(tire.reference);
        setPrice(tire.price);
        setDiscount(tire.discount);
        setAvailable(tire.available);
        setWidth({ value: tire.size.width.id, label: tire.size.width.width });
        setProfile({
          value: tire.size.profile.id,
          label: tire.size.profile.profile,
        });
        setWheel({ value: tire.size.wheel.id, label: tire.size.wheel.wheel });
        setBrand({ value: tire.brand.id, label: tire.brand.brand });
        setBrandImage(tire.brand.image);
        setDesign({ value: tire.design.id, label: tire.design.design });
        setCategory({ value: tire.category.id, label: tire.category.category });
        if (tire.preference) {
          setPreference({
            value: tire.preference.id,
            label: tire.preference.preference,
          });
        }
        setTreadwear(tire.treadwear);
        setTreadDepth(tire.tread_depth);
        setSpeedIndex(tire.speed_index);
        setLoadIndex(tire.load_index);
        setBenefits(JSON.parse(tire.benefits));
      }
    },
  });

  const { loading, error } = useQuery(GET_SIZES, {
    onCompleted: data => {
      const { widths, profiles, wheels } = data;

      if (data) {
        const widthsParsed = widths.map(item => {
          return {
            value: item.id,
            label: String(item.width),
          };
        });

        setWidthOptions(widthsParsed);

        const profilesParsed = profiles.map(item => {
          return {
            value: item.id,
            label: String(item.profile),
          };
        });

        setWidthOptions(widthsParsed);

        const wheelsParsed = wheels.map(item => {
          return {
            value: item.id,
            label: item.wheel,
          };
        });

        setWidthOptions(widthsParsed);
        setProfileOptions(profilesParsed);
        setWheelOptions(wheelsParsed);
      }
    },
  });

  useQuery(GET_TIRE_BRANDS, {
    onCompleted: ({ tireBrands }) => {
      const brandsParsed = tireBrands.map(({ id, brand, image }) => {
        return {
          value: id,
          label: brand,
          image,
        };
      });

      setBrandOptions(brandsParsed);
    },
  });

  useQuery(GET_TIRE_CATEGORIES, {
    onCompleted: ({ tireCategories }) => {
      const categoriesParsed = tireCategories.map(({ id, category }) => {
        return {
          value: id,
          label: category,
        };
      });

      setCategoryOptions(categoriesParsed);
    },
  });

  useQuery(GET_PREFERENCES, {
    onCompleted: ({ preferences }) => {
      const preferencesParsed = preferences.map(({ id, preference }) => {
        return {
          value: id,
          label: preference,
        };
      });

      setPreferenceOptions(preferencesParsed);
    },
  });

  const [getTiresDesigns] = useLazyQuery(GET_TIRE_DESIGNS, {
    onCompleted: function({ tireDesigns }) {
      const designsParsed = tireDesigns.map(({ id, design }) => {
        return {
          value: id,
          label: design,
        };
      });

      setDesignOptions(designsParsed);
    },
  });

  const [createWidth] = useMutation(CREATE_WIDTH, {
    onCompleted: function({ createWidth }) {
      const newWidth = { label: createWidth.width, value: createWidth.id };
      const widthsWithoutDuplicates = widthOptions.filter(
        ({ value }) => value !== newWidth.value,
      );

      setWidthOptions([...widthsWithoutDuplicates, newWidth]);
      setWidth(newWidth);
      setActiveNewWidth(false);
      setNewWidth("");
    },
  });

  const [createProfile] = useMutation(CREATE_PROFILE, {
    onCompleted: function({ createProfile }) {
      const newProfile = {
        label: createProfile.profile,
        value: createProfile.id,
      };
      const profilesWithoutDuplicates = profileOptions.filter(
        ({ value }) => value !== newProfile.value,
      );

      setProfileOptions([...profilesWithoutDuplicates, newProfile]);
      setProfile(newProfile);
      setActiveNewProfile(false);
      setNewProfile("");
    },
  });

  const [createWheel] = useMutation(CREATE_WHEEL, {
    onCompleted: function({ createWheel }) {
      const newWheel = { label: createWheel.wheel, value: createWheel.id };
      const wheelsWithoutDuplicates = wheelOptions.filter(
        ({ value }) => value !== newWheel.value,
      );

      setWheelOptions([...wheelsWithoutDuplicates, newWheel]);
      setWheel(newWheel);
      setActiveNewWheel(false);
      setNewWheel("");
    },
  });

  const [createTireBrand] = useMutation(CREATE_TIRE_BRAND, {
    onCompleted: function({ createTireBrand }) {
      const newBrand = {
        label: createTireBrand.brand,
        value: createTireBrand.id,
      };
      const brandsWithoutDuplicates = brandOptions.filter(
        ({ value }) => value !== newBrand.value,
      );

      setBrandOptions([...brandsWithoutDuplicates, newBrand]);
      setBrand(newBrand);
      setBrandImage(createTireBrand.image);
      setActiveNewBrand(false);
      setNewBrand("");
      getTiresDesigns({ variables: { brand: newBrand.value } });
      setDesign("");
    },
  });

  const [createTireDesign] = useMutation(CREATE_TIRE_DESIGN, {
    onCompleted: function({ createTireDesign }) {
      const newDesign = {
        label: createTireDesign.design,
        value: createTireDesign.id,
      };
      const designsWithoutDuplicates = designOptions.filter(
        ({ value }) => value !== newDesign.value,
      );

      setDesignOptions([...designsWithoutDuplicates, newDesign]);
      setDesign(newDesign);
      setActiveNewDesign(false);
      setNewDesign("");
    },
  });

  const [createPreference] = useMutation(CREATE_PREFERENCE, {
    onCompleted: function({ createPreference }) {
      const newPreference = {
        label: createPreference.preference,
        value: createPreference.id,
      };
      const preferencesWithoutDuplicates = preferenceOptions.filter(
        ({ value }) => value !== newPreference.value,
      );

      setPreferenceOptions([...preferencesWithoutDuplicates, newPreference]);
      setPreference(newPreference);
      setActiveNewPreference(false);
      setNewPreference("");
    },
  });

  const [createTire] = useMutation(CREATE_TIRE, {
    onCompleted: function({ createTire }) {
      if (createTire) {
        setIsSaved(true);
        window.location.reload();
      }
    },
  });

  const [updateTire] = useMutation(UPDATE_TIRE, {
    onCompleted: function({ updateTire }) {
      if (updateTire) {
        setIsSaved(true);
        window.location.reload();
      }
    },
  });

  function onChange(event, other, type) {
    switch (type) {
      case "image":
        setTireImage(event);
        break;
      case "tire_ref":
        setReferenceName(event);
        break;
      case "normal_price":
        setPrice(event);
        break;
      case "sales_price":
        setDiscount(event);
        break;
      case "width":
        setWidth(event);
        break;
      case "newWidth":
        setNewWidth(event);
        break;
      case "profile":
        setProfile(event);
        break;
      case "newProfile":
        setNewProfile(event);
        break;
      case "wheel":
        setWheel(event);
        break;
      case "newWheel":
        setNewWheel(event);
        break;
      case "brand":
        setBrand(event);
        setBrandImage(event.image ? event.image : "");
        setDesign("");
        getTiresDesigns({ variables: { brand: event.value } });
        break;
      case "brandImage":
        setBrandImage(event);
        break;
      case "newBrand":
        setNewBrand(event);
        break;
      case "newBrandImage":
        setNewBrandImage(event);
        break;
      case "design":
        setDesign(event);
        break;
      case "newDesign":
        setNewDesign(event);
        break;
      case "category":
        setCategory(event);
        break;
      case "preference":
        setPreference(event);
        break;
      case "newPreference":
        setNewPreference(event);
        break;
      case "treadwear":
        setTreadwear(event);
        break;
      case "treadDepth":
        setTreadDepth(event);
        break;
      case "speedIndex":
        setSpeedIndex(event);
        break;
      case "loadIndex":
        setLoadIndex(event);
        break;
      default:
        break;
    }
  }

  function onDelete() {
    setTireImage("");
  }

  function handleAvailable(e) {
    setAvailable(e.target.checked);
  }

  function handleWidth(e) {
    e.preventDefault();

    createWidth({ variables: { width: parseInt(newWidth, 10) } });
  }

  function handleProfile(e) {
    e.preventDefault();

    createProfile({ variables: { profile: parseInt(newProfile, 10) } });
  }

  function handleWheel(e) {
    e.preventDefault();

    createWheel({ variables: { wheel: newWheel } });
  }

  function handleBrand(e) {
    e.preventDefault();

    createTireBrand({ variables: { brand: newBrand, image: newBrandImage } });
  }

  function handleDesign(e) {
    e.preventDefault();

    createTireDesign({ variables: { design: newDesign, brand: brand.value } });
  }

  function handlePreference(e) {
    e.preventDefault();

    createPreference({ variables: { preference: newPreference } });
  }

  function handleBenefits(e) {
    e.preventDefault();

    setIsBenefitsActive(true);
  }

  function addBenefit(e) {
    e.preventDefault();

    setBenefits([...benefits, benefitValue]);
    setBenefitValue("");
    setIsBenefitsActive(false);
  }

  function handleTire(e) {
    e.preventDefault();

    const widthId = width.value;
    const profileId = profile.value;
    const wheelId = wheel.value;
    const brandId = brand.value;
    const designId = design.value;
    const categoryId = category.value;
    const preferenceId = preference.value;
    const benefitsParsed = JSON.stringify(benefits);
    const currentDate = new Date();
    const parseDate = `${currentDate.getDay()}-${currentDate.getYear()}`;
    const slug = `${parseDate}-${width.label}-${profile.label}-r${wheel.label}-${brand.label}-${design.label}-${category.label}`;

    const dataTire = {
      slug: isNewTire ? slug : slugName,
      reference,
      available,
      price: parseInt(price, 10),
      brand_id: brandId,
      brand_image: brandImage,
      design_id: designId,
      category_id: categoryId,
      preference_id: preferenceId,
      treadwear: parseInt(treadwear, 10),
      tread_depth: treadDepth,
      benefits: benefitsParsed,
      discount: parseInt(discount, 10),
      speed_index: speedIndex,
      load_index: loadIndex,
      image: tireImage,
    };

    if (isNewTire) {
      createTire({
        variables: {
          data: dataTire,
          size: {
            width_id: widthId,
            profile_id: profileId,
            wheel_id: wheelId,
          },
        },
      });
    } else {
      updateTire({
        variables: {
          id: tireId,
          data: dataTire,
          size: {
            width_id: widthId,
            profile_id: profileId,
            wheel_id: wheelId,
          },
        },
      });
    }
  }

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  if (isSaved)
    return (
      <Updated
        to="/administrador/llantas"
        message={`Se ha ${isNewTire ? "creado" : "actualizado"} correctamente`}
      />
    );

  return (
    <Layout
      title={`Administrador - ${isNewTire ? "Nueva llanta" : reference}`}
      Icon={TireIcon}
      heading={`${isNewTire ? "Nueva" : "Actualizar"} llanta`}
      nested
    >
      <Wrapper onSubmit={handleTire}>
        <Images>
          <DashboardImage
            image={tireImage}
            pathImage={tireImage}
            sizes="300px x 500px"
            folder={ENV === "dev" ? `dev/llantas` : "llantas"}
            onChange={onChange}
            onDelete={onDelete}
            position="tire"
          />
          <Label htmlFor="normal_price">Precio normal</Label>
          <TextInput
            bordered
            type="number"
            placeholder="$"
            id="normal_price"
            value={price}
            onChange={onChange}
            name="normal_price"
          />
          <Label htmlFor="sales_price">Porcentaje promoción</Label>
          <TextInput
            bordered
            type="number"
            placeholder="%"
            id="sales_price"
            value={discount}
            onChange={onChange}
            name="sales_price"
          />
          <LabelContainer>
            <p>Disponible</p>
            <ToggleInput
              id="available"
              onChange={handleAvailable}
              value={available}
            />
          </LabelContainer>
        </Images>
        <Content>
          <Label htmlFor="tire_ref">Referencia de llanta</Label>
          <TextInput
            bordered
            placeholder="Escriba la referencia de la llanta"
            id="tire_ref"
            value={reference}
            onChange={onChange}
            name="tire_ref"
          />
          <LayoutColumns>
            <Column33>
              <Label htmlFor="width">Ancho</Label>
              {activeNewWidth ? (
                <AddInput
                  type="number"
                  name="newWidth"
                  value={newWidth}
                  onChange={onChange}
                  onClose={e => setActiveNewWidth(false)}
                  onSave={handleWidth}
                  placeholder="Nuevo ancho"
                />
              ) : (
                <Fragment>
                  <SelectInput
                    id="width"
                    options={widthOptions}
                    value={width}
                    placeholder="Ancho"
                    onChange={onChange}
                  />
                  <NewBtn onClick={e => setActiveNewWidth(true)}>
                    + Agregar ancho
                  </NewBtn>
                </Fragment>
              )}
            </Column33>
            <Column33>
              <Label htmlFor="profile">Perfil</Label>
              {activeNewProfile ? (
                <AddInput
                  type="number"
                  name="newProfile"
                  value={newProfile}
                  onChange={onChange}
                  onClose={e => setActiveNewProfile(false)}
                  onSave={handleProfile}
                  placeholder="Nuevo perfil"
                />
              ) : (
                <Fragment>
                  <SelectInput
                    id="profile"
                    options={profileOptions}
                    value={profile}
                    placeholder="Perfil"
                    onChange={onChange}
                  />
                  <NewBtn onClick={e => setActiveNewProfile(true)}>
                    + Agregar perfil
                  </NewBtn>
                </Fragment>
              )}
            </Column33>
            <Column33>
              <Label htmlFor="wheel">Rin</Label>
              {activeNewWheel ? (
                <AddInput
                  type="text"
                  name="newWheel"
                  value={newWheel}
                  onChange={onChange}
                  onClose={e => setActiveNewWheel(false)}
                  onSave={handleWheel}
                  placeholder="Nuevo rin"
                />
              ) : (
                <Fragment>
                  <SelectInput
                    id="wheel"
                    options={wheelOptions}
                    value={wheel}
                    placeholder="Rin"
                    onChange={onChange}
                  />
                  <NewBtn onClick={e => setActiveNewWheel(true)}>
                    + Agregar rin
                  </NewBtn>
                </Fragment>
              )}
            </Column33>
          </LayoutColumns>
          <LayoutColumns>
            <Column50>
              <Label htmlFor="brand">Marca de llanta</Label>
              {activeNewBrand ? (
                <AddInput
                  type="text"
                  name="newBrand"
                  nameImage="newBrandImage"
                  value={newBrand}
                  showImage
                  image={newBrandImage}
                  onChange={onChange}
                  onClose={e => setActiveNewBrand(false)}
                  onSave={handleBrand}
                  placeholder="Nombre de marca"
                  placeholderImage="URL de la imagen"
                />
              ) : (
                <Fragment>
                  <ContainerBrandInput>
                    <div>
                      <SelectInput
                        id="brand"
                        options={brandOptions}
                        value={brand}
                        placeholder="Marca"
                        onChange={onChange}
                      />
                    </div>
                    <div>
                      <TextInput
                        bordered
                        type="text"
                        name="brandImage"
                        value={brandImage}
                        onChange={onChange}
                        placeholder="Imagen"
                      />
                    </div>
                  </ContainerBrandInput>
                  <NewBtn onClick={e => setActiveNewBrand(true)}>
                    + Agregar marca
                  </NewBtn>
                </Fragment>
              )}
            </Column50>
            <Column50>
              <Label htmlFor="design">Diseño de la marca</Label>
              {activeNewDesign ? (
                <AddInput
                  type="text"
                  name="newDesign"
                  value={newDesign}
                  onChange={onChange}
                  onClose={e => setActiveNewDesign(false)}
                  onSave={handleDesign}
                  placeholder="Nombre del diseño"
                />
              ) : (
                <Fragment>
                  <div>
                    <SelectInput
                      id="design"
                      options={designOptions}
                      value={design}
                      placeholder="Diseño de la marca"
                      onChange={onChange}
                      isDisabled={brand === ""}
                    />
                  </div>
                  {brand !== "" ? (
                    <NewBtn onClick={e => setActiveNewDesign(true)}>
                      + Agregar diseño
                    </NewBtn>
                  ) : (
                    <InactiveMessage>
                      Debes seleccionar una marca
                    </InactiveMessage>
                  )}
                </Fragment>
              )}
            </Column50>
          </LayoutColumns>
          <LayoutColumns start>
            <Column50>
              <Label htmlFor="category">Categoría</Label>
              <SelectInput
                id="category"
                options={categoryOptions}
                value={category}
                placeholder="Categoría"
                onChange={onChange}
              />
            </Column50>
            <Column50>
              <Label htmlFor="preference">Ideal para</Label>
              {activeNewPreference ? (
                <AddInput
                  type="text"
                  name="newPreference"
                  value={newPreference}
                  onChange={onChange}
                  onClose={e => setActiveNewPreference(false)}
                  onSave={handlePreference}
                  placeholder="Nueva preferencia"
                />
              ) : (
                <Fragment>
                  <SelectInput
                    id="preference"
                    options={preferenceOptions}
                    value={preference}
                    placeholder="Ideal para"
                    onChange={onChange}
                  />
                  <NewBtn onClick={e => setActiveNewPreference(true)}>
                    + Agregar preferencia
                  </NewBtn>
                </Fragment>
              )}
            </Column50>
          </LayoutColumns>
          <LayoutColumns>
            <Column25>
              <Label htmlFor="speedIndex">Indice de Velocidad</Label>
              <TextInput
                id="speedIndex"
                type="number"
                name="speedIndex"
                value={speedIndex}
                onChange={onChange}
                placeholder="Indice de velocidad"
              />
            </Column25>
            <Column25>
              <Label htmlFor="treadDepth">Profundidad de carga</Label>
              <TextInput
                id="treadDepth"
                type="text"
                name="treadDepth"
                value={treadDepth}
                onChange={onChange}
                placeholder="Profundidad de carga"
              />
            </Column25>
            <Column25>
              <Label htmlFor="treadwear">Treadwear</Label>
              <TextInput
                id="treadwear"
                type="number"
                name="treadwear"
                value={treadwear}
                onChange={onChange}
                placeholder="Treadwear"
              />
            </Column25>
            <Column25>
              <Label htmlFor="loadIndex">Indice de carga</Label>
              <TextInput
                id="loadIndex"
                type="text"
                name="loadIndex"
                value={loadIndex}
                onChange={onChange}
                placeholder="Indice de carga"
              />
            </Column25>
          </LayoutColumns>
          <hr />
          <h5>Beneficios</h5>
          <BenefitsList>
            {benefits.map((benefit, idx) => (
              <li key={idx}>{benefit}</li>
            ))}
          </BenefitsList>
          {isBenefitsActive ? (
            <AddBenefit>
              <TextInput
                required
                type="text"
                value={benefitValue}
                onChange={value => setBenefitValue(value)}
                bordered
                placeholder="Agregar beneficio"
              />
              <Button
                small
                onClick={addBenefit}
                type="button"
                disabled={benefitValue === ""}
              >
                Agregar
              </Button>
            </AddBenefit>
          ) : (
            <Btn onClick={handleBenefits} type="button">
              <Icon>+</Icon> Agregar beneficio
            </Btn>
          )}
          <SubmitContainer>
            <Button
              type="submit"
              disabled={
                width === "" ||
                profile === "" ||
                wheel === "" ||
                brand === "" ||
                design === "" ||
                reference === "" ||
                price === "" ||
                category === ""
              }
            >
              {isNewTire ? "Guardar y publicar" : "Actualizar"}
            </Button>
          </SubmitContainer>
        </Content>
      </Wrapper>
    </Layout>
  );
}
