import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @componets
import Accordion from "../components/accordion";

const Wrapper = styled.article`
  width: 100%;
  margin-bottom: 3rem;

  ${MEDIA_QUERIES.landscape} {
    width: calc((100% / 2) - 3rem);
    margin: 0 1.5rem 3rem;
  }
`;

const Title = styled.h4`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.faqsCategorySection.primaryColor};
`;

function FaqsCategorySection({ title, data }) {
  return (
    <Wrapper>
      <Title>{title}</Title>

      {data.map(item => (
        <Accordion key={item.question} heading={item.question}>
          <div dangerouslySetInnerHTML={{__html: item.answer}} />
        </Accordion>
      ))}
    </Wrapper>
  );
}

export default FaqsCategorySection;
