import React from "react";

// @components
import FilterModalStep from "../filter-modal-step";
import WidthOptions from "./width-options";
import ProfileOptions from "./profile-options";
import WheelOptions from "./wheel-options";

// @styled-components
import Button from "../../../button";
import {
  StyledFilterModalStepsContainer,
  StyledFilterModalOptions,
  StyledModalSearchContainer
} from "../../filter-styles-components";

// @styles
import { MAIN_FILTER_PLACEHOLDERS } from "../../../../constants";

// @hooks
import { useStore } from "../../../../hooks-store/store";
import { MAIN_FILTER_TYPES } from "../../../../hooks-store/main-filter-store";

const renderSizeOptions = (sizeState) => {
  if (!sizeState.width) {
    return <WidthOptions />
  }

  if (sizeState.profile === null || sizeState.profile === undefined) {
    return <ProfileOptions stateWidth={sizeState.width} />
  }

  if (!sizeState.wheel) {
    return <WheelOptions stateWidth={sizeState.width} stateProfile={sizeState.profile} />
  }
}

const renderSearchButton = (sizeState, onSearch) => {
  const { width, profile, wheel } = sizeState;
  return width && (profile || profile === 0) && wheel
    ? <Button onClick={onSearch}>Ver llantas</Button>
    : null
}

const SizeFilter = ({ filterState, onSearch }) => {
  const dispatch = useStore(false)[1];
  return (
    <>
      <StyledFilterModalStepsContainer>
        <FilterModalStep
          active
          number={1}
          value={filterState.width}
          onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteSizeField, "width")}
        >
          {MAIN_FILTER_PLACEHOLDERS.width.title}
        </FilterModalStep>
        <FilterModalStep
          active={!!filterState.width}
          number={2}
          value={filterState.profile}
          onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteSizeField, "profile")}
        >
          {MAIN_FILTER_PLACEHOLDERS.profile.title}
        </FilterModalStep>
        <FilterModalStep
          active={!!filterState.profile || filterState.profile === 0}
          number={3}
          value={filterState.wheel}
          onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteSizeField, "wheel")}
        >
          {MAIN_FILTER_PLACEHOLDERS.wheel.title}
        </FilterModalStep>
      </StyledFilterModalStepsContainer>
      <StyledFilterModalOptions>
        {renderSizeOptions(filterState)}
      </StyledFilterModalOptions>
      <StyledModalSearchContainer>
        {renderSearchButton(filterState, onSearch)}
      </StyledModalSearchContainer>
    </>
  );
}

export default SizeFilter;
