// @vendors
import React from 'react';

// @components
import FilterStep from "./filter-step";

// @styled-components
import { ClearAllButton } from "../filter-styles-components";

// @constants
import { MAIN_FILTER_PLACEHOLDERS, DEFAULT_VEHICLE_STATE } from "../../../constants";

// @hooks
import { useStore } from "../../../hooks-store/store";
import { MAIN_FILTER_TYPES } from "../../../hooks-store/main-filter-store";


const VehicleFilterForm = ({ categoryState, handleModal }) => {
  const dispatch = useStore(false)[1];
  const displayClearAll = categoryState.brand || categoryState.reference || categoryState.model;
  return (
    <>
      <FilterStep
        number={1}
        isFilled={!!categoryState.brand}
        value={categoryState.brand || MAIN_FILTER_PLACEHOLDERS.brand.value}
        active={!categoryState.brand}
        onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteVehicleField, "brand")}
        onStepClick={() => handleModal("open")}
      >
        {MAIN_FILTER_PLACEHOLDERS.brand.title}
      </FilterStep>
      <FilterStep
        number={2}
        isFilled={!!categoryState.reference}
        value={categoryState.reference || MAIN_FILTER_PLACEHOLDERS.reference.value}
        active={categoryState.brand && !categoryState.reference}
        onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteVehicleField, "reference")}
        onStepClick={() => handleModal("open")}
      >
        {MAIN_FILTER_PLACEHOLDERS.reference.title}
      </FilterStep>
      <FilterStep
        number={3}
        isFilled={!!categoryState.model}
        value={categoryState.model || MAIN_FILTER_PLACEHOLDERS.model.value}
        active={categoryState.brand && categoryState.reference &&!categoryState.model}
        onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteVehicleField, "model")}
        onStepClick={() => handleModal("open")}
      >
        {MAIN_FILTER_PLACEHOLDERS.model.title}
      </FilterStep>
      {
        displayClearAll && (
          <ClearAllButton
          onClick={() => { dispatch(MAIN_FILTER_TYPES.updateVehicle, DEFAULT_VEHICLE_STATE) }}
          small
          >
            Limpiar todo
          </ClearAllButton>
        )
      }
    </>
  );
}

export default VehicleFilterForm;