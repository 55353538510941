// @vendors
import React from "react";

// @components
import Layout from "../containers/Layout";
import HeroContact from "../containers/hero-contact";
import ContactContentSection from "../containers/contact-content-section";

export default function Contact() {
  return (
    <Layout
      title="Contáctanos para brindarte asesoría en tus llantas"
      metaDescription="Contáctanos por nuestro sitio web o comunicate con nosotros al 3136198880"
      navbarTransparent
    >
      <HeroContact />
      <ContactContentSection />
    </Layout>
  );
}
