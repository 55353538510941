// @vendors
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @requests
import { GET_MODAL_PROMOTIONS } from "../../requests/queries";
import { UPDATE_MODAL_PROMOTION, DELETE_IMAGE } from "../../requests/mutations";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import Layout from "../../containers/dashboard/Layout";
import DashboardImage from "../../components/dashboard/image";
import Button from "../../components/button";
import TextInput from "../../components/text-input";
import TextBoxInput from "../../components/textbox-input";
import Loading from "../../components/Loading";
import Updated from "../../components/dashboard/updated";

// @assets
import OfferIcon from "../../assets/icons/offer";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1260px;
  margin: 3rem auto;

  ${MEDIA_QUERIES.tablet} {
    flex-direction: row;
  }

  input,
  textarea {
    margin-bottom: 1.5rem;
  }
`;

const Images = styled.div`
  ${MEDIA_QUERIES.tablet} {
    width: 30%;
  }
`;

const Content = styled.div`
  ${MEDIA_QUERIES.tablet} {
    width: 70%;
  }
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  font-weight: bold;
`;

const SubmitContainer = styled.div`
  margin-top: 4rem;
`;

export default function DashboardModalPromotion() {
  const ENV = process.env.REACT_APP_ENV || "dev";
  const [isSaved, setIsSaved] = useState(false);

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [description, setDescription] = useState("");
  const [btnText, setBtnText] = useState("");
  const [btnUrl, setBtnUrl] = useState("");

  const { loading, error, refetch } = useQuery(GET_MODAL_PROMOTIONS, {
    onCompleted(data) {
      const { modal_promotions } = data;
      const promotion = modal_promotions && modal_promotions[0];

      setId(promotion.id);
      setTitle(promotion.title);
      setImage(promotion.image);
      setImagePath(promotion.image_path);
      setDescription(promotion.description);
      setBtnText(promotion.btn_text);
      setBtnUrl(promotion.btn_url);
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const [updateModalPromotion] = useMutation(UPDATE_MODAL_PROMOTION, {
    update(cache, { data }) {
      const { modal_promotions } = cache.readQuery({
        query: GET_MODAL_PROMOTIONS,
      });

      cache.writeQuery({
        query: GET_MODAL_PROMOTIONS,
        data: { modal_promotions },
      });
    },
  });
  const [deleteImage] = useMutation(DELETE_IMAGE);

  function onChange(event, catN, type, pathImg) {
    if (type === "title") {
      setTitle(event);
    }

    if (type === "description") {
      setDescription(event);
    }

    if (type === "btn_text") {
      setBtnText(event);
    }

    if (type === "btn_url") {
      setBtnUrl(event);
    }

    if (type === "image") {
      setImage(event);
      setImagePath(pathImg);
    }
  }

  function onDelete(event) {
    deleteImage({ variables: { path_img: event } });

    setImage("");
    setImagePath("");
  }

  function handlePromotion(e) {
    e.preventDefault();

    updateModalPromotion({
      variables: {
        id,
        data: {
          title,
          description,
          image,
          image_path: imagePath,
          btn_text: btnText,
          btn_url: btnUrl,
        },
      },
    });

    setId("");
    setTitle("");
    setImage("");
    setImagePath("");
    setDescription("");
    setBtnText("");
    setBtnUrl("");
    setIsSaved(true);
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;
  if (isSaved) return <Updated />;

  return (
    <Layout
      title="Administrador - Promoción modal"
      Icon={OfferIcon}
      heading="Promoción modal"
    >
      <Wrapper onSubmit={handlePromotion}>
        <Images>
          <DashboardImage
            image={image}
            pathImg={imagePath}
            sizes="720px x 1280px"
            folder={ENV === "dev" ? `dev/modal_promotion` : "modal_promotion"}
            onChange={onChange}
            onDelete={onDelete}
            position="image"
          />
        </Images>
        <Content>
          <Label htmlFor="title">Título</Label>
          <TextInput
            bordered
            id="title"
            name="title"
            value={title}
            placeholder="Escriba el título de la promoción"
            onChange={onChange}
          />
          <Label htmlFor="description">Descripción</Label>
          <TextBoxInput
            bordered
            id="description"
            name="description"
            value={description}
            placeholder="Descripción de la promoción"
            onChange={onChange}
          />
          <Label htmlFor="title">Texto botón</Label>
          <TextInput
            bordered
            id="btn_text"
            name="btn_text"
            value={btnText}
            placeholder="Escriba el texto del botón"
            onChange={onChange}
          />
          <Label htmlFor="title">URL botón</Label>
          <TextInput
            bordered
            id="btn_url"
            name="btn_url"
            value={btnUrl}
            placeholder="Escriba la URL del botón"
            onChange={onChange}
          />
          <SubmitContainer>
            <Button>Actualizar</Button>
          </SubmitContainer>
        </Content>
      </Wrapper>
    </Layout>
  );
}
