// @vendors
import React, { Fragment } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

// @styles
import { MEDIA_QUERIES } from "../constants";

const Wrapper = styled.section`
  margin-bottom: -5rem;

  ${MEDIA_QUERIES.landscapeMax} {
    margin-bottom: -1rem;
  }
`;

const Image = styled.div`
  position: relative;
  left: 50%;
  width: 100vw;
  height: 600px;
  margin: 0 auto;
  margin-left: -50vw;
  background-image: url(${({ bgimg }) => bgimg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  ${MEDIA_QUERIES.desktop} {
    width: calc(100vw - 8px);
  }
`;

const Description = styled.div`
  position: relative;
  top: -5rem;
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 3rem 5rem;
  background-color: ${({ theme }) =>
    theme.howDoesServiceWork.descriptionBackgroundColor};
  color: ${({ theme }) => theme.howDoesServiceWork.descriptionFontColor};

  ${MEDIA_QUERIES.tabletMax} {
    text-align: center;
  }

  ${MEDIA_QUERIES.mobileMax} {
    padding: 1rem;
  }

  p {
    font-size: 1rem;
  }
`;

const Title = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;

  span {
    display: inline-block;
    margin-top: 0.5rem;
    width: 15%;
    min-width: 30px;
    border-bottom: 1px solid white;
  }
`;

function HowDoesServiceWork({
  match: {
    params: { slug },
  },
  services,
}) {
  const service = services.find(service => service.slug === slug);

  return (
    <Wrapper>
      <Image bgimg={service.image} />
      <Description>
        <Title>
          Como funciona
          <span />
        </Title>
        {service.how_works.split("\n").map((paragraph, index) => (
          <Fragment key={index}>
            <p>{paragraph}</p>
            <br />
          </Fragment>
        ))}
      </Description>
    </Wrapper>
  );
}

export default withRouter(HowDoesServiceWork);
