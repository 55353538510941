// @vendors
import React, { useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";

// utils
import createPagination from "../utils/createPagination";

// @requests
import { GET_TIRES_OFFERS } from "../requests/queries/tires";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Layout from "../containers/Layout";
import HeroOffers from "../containers/hero-offers";
import ListProducts from "../containers/list-products";
import TireCard from "../components/tire-card";
import Pagination from "../components/pagination";
import Loading from "../components/Loading";

const List = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1280px;
  margin: 2rem auto 5rem;

  ${MEDIA_QUERIES.desktop} {
    justify-content: flex-start;
  }
`;

export default function Offers() {
  const itemsPerPage = 10;
  const [orderPrice, setOrderPrice] = useState("price_DESC");
  const [orderBrand, setOrderBrand] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);

  let paramsToSend;

  if (orderBrand) {
    paramsToSend = {
      numTires: itemsPerPage,
      currentBatch: itemsPerPage * (currentPage - 1),
      priceOrder: orderPrice,
      brand: orderBrand,
    };
  } else {
    paramsToSend = {
      numTires: itemsPerPage,
      currentBatch: itemsPerPage * (currentPage - 1),
      priceOrder: orderPrice,
    };
  }

  const { loading, error, data } = useQuery(GET_TIRES_OFFERS, {
    variables: paramsToSend,
  });

  function handlePagination(e, currentPage) {
    setCurrentPage(currentPage);
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;
  const { tires_offers, total_tires_offers } = data;
  let totalTiresBrand;
  if (orderBrand) {
    totalTiresBrand = total_tires_offers.filter(
      (tire) => tire.brand.brand === orderBrand,
    );
  }

  const { pagesParsed, previousPage, nextPage } = createPagination(
    orderBrand ? totalTiresBrand : total_tires_offers,
    itemsPerPage,
    currentPage,
  );

  function handleChangePrice(item) {
    setOrderPrice(item.value);
    setCurrentPage(1);
  }

  function handleChangeBrand(item) {
    setOrderBrand(item.value);
    setCurrentPage(1);
  }

  return (
    <Layout title="Descuentos">
      <HeroOffers />
      {tires_offers.length > 0 && (
        <ListProducts
          handleChangePrice={handleChangePrice}
          handleChangeBrand={handleChangeBrand}
          totalTires={total_tires_offers}
        />
      )}
      <List>
        {tires_offers.map((tire) => (
          <TireCard key={tire.id} {...tire} isOffer />
        ))}
      </List>

      {total_tires_offers && total_tires_offers.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          handlePagination={handlePagination}
          nextPage={nextPage}
          pagesParsed={pagesParsed}
          previousPage={previousPage}
        />
      )}
    </Layout>
  );
}
