// @vendors
import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @utils
import createPagination from "../../utils/createPagination";

// @requests
import { GET_POSTS_DASHBOARD } from "../../requests/queries/posts";
import { DELETE_POST } from "../../requests/mutations/posts";
import { DELETE_IMAGE } from "../../requests/mutations";

// @components
import Layout from "../../containers/dashboard/Layout";
import DashboardList from "../../containers/dashboard/list";
import Pagination from "../../components/pagination";
import Loading from "../../components/Loading";

// @assets
import BlogIcon from "../../assets/icons/docs";

export default function DashboardBlog({ history, location: { state } }) {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = useState("");

  const { data, loading, error, refetch } = useQuery(GET_POSTS_DASHBOARD, {
    variables: {
      numPosts: itemsPerPage,
      currentBatch: itemsPerPage * (currentPage - 1),
    },
  });
  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: function({ deletePost }) {
      if (deletePost) {
        refetch({
          numPosts: itemsPerPage,
          currentBatch: itemsPerPage * (currentPage - 1),
        });
      }
    },
  });
  const [deleteImage] = useMutation(DELETE_IMAGE);

  useEffect(() => {
    refetch();

    if (state) {
      setNotification(state.notification);

      setTimeout(() => {
        setNotification("");
        history.replace({ pathname: "/administrador/ofertas", state: "" });
      }, 2000);
    }
  }, [history, state, refetch]);

  function onDelete(id, image) {
    deleteImage({ variables: { path_img: image } });
    deletePost({ variables: { id } });
  }

  function handlePagination(e, currentPage) {
    setCurrentPage(currentPage);
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { posts, total_posts } = data;
  const { pagesParsed, previousPage, nextPage } = createPagination(
    total_posts,
    itemsPerPage,
    currentPage,
  );

  return (
    <Layout
      title="Administrador - Ofertas"
      notification={notification}
      Icon={BlogIcon}
      heading="Administrar ofertas"
    >
      <DashboardList
        btnLink="ofertas/nuevo"
        btnText="oferta"
        data={posts}
        onDelete={onDelete}
      />
      {total_posts && total_posts.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          handlePagination={handlePagination}
          nextPage={nextPage}
          pagesParsed={pagesParsed}
          previousPage={previousPage}
        />
      )}
    </Layout>
  );
}
