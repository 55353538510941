// @vendors
import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";

// utils
import createPagination from "../utils/createPagination";

// @requests
import { GET_POSTS } from "../requests/queries/posts";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Layout from "../containers/Layout";
import HeroBlog from "../containers/hero-blog";
import BlogFilter from "../components/blog-filter";
import PostCard from "../components/post-card";
import Pagination from "../components/pagination";
import Newsletter from "../components/newsletter";
import Loading from "../components/Loading";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: 1260px;
  margin: 3rem auto;

  ${MEDIA_QUERIES.landscape} {
    margin-top: 5rem;
  }

  hr {
    display: none;

    ${MEDIA_QUERIES.landscape} {
      display: block;
      width: 100%;
      margin: 2rem auto 4rem;
    }
  }
`;

export default function Blog() {
  const itemsPerPage = 2;
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryFiltered, setCategoryFiltered] = useState("Todos");
  const { loading, error, data } = useQuery(GET_POSTS, {
    variables: {
      numPosts: itemsPerPage,
      currentBatch: itemsPerPage * (currentPage - 1),
      category: categoryFiltered === "Todos" ? "" : categoryFiltered,
    },
  });

  function handleCategory(category) {
    setCategoryFiltered(category);
    setCurrentPage(1);
  }

  function handlePagination(e, currentPage) {
    setCurrentPage(currentPage);
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { posts, total_posts } = data;
  const { pagesParsed, previousPage, nextPage } = createPagination(
    total_posts,
    itemsPerPage,
    currentPage,
  );
  let counter = 1;

  return (
    <Layout title="Ofertas">
      <HeroBlog />
      {total_posts.length > 0 && (
        <BlogFilter active={categoryFiltered} handleCategory={handleCategory} />
      )}

      <Container>
        {posts.map(post => {
          if (counter === 3) {
            counter = 1;
            return (
              <Fragment key={post.id}>
                <PostCard {...post} />

                <hr />
              </Fragment>
            );
          } else {
            counter++;
            return <PostCard {...post} key={post.id} />;
          }
        })}
      </Container>

      {total_posts && total_posts.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          handlePagination={handlePagination}
          nextPage={nextPage}
          pagesParsed={pagesParsed}
          previousPage={previousPage}
        />
      )}
      <Newsletter />
    </Layout>
  );
}
