import React from "react";
import styled, { keyframes } from "styled-components";
import { string, bool } from "prop-types";

// @constants
import { MEDIA_QUERIES } from "../constants";

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${({ width }) => width}px;
  height: 74px;

  ${MEDIA_QUERIES.desktopXL} {
    width: ${({ widthdesktopxl }) => widthdesktopxl}px;
  }
`;

const Container = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  transform: translate(-50%, -50%);

  div {
    animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;

    ::after {
      content: " ";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.main.primaryColor};
      margin: -3px 0 0 -3px;
    }

    :nth-child(1) {
      animation-delay: -0.036s;

      ::after {
        top: 50px;
        left: 50px;
      }
    }

    :nth-child(2) {
      animation-delay: -0.072s;

      ::after {
        top: 54px;
        left: 45px;
      }
    }

    :nth-child(3) {
      animation-delay: -0.108s;

      ::after {
        top: 57px;
        left: 39px;
      }
    }

    :nth-child(4) {
      animation-delay: -0.144s;

      ::after {
        top: 58px;
        left: 32px;
      }
    }

    :nth-child(5) {
      animation-delay: -0.18s;

      ::after {
        top: 57px;
        left: 25px;
      }
    }

    :nth-child(6) {
      animation-delay: -0.216s;

      ::after {
        top: 54px;
        left: 19px;
      }
    }

    :nth-child(7) {
      animation-delay: -0.252s;

      ::after {
        top: 50px;
        left: 14px;
      }
    }

    :nth-child(8) {
      animation-delay: -0.288s;

      ::after {
        top: 45px;
        left: 10px;
      }
    }
  }
`;

const Text = styled.p`
  position: relative;
  top: 78px;
  left: -20px;
  font-size: 1.2rem;
  font-weight: bold;
`;

export default function Loading({ inline, width, widthDesktopXL }) {
  return inline ? (
    <Wrapper width={width} widthdesktopxl={widthDesktopXL}>
      <Container>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <Text>Cargando...</Text>
      </Container>
    </Wrapper>
  ) : (
    <Container>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <Text>Cargando...</Text>
    </Container>
  );
}

Loading.propTypes = {
  inline: bool,
  width: string,
  widthDesktopXL: string,
};

Loading.propTypes = {
  inline: false,
};
