// @vendors
import React from "react";
import { withTheme } from "styled-components";

// @components
import SelectButton from "../../select-button";

// @styled-components
import {
  StyledFilterStep,
  StyledStepNumber,
  StyledStepInfo,
  StyledStepTitle,
  ClearStepButton
} from "../filter-styles-components";


const FilterStep = ({
  active,
  number,
  children,
  value,
  theme,
  onStepClick,
  onStepDelete,
  isFilled
}) => (
  <StyledFilterStep>
    <StyledStepNumber active={active}>{number}</StyledStepNumber>
    <StyledStepInfo>
      <StyledStepTitle active={active}>{children}</StyledStepTitle>
      <SelectButton
        color={
          active
            ? theme.filterStep.textPrimaryColor
            : theme.filterStep.textColorDisabled
        }
        disabled={!active}
        onClick={onStepClick}
      >
        {value}
      </SelectButton>
      {isFilled && (<ClearStepButton onClick={onStepDelete} small>Limpiar</ClearStepButton>)}
    </StyledStepInfo>
  </StyledFilterStep>
);

export default withTheme(FilterStep);
