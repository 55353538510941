// @vendors
import React from 'react';
import { useQuery } from "@apollo/react-hooks";
import capitalize from "lodash.capitalize"

// @constants
import { MAIN_FILTER_TYPES } from "../../../../hooks-store/main-filter-store"

// @components
import { StyledFilterModalOption } from "../../filter-styles-components";

// @store-hooks
import { useStore } from "../../../../hooks-store/store";

// @queries
import { GET_CAR_BRANDS } from "../../../../requests/queries/cars";

const BrandOptions = () => {
  const dispatch = useStore(false)[1];
  const { loading, error, data: { carBrands } = { carBrands: []} } = useQuery(GET_CAR_BRANDS);
  if (loading) {
    return <div>Loading</div>
  }

  if (error) {
    return <div>Ocurrió un error cargando los marcas</div>
  }

  return carBrands.map(carBrand => (
      <StyledFilterModalOption
        key={carBrand.id}
        filterOption
        onClick={() => {
          dispatch(
            MAIN_FILTER_TYPES.updateVehicleField,
            { fieldName: "brand", value: carBrand.name }
          )
        }}
      >
        {capitalize(carBrand.name)}
      </StyledFilterModalOption>
    )
  );
}

export default BrandOptions;
