// @vendors
import React from "react";
import styled from "styled-components";

//@constants
import { MEDIA_QUERIES } from "../../constants";

// @data
import items from "../../data/dashboard-items";

// @components
import DashboardItem from "../../components/dashboard/item";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;

  ${MEDIA_QUERIES.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 5rem;
  }

  ${MEDIA_QUERIES.landscape} {
    justify-content: space-between;
  }
`;

const Title = styled.p`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
`;

export default function DashboardItems() {
  return (
    <Wrapper>
      <Title>Selecciona el item que deseas administrar</Title>
      {items.map(item => (
        <DashboardItem
          key={item.id}
          Icon={item.icon}
          text={item.title}
          link={item.link}
        />
      ))}
    </Wrapper>
  );
}
