// @vendors
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @styled-components
import Button from "../button";
import ThinButton from "../thin-button";

export const StyledFilterWrapper = styled.div`
  display: grid;
  grid-template-columns: 230px repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "car-category select-container select-container select-container"
    "size-category select-container select-container select-container";
  grid-gap: 2px;
  width: 95%;
  max-width: 1260px;
  height: 156px;
  margin: ${({ isNewSearch, isHomeSearch }) =>
    isNewSearch || isHomeSearch ? "2rem auto" : "8rem auto 2rem"};

  ${MEDIA_QUERIES.desktopMax} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 3rem auto;
    grid-template-areas:
      "car-category  size-category"
      "select-container select-container";
    height: auto;
  }
`;

export const StyledCategory = styled.button`
  cursor: pointer;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  background-color: ${({ theme }) => theme.mainFilter.backgroundColor};
  color: ${({ theme, active }) =>
    active ? theme.mainFilter.textColor : theme.mainFilter.textColorDisabled};
  grid-area: ${({ gridArea }) => gridArea};
  box-shadow: 0 2px 5px 0 #00000080;
  transition: background-color 0.35s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1.38;

  :focus {
    outline: none;
  }

  :hover {
    background-color: ${({ theme }) => theme.mainFilter.backgroundHoverColor};
  }

  svg {
    margin-bottom: 5px;
  }

  ${MEDIA_QUERIES.desktopMax} {
    svg {
      display: none;
    }
  }
`;

export const StyledFilterSubmitButton = styled.div`
  margin-bottom: ${({ isNewSearch }) => (isNewSearch ? "15rem" : "0")};
  text-align: center;
`;

export const FilterSelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  grid-area: ${({ gridArea }) => gridArea};
  box-shadow: 0 2px 5px 0 #00000080;
  background-color: ${({ theme }) => theme.mainFilter.selectBackgroundColor};

  ${MEDIA_QUERIES.desktopMax} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const StyledFilterStep = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledStepNumber = styled.div`
  font-size: 24px;
  font-weight: bold;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  color: ${({ theme }) => theme.filterStep.textColor};
  background-color: ${({ theme: { filterStep }, active }) =>
    active ? filterStep.textPrimaryColor : filterStep.textColorDisabled};
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERIES.desktopMax} {
    display: none;
  }
`;

export const StyledStepInfo = styled.div`
  position: relative;
  margin-left: 10px;
  flex-grow: 1;

  ${MEDIA_QUERIES.desktopMax} {
    margin: 5px;
  }
`;

export const ClearStepButton = styled(ThinButton)`
  position: absolute;
  bottom: -25px;
  right: 0;

  ${MEDIA_QUERIES.desktopMax} {
    bottom: unset;
    top: 0;
  }
`;

export const ClearAllButton = styled(ThinButton)`
  position: absolute;
  bottom: -28px;
  right: 0;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  background-color: ${({ theme }) => theme.button.primaryBackground};
  color: ${({ theme }) => theme.button.textColor};
  transition: background-color 0.35s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.button.backgroundHoverColor};
    border-color: transparent;
    color: ${({ theme }) => theme.button.textColor};
  }

  ${MEDIA_QUERIES.desktopMax} {
    bottom: -28px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    background-color: ${({ theme }) => theme.button.primaryBackground};
    color: ${({ theme }) => theme.button.textColor};
  }
`;

export const ModalStepInfo = styled.div`
  position: absolute;
  left: 8px;
  bottom: -25px;

  ${MEDIA_QUERIES.tabletMax} {
    left: 298px;
    bottom: 25px;
  }

  ${MEDIA_QUERIES.mobileMax} {
    left: 180px;
    bottom: 13px;
    font-size: 11px;
  }
`;

export const ClearModalStepButton = styled(ThinButton)`
  margin-right: 0.3rem;
`;

export const SearchModalValue = styled.span`
  color: ${({ theme }) => theme.button.primaryBackground};
`;

export const StyledStepTitle = styled.p`
  font-weight: bold;
  margin: 0 0 5px 0;
  color: ${({ theme: { filterStep }, active }) =>
    active ? filterStep.textPrimaryColor : filterStep.textColorDisabled};
`;

export const StyledModalStepNumber = styled(StyledStepNumber)`
  ${MEDIA_QUERIES.tablet} {
    display: flex;
  }
`;

export const StyledModalStep = styled.div`
  position: relative;
  display: flex;
  min-width: 260px;
  margin: 8px 10px;

  :last-child {
    min-width: 60px;

    p {
      flex-grow: 0;
      border-bottom: none;
    }
  }
`;

export const StyledModalStepTitle = styled(StyledStepTitle)`
  flex-grow: 1;
  border-bottom: 1px solid
    ${({ theme: { modalFilterStep }, active }) =>
      active ? modalFilterStep.activeColor : modalFilterStep.disabledColor};
  margin-left: 10px;
  align-self: flex-start;
  padding-bottom: 5px;
`;

export const StyledFilterModalStepsContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;

  ${MEDIA_QUERIES.tabletMax} {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;

export const StyledFilterModalOptions = styled.div`
  max-height: 300px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow-y: auto;

  ${MEDIA_QUERIES.tabletMax} {
    grid-template-columns: repeat(3, 1fr);
    max-height: 300px;
  }

  ${MEDIA_QUERIES.mobileMax} {
    grid-template-columns: 1fr;
    max-height: 300px;
  }
`;

export const StyledFilterModal = styled.div`
  width: 100%;
  padding: 5px 75px 20px 75px;
  max-height: 500px;
  overflow: hidden;

  ${MEDIA_QUERIES.tabletMax} {
    flex-direction: column;
    padding: 5px 20px 10px 20px;
  }
`;

export const StyledModalCategoryTitle = styled.div`
  color: ${({ theme }) => theme.filterModal.activeTextColor};
  font-weight: bold;
  text-align: center;
`;

export const StyledFilterModalOption = styled(Button)`
  font-size: 15px;
  justify-content: flex-start;
  margin: 3px 15px;
  min-height: 36px;
  text-align: left;
`;

export const StyledModalSearchContainer = styled.div`
  text-align: center;
  padding: 2rem 0;

  ${MEDIA_QUERIES.tabletMax} {
    padding: 2rem 0;
  }
`;
