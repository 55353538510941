// @vendors
import React, { Fragment } from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import TextInput from "../../components/text-input";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERIES.landscape} {
    flex-direction: row;
  }

  div {
    ${MEDIA_QUERIES.landscape} {
      width: calc(100% / 2);
    }
  }
`;

const ActionBtn = styled.button`
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 1.2rem;
  background-color: ${({ theme, close, disabled }) =>
    close
      ? theme.main.dangerColor
      : disabled
      ? theme.main.grayColor
      : theme.main.primaryColor};
  color: ${({ theme }) => theme.main.backgroundColor};
  font-size: 1rem;
  cursor: pointer;
  outline: none;
`;

function AddInput({
  type,
  name,
  value,
  showImage,
  image,
  nameImage,
  onChange,
  onClose,
  onSave,
  placeholder,
  placeholderImage,
}) {
  return (
    <Fragment>
      {showImage ? (
        <Container>
          <TextInput
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            bordered
            required
          />
          <TextInput
            type="text"
            name={nameImage}
            value={image}
            onChange={onChange}
            placeholder={placeholderImage}
            bordered
          />
        </Container>
      ) : (
        <TextInput
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          bordered
          required
        />
      )}
      <ActionBtn
        onClick={onSave}
        type="button"
        disabled={!showImage ? value === "" : value === "" && image === ""}
      >
        Guardar
      </ActionBtn>
      <ActionBtn onClick={onClose} close type="button">
        Cancelar
      </ActionBtn>
    </Fragment>
  );
}

export default AddInput;
