// @vendors
import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import AppointmentForm from "../components/appointment-form";

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: center;
  max-width: ${MEDIA_QUERIES.desktopXL}px;
  margin: 0 auto 4rem;

  ${MEDIA_QUERIES.landscapeMax} {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

const Description = styled.div`
  padding-right: 30%;

  h1 {
    margin-bottom: 1rem;
  }

  ${MEDIA_QUERIES.desktopMax} {
    padding-right: 20%;
  }

  ${MEDIA_QUERIES.landscapeMax} {
    padding-right: 0;
    text-align: center;
    margin-bottom: 3rem;
  }
`;

const Icon = styled.img`
  height: 6rem;
  margin-bottom: 2rem;
`;

function HeroService({
  match: {
    params: { slug },
  },
  services,
  setTitlePage,
  setMetaDescription,
}) {
  const service = services.find(service => service.slug === slug);
  const getFirstPhrase = service.description.split(".")[0];

  setTitlePage(service.name);
  setMetaDescription(getFirstPhrase);

  return (
    <Wrapper>
      <Description>
        <Icon src={service.icon} alt={service.name} />
        <h1>{service.name}</h1>
        <p>{service.description}</p>
      </Description>
      <AppointmentForm
        emailSender={service.author && service.author.email_sender}
      />
    </Wrapper>
  );
}

export default withRouter(HeroService);
