import gql from "graphql-tag";

const CREATE_SERVICE = gql`
  mutation createService($data: CreateServiceInput!) {
    createService(data: $data) {
      id
      name
      description
      how_works
    }
  }
`;

const UPDATE_SERVICE = gql`
  mutation updateService($id: ID!, $data: UpdateServiceInput!) {
    updateService(id: $id, data: $data) {
      id
      name
      description
      how_works
    }
  }
`;

const DELETE_SERVICE = gql`
  mutation deleteService($id: ID!) {
    deleteService(id: $id) {
      id
      slug
      name
    }
  }
`;

export { CREATE_SERVICE, UPDATE_SERVICE, DELETE_SERVICE };
