// @vendors
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// @styles
import { MEDIA_QUERIES } from "../constants";

// @assets
import logo from "../assets/images/logo-servillantas-black.png";
import logoEcoFriendly from "../assets/images/logo-eco-friendly.png";

const Wrapper = styled.div`
  background: ${({ bgGray, theme }) =>
    bgGray ? theme.main.grayColor : "none"};
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1260px;
  margin: 0 auto;
  padding: 0.3rem 0;
  text-align: center;

  img {
    height: 60px;
    margin-bottom: 1rem;

    ${MEDIA_QUERIES.landscape} {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 1rem;
    font-size: 0.9rem;

    ${MEDIA_QUERIES.landscape} {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }
`;

const EcoFriendly = styled(Link)`
  text-decoration: none;

  img {
    height: 50px;
    margin: 0;
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.footer.textColorPrimary};
    font-size: 0.7rem;
  }
`;

export default function CopyrightFooter({ bgGray }) {
  const year = new Date().getFullYear();

  return (
    <Wrapper bgGray={bgGray}>
      <Container>
        <img src={logo} alt="Logo Servillantas" />
        <p>Copyright ©{year} Todos los derechos reservados a Servillantas.</p>
        <EcoFriendly to="/politica-ambiental">
          <img src={logoEcoFriendly} alt="Logo Eco friendly" />
          <p>Comprometidos con el medio ambiente</p>
        </EcoFriendly>
      </Container>
    </Wrapper>
  );
}
