import React from "react";

// @components
import FilterModalStep from "../filter-modal-step";
import BrandOptions from "./brand-options";
import ReferenceOptions from "./reference-options";
import ModelOptions from "./model-options";

// @styled-components
import Button from "../../../button";
import {
  StyledFilterModalStepsContainer,
  StyledFilterModalOptions,
  StyledModalSearchContainer
} from "../../filter-styles-components";

// @styles
import { MAIN_FILTER_PLACEHOLDERS } from "../../../../constants";

// @hooks
import { useStore } from "../../../../hooks-store/store";
import { MAIN_FILTER_TYPES } from "../../../../hooks-store/main-filter-store";

const renderVehicleOptions = (vehicleState) => {
  if (!vehicleState.brand) {
    return <BrandOptions />
  }

  if (!vehicleState.reference) {
    return <ReferenceOptions stateBrand={vehicleState.brand} />
  }

  if (!vehicleState.model) {
    return (
      <ModelOptions
        stateBrand={vehicleState.brand}
        stateReference={vehicleState.reference}
      />
    );
  }
}

const renderSearchButton = (vehicleState, onSearch) => {
  const { brand, reference, model } = vehicleState;
  return brand && reference && model
    ? <Button onClick={onSearch}>Ver llantas</Button>
    : null
}

const VehicleFilter = ({ filterState, onSearch }) => {
  const dispatch = useStore(false)[1];
  return (
    <>
      <StyledFilterModalStepsContainer>
        <FilterModalStep
          active
          number={1}
          value={filterState.brand}
          onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteVehicleField, "brand")}
        >
          {MAIN_FILTER_PLACEHOLDERS.brand.title}
        </FilterModalStep>
        <FilterModalStep
          active={!!filterState.brand}
          number={2}
          value={filterState.reference}
          onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteVehicleField, "reference")}
        >
          {MAIN_FILTER_PLACEHOLDERS.reference.title}
        </FilterModalStep>
        <FilterModalStep
          active={!!filterState.reference}
          number={3}
          value={filterState.model}
          onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteVehicleField, "model")}
        >
          {MAIN_FILTER_PLACEHOLDERS.model.title}
        </FilterModalStep>
      </StyledFilterModalStepsContainer>
      <StyledFilterModalOptions>
        {renderVehicleOptions(filterState)}
      </StyledFilterModalOptions>
      <StyledModalSearchContainer>
        {renderSearchButton(filterState, onSearch)}
      </StyledModalSearchContainer>
    </>
  );
}

export default VehicleFilter;
