// @vendors
import React from 'react';

// @components
import FilterStep from "./filter-step";

// @styled-components
import { ClearAllButton } from "../filter-styles-components";

// @constants
import { MAIN_FILTER_PLACEHOLDERS, DEFAULT_SIZE_STATE } from "../../../constants";

// @hooks
import { useStore } from "../../../hooks-store/store";
import { MAIN_FILTER_TYPES } from "../../../hooks-store/main-filter-store";


const SizeFilterForm = ({ categoryState, handleModal }) => {
  const dispatch = useStore(false)[1];
  const displayClearAll = categoryState.width || (categoryState.profile || categoryState.profile === 0) || categoryState.wheel;
  return (
    <>
      <FilterStep
        number={1}
        isFilled={!!categoryState.width}
        value={ categoryState.width || MAIN_FILTER_PLACEHOLDERS.width.value }
        active={!categoryState.width}
        onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteSizeField, "width")}
        onStepClick={() => handleModal("open")}
      >
        {MAIN_FILTER_PLACEHOLDERS.width.title}
      </FilterStep>
      <FilterStep
        number={2}
        isFilled={!!categoryState.profile || categoryState.profile === 0}
        value={ (categoryState.profile || categoryState.profile === 0) ? categoryState.profile : MAIN_FILTER_PLACEHOLDERS.profile.value }
        active={categoryState.width && (categoryState.profile === null || categoryState.profile === undefined)}
        onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteSizeField, "profile")}
        onStepClick={() => handleModal("open")}
      >
        {MAIN_FILTER_PLACEHOLDERS.profile.title}
      </FilterStep>
      <FilterStep
        number={3}
        isFilled={!!categoryState.wheel}
        value={ categoryState.wheel || MAIN_FILTER_PLACEHOLDERS.wheel.value }
        active={categoryState.width && (categoryState.profile || categoryState.profile === 0) && !categoryState.wheel}
        onStepDelete={() => dispatch(MAIN_FILTER_TYPES.deleteSizeField, "wheel")}
        onStepClick={() => handleModal("open")}
      >
        {MAIN_FILTER_PLACEHOLDERS.wheel.title}
      </FilterStep>
      {
        displayClearAll && (
          <ClearAllButton
          onClick={() => { dispatch(MAIN_FILTER_TYPES.updateSize, DEFAULT_SIZE_STATE) }}
          small
          >
            Limpiar todo
          </ClearAllButton>
        )
      }
    </>
  );
}

export default SizeFilterForm;
