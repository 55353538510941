// @vendors
import React from "react";
import Select from "react-select";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";

// @constants
import { SELECT_PLACEHOLDER } from "../constants";

const customSearchSelectStyles = (
  { backgroundColor, textColor, arrowColor },
  isTernary,
) => ({
  control: (provided, _) => ({
    ...provided,
    justifyContent: "center",
    height: "45px",
    minWidth: "160px",
    backgroundColor: backgroundColor,
    borderColor: "transparent",
    borderRadius: "22px",
    boxShadow: isTernary && "0px 3px 7px #00000066",
    outline: "none",
    border: "none",
  }),
  container: (provided, _) => ({
    ...provided,
    margin: "5px",
  }),
  indicatorSeparator: () => {},
  dropdownIndicator: (provided, _) => ({
    ...provided,
    color: arrowColor,
  }),
  input: () => ({
    color: textColor,
  }),
  placeholder: (provided, _) => ({
    ...provided,
    color: textColor,
    fontSize: "14px",
    marginTop: "18px",
    maxWidth: "160px",
    position: "static",
    padding: 0,
  }),
  singleValue: (provided, _) => ({
    ...provided,
    position: "static",
    color: textColor,
    marginTop: "18px",
    maxWidth: "160px",
    fontSize: "14px",
    padding: 0,
  }),
  valueContainer: (provided, _) => ({
    ...provided,
    padding: "2px 0 2px 8px",
    flex: "0 1 auto",
  }),
});

const SearchSelect = props => {
  const {
    select: { primary, secondary, ternary },
  } = props.theme;
  const isTernary = props.type === "ternary";
  let selectStyles;
  switch (props.type) {
    case "secondary":
      selectStyles = secondary;
      break;
    case "ternary":
      selectStyles = ternary;
      break;
    default:
      selectStyles = primary;
      break;
  }

  return (
    <Select
      {...props}
      styles={customSearchSelectStyles(selectStyles, isTernary)}
    />
  );
};

export default withTheme(SearchSelect);

SearchSelect.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "ternary", ""]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

SearchSelect.defaultProps = {
  type: "",
  options: [],
  placeholder: SELECT_PLACEHOLDER,
  onChange: () => {},
  value: "",
};
