// @vendors
import React from 'react';
import uniqWith from 'lodash.uniqwith';
import isEqual from'lodash.isequal';
import { useQuery } from "@apollo/react-hooks";

// @constants
import { MAIN_FILTER_TYPES } from "../../../../hooks-store/main-filter-store"

// @styled-components
import { StyledFilterModalOption } from "../../filter-styles-components";

// @store-hooks
import { useStore } from "../../../../hooks-store/store";

// @queries
import { GET_WIDTHS_PROFILES_WHEELS } from "../../../../requests/queries/tires";

const WheelOptions = ({ stateWidth, stateProfile }) => {
  const dispatch = useStore(false)[1];
  const {
    loading,
    error,
    data: { get_widths_profiles_wheels } = { get_widths_profiles_wheels: [] }
  } = useQuery(GET_WIDTHS_PROFILES_WHEELS, {
    variables: { width: stateWidth, profile: stateProfile }
  });

  if (loading) {
    return <div>Loading</div>
  }

  if (error) {
    return <div>Ocurrió un error cargando los rines</div>
  }

  return uniqWith(
    get_widths_profiles_wheels.map(({ wheel }) => ({ wheel: wheel.wheel, id: wheel.id })),
    isEqual
  ).map(wheel => (
      <StyledFilterModalOption
        key={wheel.id}
        filterOption
        onClick={() => {
          dispatch(
            MAIN_FILTER_TYPES.updateSizeField,
            { fieldName: "wheel", value: wheel.wheel }
          )
        }}
      >
        {wheel.wheel}
      </StyledFilterModalOption>
    )
  );
}

export default WheelOptions;