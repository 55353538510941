// @vendors
import React, { useState } from "react";
import { withTheme } from "styled-components";

// @components
import Button from "../button";
import FilterModal from "./filter-modal";
import MainFilterForm from "./filter-form/filter-form";

// @styled-components
import {
  StyledFilterWrapper,
  StyledCategory,
  StyledFilterSubmitButton,
} from "./filter-styles-components";

// @constants
import { MAIN_FILTER_CATEGORIES } from "../../constants";
import { MAIN_FILTER_TYPES } from "../../hooks-store/main-filter-store";

// @icons
import CarIcon from "../../assets/icons/car";
import WheelIcon from "../../assets/icons/wheel";

// @utils
import { isFilterFullfilled } from "../../utils/main-filter";

// @hooks
import { useStore } from "../../hooks-store/store";

const MainFilter = ({
  theme,
  isNewSearch,
  isHomeSearch,
  mainFilterStore,
  onSearch,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const dispatch = useStore(false)[1];

  const activeCategory = mainFilterStore.activeCategory;
  const categoryState = mainFilterStore[mainFilterStore.activeCategory];

  const modalProps = {
    activeCategory,
    categoryState,
    onCloseClick: handleModal,
    onSearch: () => { onSearch(); handleModal();},
  };

  function handleModal(status) {
    const navElm = document.getElementById("navigation");

    if (status === "open") {
      navElm.setAttribute("style", "z-index: 1;");
      setIsModalOpened(true);
    } else {
      navElm.setAttribute("style", "z-index: 2;");
      setIsModalOpened(false);
    }
  }

  return (
    <div>
      <StyledFilterWrapper
        isNewSearch={isNewSearch}
        isHomeSearch={isHomeSearch}
      >
        {isModalOpened && <FilterModal {...modalProps} />}
        <StyledCategory
          gridArea="car-category"
          onClick={() =>
            dispatch(
              MAIN_FILTER_TYPES.changeCategory,
              MAIN_FILTER_CATEGORIES.vehicle,
            )
          }
          active={activeCategory === MAIN_FILTER_CATEGORIES.vehicle}
        >
          <CarIcon
            active={activeCategory === MAIN_FILTER_CATEGORIES.vehicle}
            activeColor={theme.mainFilter.textColor}
            disabledColor={theme.mainFilter.textColorDisabled}
          />
          <span>Buscar por vehículo</span>
        </StyledCategory>
        <StyledCategory
          gridArea="size-category"
          onClick={() =>
            dispatch(
              MAIN_FILTER_TYPES.changeCategory,
              MAIN_FILTER_CATEGORIES.size,
            )
          }
          active={activeCategory === MAIN_FILTER_CATEGORIES.size}
        >
          <WheelIcon
            active={activeCategory === MAIN_FILTER_CATEGORIES.size}
            activeColor={theme.mainFilter.textColor}
            disabledColor={theme.mainFilter.textColorDisabled}
          />
          <span>Buscar por tamaño</span>
        </StyledCategory>
        <MainFilterForm
          categoryState={categoryState}
          activeCategory={activeCategory}
          handleModal={handleModal}
        />
      </StyledFilterWrapper>
      <StyledFilterSubmitButton isNewSearch={isNewSearch}>
        <Button
          disabled={!isFilterFullfilled(mainFilterStore)}
          onClick={onSearch}
        >
          Ver llantas
        </Button>
      </StyledFilterSubmitButton>
    </div>
  );
};

export default withTheme(MainFilter);
