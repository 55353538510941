// @vendors
import React from "react";
import styled from "styled-components";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;

  .full-width,
  .react-datepicker__input-container {
    display: block;
    width: 100%;
  }
`;

const StyledDateInput = styled.input`
  box-sizing: border-box;
  width: 180px;
  border: none;
  border-radius: 25px;
  height: 45px;
  font-size: 16px;
  color: ${({ theme }) => theme.main.grayDarkerColor};
  text-align: left;
  padding-left: 25px;
  box-shadow: 0 2px 5px 0 ${({ theme }) => theme.main.boxShadow};
  margin-top: 4px;
  letter-spacing: ${({ timeOnly }) => (timeOnly ? "0" : "2px")};

  :hover {
    cursor: pointer;
  }
  :focus {
    outline: none;
  }
`;

const Label = styled.label`
  padding-left: 25px;
  font-weight: bold;
`;

const properModifiers = {
  offset: {
    enabled: true,
  },
  preventOverflow: {
    enabled: true,
    escapeWithReference: false,
    boundariesElement: "viewport",
  },
};

export default function DateInput({
  timeOnly,
  selected,
  onChange,
  label,
  initSchedule,
  endSchedule,
  initScheduleSaturday,
  endScheduleSaturday,
}) {
  const isSaturday = moment().day() === 6;
  const isSunday = date => {
    const day = moment(date).day();

    return day !== 0;
  };

  return (
    <Container>
      <Label>{label}</Label>
      {timeOnly ? (
        <DatePicker
          customInput={<StyledDateInput timeOnly={timeOnly} />}
          selected={selected}
          onChange={onChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={60}
          dateFormat="h:mm aa"
          placeholderText="00:00 am"
          timeCaption="Time"
          popperModifiers={properModifiers}
          className="full-width"
          minTime={isSaturday ? initScheduleSaturday : initSchedule}
          maxTime={isSaturday ? endScheduleSaturday : endSchedule}
          filterDate={isSunday}
        />
      ) : (
        <DatePicker
          customInput={<StyledDateInput />}
          selected={selected}
          onChange={onChange}
          filterDate={isSunday}
          dateFormat="dd/MM/yy"
          minDate={new Date()}
          placeholderText="DD/MM/AA"
          popperModifiers={properModifiers}
          className="full-width"
        />
      )}
    </Container>
  );
}
