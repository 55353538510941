// @vendors
import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const flatStyles = css`
  ${({ flat, theme }) =>
    flat &&
    `
    background-color: transparent;
    border-color: ${theme.button.textColor};
    transition: border-color 0.35s ease-in-out, color 0.35s ease-in-out;
  `}
`;

const flatHoverStyles = css`
  ${({ flat, theme }) =>
    flat &&
    `
    border-color: ${theme.button.disabledBackground};
    color: ${theme.button.disabledBackground};
  `};
`;

const flatPrimaryStyles = css`
  ${({ primary, flat, theme }) =>
    flat &&
    primary &&
    `
    background-color:  transparent;
    border-color: ${theme.button.primaryBackground};
    color: ${theme.button.primaryBackground};
    transition: border-color 0.35s ease-in-out, color 0.35s ease-in-out;
  `}
`;

const flatPrimaryHoverStyles = css`
  ${({ flat, primary, theme }) =>
    flat &&
    primary &&
    `
    border-color: ${theme.button.backgroundHoverColor};
    color: ${theme.button.backgroundHoverColor};
  `}
`;

const filterOptionStyles = css`
  ${({ filterOption, theme }) =>
    filterOption &&
    `
    border-color: transparent;
    background-color: ${theme.button.filterOptionBackgroundColor};
    color: ${theme.button.filterOptionTextColor};
  `}
`;

const filterOptionHoverStyles = css`
  ${({ filterOption, theme }) =>
    filterOption &&
    `
    background-color: ${theme.button.filterOptionHoverTextColor};
  `}
`;

const disabledStyles = css`
  ${({ disabled, theme }) =>
    disabled &&
    `
    border-color: transparent;
    background-color: ${theme.button.disabledBackground};
    color: ${theme.button.textColor};
  `}
`;

const primaryHoverStyles = css`
  ${({ theme, flat }) =>
    !flat && `background-color: ${theme.button.backgroundHoverColor}`}
`;

const StyledButton = styled.button.attrs(({ disabled }) => ({
  disabled,
}))`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.35s ease-in-out;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  padding: 0 25px;
  border-radius: 28px;
  font-size: ${({ small }) => (small ? "15px" : "1.2rem")};
  font-weight: bold;
  height: ${({ small }) => (small ? "40px" : "55px")};
  background-color: ${({ theme }) => theme.button.primaryBackground};
  color: ${({ theme }) => theme.button.textColor};
  text-decoration: none;
  ${flatStyles};
  ${flatPrimaryStyles};
  ${filterOptionStyles};
  ${disabledStyles};

  :focus {
    outline: none;
  }

  :hover:enabled {
    ${primaryHoverStyles};
    ${flatHoverStyles};
    ${flatPrimaryHoverStyles};
    ${filterOptionHoverStyles};
  }

  svg {
    height: 2rem;
    margin-right: 0.5rem;
    fill: ${({ theme, primary }) =>
      primary ? theme.button.primaryBackground : theme.button.textColor};
  }
`;

export default function Button(props) {
  return <StyledButton {...props}>{props.children}</StyledButton>;
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  small: PropTypes.bool,
  flat: PropTypes.bool,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  filterOption: PropTypes.bool,
};

Button.defaultProps = {
  children: "",
  small: false,
  flat: false,
  disabled: false,
  primary: false,
  filterOption: false,
};
