// @vendors
import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @data
import faqs from "../data/faqs";

// @components
import Layout from "../containers/Layout";
import FaqsCategorySection from "../containers/faqs-category-section";
import Newsletter from "../components/newsletter";

const Header = styled.header`
  margin: 8rem auto 4rem;
  text-align: center;
`;

const Subtitle = styled.h5`
  color: ${({ theme }) => theme.faqs.primaryColor};
`;

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  max-width: 1260px;
  margin: 0 auto 3rem;

  ${MEDIA_QUERIES.landscape} {
    margin-bottom: 6rem;
  }
`;

export default function Faqs() {
  return (
    <Layout title="Preguntas frecuentes">
      <Header>
        <h1>Faqs</h1>
        <Subtitle>Consulta las preguntas frecuentes</Subtitle>
      </Header>
      <Container>
        {faqs.map(faq => (
          <FaqsCategorySection
            key={faq.heading}
            title={faq.heading}
            data={faq.data}
          />
        ))}
      </Container>
      <Newsletter />
    </Layout>
  );
}
