const reasons = [
  {
    value: "Asesoria y venta de llantas",
    label: "Asesoria y venta de llantas",
  },
  {
    value: "Servicios de servillantas la 57",
    label: "Servicios de servillantas la 57",
  },
  { value: "Reencauche", label: "Reencauche" },
  { value: "Dudas o inquietudes", label: "Dudas o inquietudes" },
  { value: "Promociones", label: "Promociones" },
  { value: "Otro motivo", label: "Otro motivo" },
];

export default reasons;
