// @vendors
import React, { Fragment } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";

// @requests
import { GET_SERVICES } from "../requests/queries/services";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Layout from "../containers/Layout";
import ServicesCarousel from "../containers/services-carousel";
// import ReencaucheSection from "../containers/reencauche-section";
import Loading from "../components/Loading";

const Heading = styled.header`
  margin: 8rem 0 2rem;
  text-align: center;

  ${MEDIA_QUERIES.desktop} {
    margin: 8rem 0 4rem;
  }
`;

const Subtitle = styled.h5`
  color: ${({ theme }) => theme.services.primaryColor};
`;

export default function Services() {
  const { loading, error, data } = useQuery(GET_SERVICES);

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { services } = data;

  return (
    <Layout title="Servicios">
      {services.length !== 0 && (
        <Fragment>
          <Heading>
            <h1>Servicios</h1>
            <Subtitle>Estos son nuestros servicios</Subtitle>
          </Heading>
          <ServicesCarousel services={services} />
        </Fragment>
      )}
      {/* <ReencaucheSection /> */}
    </Layout>
  );
}
