// @vendors
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 185px;
  height: 179px;
  margin: 7px;
  padding: 10px;
  background-color: ${({ theme }) => theme.serviceCard.backgroundColor};
  color: ${({ theme }) => theme.serviceCard.textColor};
  box-shadow: 0 2px 4px 0 #00000080;
  text-decoration: none;
`;

const Title = styled.h5`
  height: 44px;
  text-align: center;
`;

const IconContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  height: 5.5rem;
`;

function ServiceCard({ children, link, icon }) {
  return (
    <Wrapper to={`/servicios/${link}`}>
      <IconContainer>
        <Icon src={icon} alt={children} />
      </IconContainer>
      <Title>{children}</Title>
    </Wrapper>
  );
}

export default ServiceCard;
