// @vendors
import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Link } from "react-router-dom";

// @components
import Button from "../components/button";
import BranchCard from "../components/branch-card";

// @assets
import branchInfoImage from "../assets/images/bg-our-branch.jpg";

// @constants
import { MEDIA_QUERIES, BRANCHES_SLIDER_SETTINGS } from "../constants";

// @mocked-data
import branches from "../data/branches";

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  min-height: 434px;

  ${MEDIA_QUERIES.landscapeMax} {
    flex-direction: column;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 35%;
  padding: 1rem 3rem;
  background-image: url(${branchInfoImage});
  background-size: cover;
  color: ${({ theme }) => theme.branchesSection.InfoTitleTextColor};

  ${MEDIA_QUERIES.landscapeMax} {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2rem;
    text-align: center;
  }

  ${MEDIA_QUERIES.desktopXL} {
    padding: 1rem 5rem;
  }

  ::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.branchesSection.imageOverlayColor};
  }

  a {
    text-decoration: none;
  }
`;

const Title = styled.h2`
  display: inline-block;
  border-bottom: ${({ theme }) => theme.branchesSection.titleBorderColor} 1px
    solid;
  padding-bottom: 10px;
  padding-right: 15%;
  margin-bottom: 10px;

  span {
    display: block;
  }

  ${MEDIA_QUERIES.landscapeMax} {
    padding-right: 0;
  }
`;

const Message = styled.p`
  margin-bottom: 35px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.branchesSection.branchesBackground};

  ${MEDIA_QUERIES.landscape} {
    width: 65%;
  }

  .slick-slider {
    width: 90%;
    margin: 3rem auto;

    ${MEDIA_QUERIES.tablet} {
      width: 60%;
      margin: 2rem auto;
    }

    ${MEDIA_QUERIES.desktop} {
      width: 85%;
      margin: 0 auto;
    }
  }

  .slick-slide > div {
    padding: 0 1.2rem;
  }

  .slick-arrow {
    width: 3rem;
    height: 3rem;
  }

  .slick-prev,
  .slick-next {
    ::before {
      font-size: 2.5rem;
      opacity: 1;
    }
  }

  .slick-prev {
    left: -3rem;

    @media screen and (max-width: calc(768px - 1px)) {
      top: -2.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .slick-next {
    right: -3rem;

    @media screen and (max-width: calc(768px - 1px)) {
      top: unset;
      right: 50%;
      bottom: -3rem;
      transform: translateX(50%);
    }
  }
`;

export default function BranchesSection() {
  return (
    <Wrapper>
      <Info>
        <Title>
          <span>Nuestras</span> <span>sedes</span>
        </Title>
        <Message>
          En Servillantas tenemos varias sedes de servicio dispuestas para
          prestarte un gran servicio. Encuentra la sede de servicio más cercana.
        </Message>
        <Link to="/sedes">
          <Button flat>Ver todas las sedes</Button>
        </Link>
      </Info>

      <Container>
        <Slider {...BRANCHES_SLIDER_SETTINGS}>
          {branches.map(branch => (
            <BranchCard key={branch.id} {...branch} />
          ))}
        </Slider>
      </Container>
    </Wrapper>
  );
}
