// @vendors
import React from "react";
import uniqWith from "lodash.uniqwith";
import isEqual from "lodash.isequal";
import capitalize from "lodash.capitalize";
import { useQuery } from "@apollo/react-hooks";

// @constants
import { MAIN_FILTER_TYPES } from "../../../../hooks-store/main-filter-store";

// @components
import { StyledFilterModalOption } from "../../filter-styles-components";

// @store-hooks
import { useStore } from "../../../../hooks-store/store";

// @queries
import { GET_CAR_BRAND_REFERENCES } from "../../../../requests/queries/cars";

const ReferenceOptions = ({ stateBrand }) => {
  const dispatch = useStore(false)[1];
  const {
    loading,
    error,
    data: { car_references } = { car_references: [] },
  } = useQuery(GET_CAR_BRAND_REFERENCES, {
    variables: { carBrand: stateBrand },
  });
  if (loading) {
    return <div>Loading</div>;
  }

  if (error) {
    return <div>Ocurrió un error cargando las líneas</div>;
  }

  return uniqWith(
    car_references.map(({ car_reference }) => ({
      name: car_reference.name,
      id: car_reference.id,
    })),
    isEqual,
  )
    .sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
    .map((reference) => (
      <StyledFilterModalOption
        key={reference.id}
        filterOption
        onClick={() => {
          dispatch(MAIN_FILTER_TYPES.updateVehicleField, {
            fieldName: "reference",
            value: reference.name,
          });
        }}
      >
        {capitalize(reference.name)}
      </StyledFilterModalOption>
    ));
};

export default ReferenceOptions;
