// @vendors
import React from "react";
import styled from "styled-components";
import draftToHtml from "draftjs-to-html";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

// @utils
import formatDateTime from "../utils/formatDateTime";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Layout from "../containers/Layout";
import ShareSocial from "../components/share-social";
import RelatedPosts from "../containers/related-posts";
import Newsletter from "../components/newsletter";
import Button from "../components/button";
import Loading from "../components/Loading";

const Wrapper = styled.article`
  width: 90%;
  max-width: 1260px;
  margin: 8rem auto 4rem;
`;

const Header = styled.header`
  margin-bottom: 2rem;
  text-align: center;
`;

const Title = styled.h1`
  text-transform: none;
`;

const Line = styled.hr`
  width: 30%;
  margin: 1rem auto;
  border-color: ${({ theme }) => theme.singlePost.primaryColor};
`;

const Info = styled.div``;

const CreatedAt = styled.span`
  display: inline-block;
  margin-right: 2rem;
  color: ${({ theme }) => theme.singlePost.secondaryColor};
  font-weight: bolder;
`;

const Category = styled.span`
  color: ${({ theme }) => theme.singlePost.primaryColor};
  font-weight: bolder;
`;

const CoverImg = styled.div`
  margin-bottom: 2rem;
  text-align: center;

  ${MEDIA_QUERIES.tablet} {
    margin-bottom: 4rem;
  }

  img {
    width: 100%;
    max-width: 960px;
    max-height: 385px;
    object-fit: contain;
  }
`;

const Content = styled.div`
  margin-bottom: 4rem;

  p {
    margin-bottom: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.4rem;
  }
`;

const GoBack = styled(Link)`
  display: block;
  text-align: center;
  text-decoration: none;
`;

export default function Post({
  match: {
    params: { slug },
  },
}) {
  const { loading, error, data } = useQuery(
    gql`
      query initialData($slug: String!) {
        post(slug: $slug) {
          title
          slug
          image
          created_at
          body
          categories {
            id
            name
          }
        }

        posts(limit: 3, exclude: $slug) {
          id
          image
          title
          slug
          created_at
        }
      }
    `,
    {
      variables: {
        slug: slug,
      },
    },
  );

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { post, posts } = data;

  const url = window.location.href;
  const categories = post.categories.map(category => category.name);
  const relatedPosts = posts;
  const content = JSON.parse(post.body);

  return (
    <Layout title={post.title}>
      <Wrapper>
        <Header>
          <Title>{post.title}</Title>
          <Line />
          <Info>
            <CreatedAt>{formatDateTime(post.created_at)}</CreatedAt>
            <Category>{categories.join(", ")}</Category>
          </Info>
        </Header>
        <CoverImg>
          <img src={post.image} alt={post.title} />
        </CoverImg>
        <Content dangerouslySetInnerHTML={{ __html: draftToHtml(content) }} />

        <ShareSocial url={url} title={post.title} />
        {relatedPosts.length >= 3 && <RelatedPosts posts={relatedPosts} />}

        <GoBack to="/ofertas">
          <Button flat primary>
            Volver a ofertas
          </Button>
        </GoBack>
      </Wrapper>
      <Newsletter />
    </Layout>
  );
}
