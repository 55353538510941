import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @components
import Layout from "../containers/Layout";

const Wrapper = styled.section`
  width: 90%;
  max-width: 1260px;
  margin: 7rem auto 4rem;

  ${MEDIA_QUERIES.tablet} {
    margin-top: 8rem;
  }

  p,
  ul,
  ol {
    margin-bottom: 1.4rem;
  }

  li {
    margin-bottom: 1rem;
  }
`;

const Title = styled.h1`
  margin-bottom: 2.5rem;
  font-size: 1.6rem;
  text-align: center;

  ${MEDIA_QUERIES.tablet} {
    font-size: 2rem;
  }
`;

export default function Environment() {
  return (
    <Layout title="Política ambiental">
      <Wrapper>
        <Title>Política ambiental</Title>
        <p>
          Las empresas del Grupo Servireencauche se comprometen con el medio
          ambiente generando planes medioambientales que contribuyen al
          desarrollo sostenible, empleado en forma eficiente cada uno de los
          recursos naturales que se emplean en las diferentes actividades que se
          desarrollan dentro de la organización y a una mejora continua en cada
          uno de los procesos.
        </p>

        <p>
          En cumplimiento a la normativa ambiental vigente y teniendo compromiso
          con los recursos naturales, se adelantan en las empresas los
          siguientes planes y estrategias de gestión:
        </p>

        <ul>
          <li>
            Gestión de un manejo adecuado de los residuos mediante los
            respectivos aforos y separación estratégica en la fuente.
            Verificación periódica de la disposición final.
          </li>
          <li>
            Separación y almacenamiento adecuado de los Residuos Peligrosos.
            Verificación periódica de la disposición final.
          </li>
          <li>
            Permanente formación y socialización a los empleados y contratistas
            sobre los aspectos ambientales que su actividad genere.
          </li>
          <li>
            Separación, almacenamiento y rotulación adecuada de los productos
            químicos con base en el Sistema Globalmente Armonizado.
          </li>
        </ul>

        <p>
          Siguiendo la política ambiental y con el fin de dar un paso adelante
          en el adecuado manejo de los recursos medio ambientales, la empresa
          adelanta planes que permitan desarrollar responsabilidad social
          empresarial dentro de la misma.
        </p>

        <ul>
          <li>Planes de Manejo de Residuos Sólidos.</li>
          <li>Planes de Manejo de Sustancias Químicas</li>
          <li>Correcta disposición de residuos especiales.</li>
          <li>Correcto reciclaje y aprovechamiento de residuos.</li>
          <li>
            Capacitamos a nuestro personal con el fin de generar consciencia
            ambiental.
          </li>
          <li>
            Manejo y control de prácticas operativas y mejoras en las mismas con
            el fin de optimizar los recursos.
          </li>
          <li>
            Campañas ambientales mes a mes para generar consciencia ambiental
            dentro y fuera de la organización.
          </li>
        </ul>
      </Wrapper>
    </Layout>
  );
}
