// @vendors
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// @styles
import { MEDIA_QUERIES } from "../constants";

// @assets
import CheckIcon from "../assets/icons/check";

const BenefitsWrapper = styled.section`
  padding: 1.5rem 2rem;
  background-color: ${({ theme }) => theme.benefits.backgroundColor};

  ${MEDIA_QUERIES.tablet} {
    padding: 2.5rem 5rem;
  }

  ${MEDIA_QUERIES.desktopXL} {
    padding: 3rem 20rem;
  }
`;

const BenefitsTitle = styled.h3`
  margin-bottom: 1.5rem;
`;

const BenefitsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Benefit = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  ${MEDIA_QUERIES.tablet} {
    width: 50%;
  }

  ${MEDIA_QUERIES.desktopXL} {
    margin-bottom: 1.5rem;
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1rem;
    fill: ${({ theme }) => theme.benefits.checkColor};

    ${MEDIA_QUERIES.desktopXL} {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  p {
    width: 95%;
    padding-right: 1rem;

    ${MEDIA_QUERIES.desktopXL} {
      padding-right: 2rem;
    }
  }
`;

function Benefits({ benefits }) {
  return (
    <BenefitsWrapper>
      <BenefitsTitle>Beneficios de esta llanta</BenefitsTitle>
      <BenefitsContainer>
        {benefits.map(benefit => (
          <Benefit key={benefit}>
            <CheckIcon />
            <p>{benefit}</p>
          </Benefit>
        ))}
      </BenefitsContainer>
    </BenefitsWrapper>
  );
}

export default Benefits;

Benefits.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.string),
};

Benefits.defaultProps = {
  benefits: [],
};
