import React from "react";
import { Redirect } from "react-router-dom";
import { string } from "prop-types";

export default function Updated({ to, message }) {
  return (
    <Redirect
      to={{
        pathname: to,
        state: { notification: message },
      }}
    />
  );
}

Updated.propTypes = {
  to: string,
  message: string,
};

Updated.defaultProps = {
  to: "/administrador",
  message: "Se ha actualizado correctamente",
};
