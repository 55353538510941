// @vendors
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// @icons
import WhatsappIcon from "../assets/icons/whatsapp";

const Wrapper = styled.a`
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  svg {
    position: relative;
    z-index: 1;
    width: ${({ small }) => (small ? "2.5rem" : "3.5rem")};
    height: ${({ small }) => (small ? "2.5rem" : "3.5rem")};
  }
`;

const Content = styled.span`
  position: relative;
  left: -1rem;
  padding: 0.3rem 0.8rem 0.3rem 1.5rem;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.main.primaryColor};
  color: ${({ theme }) => theme.main.backgroundColor};
  font-size: 0.9rem;
  font-weight: bold;
`;

export default function WhatsappButton({ children, isSmall }) {
  return (
    <Wrapper
      href="https://api.whatsapp.com/send?phone=+573136198880&text=Hola, quiero una asesoría acerca de mis llantas"
      small={isSmall}
      target="_blank"
    >
      <WhatsappIcon />
      {children && <Content small={isSmall}>{children}</Content>}
    </Wrapper>
  );
}

WhatsappButton.propTypes = {
  children: PropTypes.string,
};

WhatsappButton.defaultProps = {
  children: "",
};
