import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

// @constants
import { MEDIA_QUERIES } from "../constants";

const settings = {
  dots: false,
  slidesToShow: 5,
  infinite: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 1,
        infinite: true,
      },
    },
  ],
};

const Wrapper = styled.nav`
  position: relative;
  width: 90%;
  max-width: 1260px;
  margin: 0 auto 2rem;
  padding: 1.2rem 0 0.8rem;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.main.boxShadow};

  .slick-slider {
    width: 75%;
    margin: 0 auto;

    ${MEDIA_QUERIES.tablet} {
      width: 80%;
    }
  }

  .slick-prev,
  .slick-next {
    top: 45%;

    ${MEDIA_QUERIES.tablet} {
      top: 30%;
    }

    ::before {
      color: ${({ theme }) => theme.blogFilter.activeColor};
      font-size: 1.4rem;
      opacity: 1;

      ${MEDIA_QUERIES.tablet} {
        font-size: 1.8rem;
      }
    }
  }
`;

const Item = styled.strong`
  width: auto !important;
  padding: 0 0.5rem 0.2rem;
  border-bottom: ${({ theme, isActive }) =>
    isActive ? `2px solid ${theme.blogFilter.activeColor}` : "0"};
  color: ${({ theme, isActive }) =>
    isActive ? theme.blogFilter.activeColor : theme.blogFilter.primaryColor};
  font-weight: bolder;
  text-transform: uppercase;
  outline: none;
`;

export default function BlogFilter({ active, handleCategory }) {
  const [categories, setCategories] = useState([]);

  const { loading, error } = useQuery(
    gql`
      query getPosts {
        total_posts {
          id
          categories {
            id
            name
          }
        }
      }
    `,
    {
      onCompleted: function({ total_posts }) {
        setCategories(function() {
          const listCategories = [];
          const defaultCategory = { id: "kfdgkn4i3r32", name: "Todos" };

          total_posts.forEach(post => {
            post.categories.forEach(category => {
              if (!listCategories.some(cat => cat.name === category.name)) {
                listCategories.push(category);
              }
            });
          });

          return [defaultCategory, ...listCategories];
        });
      },
    },
  );

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  return (
    <Wrapper>
      <Slider {...settings}>
        {categories.map(category => (
          <Item
            isActive={category.name === active}
            key={category.id}
            onClick={() => handleCategory(category.name)}
          >
            {category.name}
          </Item>
        ))}
      </Slider>
    </Wrapper>
  );
}
