import React from "react";
import styled, { withTheme } from "styled-components";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @assets
import FacebookIcon from "../assets/icons/facebook";
import WhatsappAltIcon from "../assets/icons/whatsapp-alt";
import TwitterIcon from "../assets/icons/twitter";
// import LinkedinIcon from "../assets/icons/linkedin";

const Wrapper = styled.aside`
  max-width: 400px;
  margin: 0 auto 5rem;
  text-align: center;
`;

const Title = styled.h5`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.shareSocial.primaryColor};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  border-radius: 70px;
  box-shadow: 0 2px 5px 0 ${({ theme }) => theme.main.boxShadow};

  ${MEDIA_QUERIES.tablet} {
    padding: 1rem 3rem;
  }
`;

const Item = styled.span`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem;
  padding: 0.6rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  cursor: pointer;

  ${MEDIA_QUERIES.tablet} {
    width: 4rem;
    height: 4rem;
    padding: 1rem;
  }

  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: ${({ theme }) => theme.shareSocial.secondaryColor};

    ${MEDIA_QUERIES.tablet} {
      width: 2rem;
      height: 2rem;
    }
  }
`;

function ShareSocial({ theme, url, title }) {
  return (
    <Wrapper>
      <Title>Comparte este articulo</Title>
      <Container>
        <Item color={theme.shareSocial.facebookColor}>
          <FacebookShareButton url={url}>
            <FacebookIcon />
          </FacebookShareButton>
        </Item>
        <Item color={theme.shareSocial.whatsappColor}>
          <WhatsappShareButton url={url}>
            <WhatsappAltIcon />
          </WhatsappShareButton>
        </Item>
        <Item color={theme.shareSocial.twitterColor}>
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon />
          </TwitterShareButton>
        </Item>
        {/* <Item color={theme.shareSocial.linkedinColor}>
          <LinkedinShareButton url={url}>
            <LinkedinIcon />
          </LinkedinShareButton>
        </Item> */}
      </Container>
    </Wrapper>
  );
}

export default withTheme(ShareSocial);
