import gql from "graphql-tag";

const GET_CARS = gql`
  query cars($numCars: Int, $currentBatch: Int, $query: String) {
    cars(numCars: $numCars, currentBatch: $currentBatch, query: $query) {
      id
      slug
      car_brand {
        id
        name
      }
      car_reference {
        id
        name
      }
      car_model {
        id
        year
      }
    }

    total_cars(query: $query) {
      id
    }
  }
`;

const GET_CAR = gql`
  query car($slug: String!) {
    car(slug: $slug) {
      id
      slug
      car_brand {
        id
        name
      }
      car_reference {
        id
        name
      }
      car_model {
        id
        year
      }
      sizes {
        id
        width {
          id
          width
        }
        profile {
          id
          profile
        }
        wheel {
          id
          wheel
        }
      }
    }
  }
`;

const GET_CAR_BRANDS = gql`
  query carBrands {
    carBrands {
      id
      name
    }
  }
`;

const GET_CAR_REFERENCES = gql`
  query carReferences($carBrand: ID) {
    carReferences(carBrand: $carBrand) {
      id
      name
    }
  }
`;

const GET_CAR_BRAND_REFERENCES = gql`
query car_references($carBrand: String!) {
  car_references( carBrand: $carBrand) {
    car_reference {
      name
      id
    }
  }
}
`;

const GET_CAR_REFERENCE_MODELS = gql`
  query car_models($carBrand: String!, $carReference: String!) {
    car_models( carBrand: $carBrand, carReference: $carReference) {
      car_model {
        year
        id
      }
    }
  }
`;

const GET_CAR_MODELS = gql`
  query carModels {
    carModels {
      id
      year
    }
  }
`;

export {
  GET_CARS,
  GET_CAR,
  GET_CAR_BRANDS,
  GET_CAR_REFERENCES,
  GET_CAR_MODELS,
  GET_CAR_REFERENCE_MODELS,
  GET_CAR_BRAND_REFERENCES
};
