const MEDIA_QUERIES = {
  mobile: "@media screen and (min-width: 480px)",
  mobileMax: "@media screen and (max-width: 480px)",
  tablet: "@media screen and (min-width: 768px)",
  tabletMax: "@media screen and (max-width: 768px)",
  landscape: "@media screen and (min-width: 992px)",
  landscapeMax: "@media screen and (max-width: 992px)",
  desktop: "@media screen and (min-width: 1180px)",
  desktopMax: "@media screen and (max-width: 1180px)",
  desktopXL: "@media screen and (min-width: 1600px)",
};

const MAIN_FILTER_CATEGORIES = {
  size: "size",
  vehicle: "vehicle",
};

const MAIN_FILTER_PLACEHOLDERS = {
  brand: { value: "Selecciona una marca", title: "MARCA DE VEHÍCULO" },
  model: { value: "Selecciona un modelo", title: "MODELO (AÑO)" },
  reference: { value: "Selecciona una línea", title: "LÍNEA" },
  width: { value: "Selecciona un ancho", title: "ANCHO" },
  profile: { value: "Selecciona un perfil", title: "PERFIL" },
  wheel: { value: "Selecciona un rin", title: "RIN" },
};

const SELECT_PLACEHOLDER = "Select...";

const BRANCHES_SLIDER_SETTINGS = {
  dots: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export const DEFAULT_SIZE_STATE = {
  width: null,
  profile: null,
  wheel: ""
}

export const DEFAULT_VEHICLE_STATE = {
  brand: "",
  reference: "",
  model: null
}

export {
  MEDIA_QUERIES,
  BRANCHES_SLIDER_SETTINGS,
  MAIN_FILTER_CATEGORIES,
  SELECT_PLACEHOLDER,
  MAIN_FILTER_PLACEHOLDERS,
};
