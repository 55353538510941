// @vendors
import React from "react";

export default function GearIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <path
        fillRule="evenodd"
        d="M21 28c-3.835 0-6.942-3.133-6.942-7s3.107-7 6.942-7 6.943 3.133 6.943 7-3.108 7-6.943 7zm19.612-2.05l-3.253-1.892c.183-.994.303-2.01.303-3.058 0-1.047-.12-2.064-.303-3.058l3.253-1.892a2.812 2.812 0 0 0 1.015-3.825l-2.776-4.85a2.764 2.764 0 0 0-3.793-1.025l-3.286 1.911c-1.535-1.316-3.28-2.392-5.218-3.085V2.8c0-1.546-1.243-2.8-2.778-2.8h-5.552c-1.535 0-2.778 1.254-2.778 2.8v2.376c-1.94.693-3.684 1.77-5.218 3.085l-3.286-1.91a2.764 2.764 0 0 0-3.793 1.024l-2.776 4.85a2.813 2.813 0 0 0 1.015 3.825l3.252 1.892A16.92 16.92 0 0 0 4.338 21c0 1.049.12 2.064.302 3.058L1.388 25.95a2.813 2.813 0 0 0-1.015 3.825l2.776 4.85a2.764 2.764 0 0 0 3.793 1.025l3.286-1.911c1.534 1.316 3.279 2.394 5.218 3.085V39.2c0 1.546 1.243 2.8 2.778 2.8h5.552c1.535 0 2.778-1.254 2.778-2.8v-2.376c1.939-.691 3.683-1.77 5.218-3.085l3.286 1.91a2.764 2.764 0 0 0 3.793-1.024l2.776-4.85a2.812 2.812 0 0 0-1.015-3.825zM21 16.8c-2.3 0-4.165 1.882-4.165 4.2 0 2.32 1.865 4.2 4.165 4.2s4.165-1.88 4.165-4.2c0-2.318-1.865-4.2-4.165-4.2z"
      />
    </svg>
  );
}
