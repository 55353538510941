import React from "react";
import styled from "styled-components";

// @components
import Button from "./button";

// @constants
import { MEDIA_QUERIES } from "../constants";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background-position: center center;
  background-size: cover;

  ${MEDIA_QUERIES.landscape} {
    display: inline-block;
    width: ${({ multiple }) => (multiple ? "50%" : "100%")};
    height: 500px;
  }

  ${MEDIA_QUERIES.desktopXL} {
    height: 700px;
  }
`;

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 90%;
  padding: 1rem;
  transform: translateX(-50%);
  background-color: ${({ theme, bgAlt }) =>
    bgAlt
      ? theme.promotion.backgroundColorAlt
      : theme.promotion.backgroundColor};

  ${MEDIA_QUERIES.tablet} {
    padding: 2rem;
    width: 80%;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.promotion.textColor};
`;

const Description = styled.p`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.promotion.textColor};
`;

export default function Promotion({
  image,
  title,
  description,
  url,
  multiple,
  bgAlt,
}) {
  return (
    <Wrapper style={{ backgroundImage: `url(${image})` }} multiple={multiple}>
      {(title !== "" || description !== "" || url !== "") && (
        <Container bgAlt={bgAlt}>
          {title !== "" && <Title>{title}</Title>}
          {description !== "" && <Description>{description}</Description>}
          {url !== "" && (
            <Button flat as="a" href={url}>
              Ver promoción
            </Button>
          )}
        </Container>
      )}
    </Wrapper>
  );
}
