import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// @assets
import bgImage from "../assets/images/bg-environmental.jpg";
import logo from "../assets/images/logo-eco-friendly.png";

// @constants
import { MEDIA_QUERIES } from "../constants";

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 3rem 1rem;
  background: linear-gradient(
      ${({ theme }) => theme.environmental.backgroundGradientTop},
      ${({ theme }) => theme.environmental.backgroundGradientBottom} 80%
    ),
    url(${bgImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  ${MEDIA_QUERIES.landscape} {
    padding: 5rem 8rem;
  }

  ${MEDIA_QUERIES.desktopXL} {
    padding: 8rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERIES.desktopXL} {
    width: 80%;
  }
`;

const Image = styled(Link)`
  display: block;
  max-width: 300px;
  margin-bottom: 1rem;
  text-align: center;

  ${MEDIA_QUERIES.tablet} {
    margin-bottom: 2rem;
  }

  ${MEDIA_QUERIES.desktop} {
    margin-right: 3rem;
    margin-bottom: 0;
  }

  img {
    height: 130px;

    ${MEDIA_QUERIES.tablet} {
      height: 160px;
    }

    ${MEDIA_QUERIES.desktop} {
      height: 200px;
    }

    ${MEDIA_QUERIES.desktopXL} {
      height: 220px;
    }
  }
`;

const Content = styled.div`
  max-width: 600px;
  text-align: center;

  ${MEDIA_QUERIES.desktop} {
    text-align: left;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.environmental.textColorPrimary};
`;

const Subtitle = styled.h4`
  color: ${({ theme }) => theme.environmental.textColor};
`;

const Description = styled.p`
  color: ${({ theme }) => theme.environmental.textColor};
`;

function EnvironmentalSection() {
  return (
    <Wrapper>
      <Container>
        <Image to="/politica-ambiental">
          <img src={logo} alt="Eco friendly" />
        </Image>
        <Content>
          <Subtitle>Comprometidos con el</Subtitle>
          <Title>Medio ambiente</Title>
          <Description>
            El cuidado del planeta es responsabilidad de todos, en Servillantas
            trabajamos comprometidos con el medio ambiente usando el proceso de
            reencauche más amigable con el planeta.
          </Description>
        </Content>
      </Container>
    </Wrapper>
  );
}

export default EnvironmentalSection;
