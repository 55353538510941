import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @assets
import PointerIcon from "../assets/icons/pointer";
import PhoneIcon from "../assets/icons/phone";
import ArrowDown from "../assets/icons/arrow-down";

const Wrapper = styled.article`
  display: inline-block;
  position: relative;
  width: 95%;
  max-width: 380px;
  margin: 0 auto 0.8rem;
  padding: 1rem;
  text-align: left;
  box-shadow: 0 2px 5px 0 ${({ theme }) => theme.main.boxShadow};
  cursor: pointer;

  ${MEDIA_QUERIES.tablet} {
    width: 45%;
    margin-right: 0.8rem;
  }

  ${MEDIA_QUERIES.landscape} {
    width: 100%;
    padding: 1.5rem;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.h4`
  margin-bottom: 0.5rem;
  color: ${({ theme, isActive }) =>
    isActive ? theme.branches.activeColor : theme.branches.primaryColor};
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;

  svg {
    margin-right: 1rem;
    height: 1.5rem;
    fill: ${({ theme }) => theme.branches.activeColor};
  }

  p {
    font-size: 0.9rem;

    ${MEDIA_QUERIES.desktopXL} {
      font-size: 1rem;
    }
  }
`;

const ActiveIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  width: 1.9rem;
  height: 1.9rem;
  padding: 0.2rem;
  border-radius: 50%;
  transform: rotate(0) translateY(-50%);
  background-color: ${({ theme }) => theme.branches.activeColor};

  ${MEDIA_QUERIES.landscape} {
    top: 40%;
    right: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    transform: rotate(-90deg);
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${({ theme }) => theme.branches.ternaryColor};

    ${MEDIA_QUERIES.landscape} {
      width: 2rem;
      height: 2rem;
    }
  }
`;

function BranchPageCard({
  title,
  address,
  city,
  country,
  phone,
  isActive,
  handleActiveBranch,
  lat,
  lng,
}) {
  return (
    <Wrapper onClick={() => handleActiveBranch(lat, lng)}>
      <Title isActive={isActive}>{title}</Title>
      <Info>
        <PointerIcon />
        <p>
          {address} <br />
          {city}, {country}
        </p>
      </Info>
      {phone && (
        <Info>
          <PhoneIcon />
          <p>{phone}</p>
        </Info>
      )}
      {isActive && (
        <ActiveIcon>
          <ArrowDown />
        </ActiveIcon>
      )}
    </Wrapper>
  );
}

export default BranchPageCard;

BranchPageCard.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};
