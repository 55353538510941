// @vendors
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider } from "styled-components";

// @utils
import { getCookie } from "./utils/cookies";

// @styles
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import GlobalStyle from "./assets/styles/main";
import lightTheme from "./assets/styles/lightTheme";

// @store-hooks
import configureMainFilterStore from "./hooks-store/main-filter-store";

// @components
import App from "./App";

// @constants
const setToken = () => {
  const token = getCookie("token")
  return token ? `Bearer ${token}` : "";
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    createUploadLink({
      uri: process.env.REACT_APP_API_SERVER || "http://localhost:4000",
      credentials: "same-origin",
      headers: {
        Authorization: setToken(),
      },
    }),
  ]),
  cache: new InMemoryCache(),
});

configureMainFilterStore();

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={lightTheme}>
      <Fragment>
        <GlobalStyle />
        <App />
      </Fragment>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root"),
);
