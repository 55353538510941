// @vendors
import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @assets
import heroImg from "../assets/images/hero-aboutus.png";

const HeroAboutUsWrapper = styled.header`
  display: flex;
  width: 90%;
  max-width: 1260px;
  margin: 8rem auto 3rem;

  ${MEDIA_QUERIES.landscape} {
    margin-top: 10rem;
  }
`;

const HeroAboutUsContent = styled.div`
  ${MEDIA_QUERIES.tablet} {
    width: 50%;
    margin-right: 2rem;
  }
`;

const HeroAboutUsTitle = styled.h1`
  margin-bottom: 2rem;
  line-height: 1;

  span {
    font-size: 2rem;
  }
`;

const HeroAboutUsImage = styled.div`
  display: none;

  ${MEDIA_QUERIES.tablet} {
    display: block;
    width: 50%;
    text-align: center;
  }

  img {
    max-width: 100%;

    ${MEDIA_QUERIES.desktop} {
      max-width: auto;
      height: 600px;
    }
  }
`;

function HeroAboutUs() {
  return (
    <HeroAboutUsWrapper>
      <HeroAboutUsContent>
        <HeroAboutUsTitle>
          <span>Nuestra</span> <br /> Empresa
        </HeroAboutUsTitle>
        <p>
          Servillantas la 57 es una empresa perteneciente al gurpo comercial SERVIREENCAUCHE DE COLOMBIA SA la cual es una empresa con una trayectoria de 20 años en el mercado, dedicada a la importacion, comercializacion y reencauche de llantas de camion y auto movil y camioneta
        </p>

        <br />

        <p>
          Actualmente contamos con punto de venta en Bogota, Cartagena, Cucuta, Barranquilla y Medellin. Adicionalmente contamos con precesian en diferentes sectores de Anioquia y el resto del pais por medio de nuestros distribuidores.
        </p>

        <br />

        <p>
          Nuestras marcas principales y de las cuales somos importadores directos son Aufine, Firenza y Sierra para la linea de camion liviano y pesado, Royal Black para automovil y camioneta. adicionalmente contamos con la distribucion de marcas como Hankook, Continental, Cooper, Bridgestone, Compasal entre otras marcas, las cuales hacen que tengamos un portafolio de prodecuto muy completo para poder satisfacer las necesidades de todos nuestros clientes.
        </p>
      </HeroAboutUsContent>
      <HeroAboutUsImage>
        <img src={heroImg} alt="" />
      </HeroAboutUsImage>
    </HeroAboutUsWrapper>
  );
}

export default HeroAboutUs;
