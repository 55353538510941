import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 5px;
`;

const InputField = styled.textarea`
  width: 100%;
  min-height: 150px;
  border: 1px solid ${({ theme }) => theme.textBoxInput.borderColor};
  padding: 15px 15px 15px 25px;
  color: ${({ theme }) => theme.textBoxInput.textColor};
  font-size: 1rem;
  line-height: 1.4;
  resize: none;

  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.textBoxInput.focusedBorderColor};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textBoxInput.placeHolderTextColor};
  }
`;

export default function TextBoxInput({
  placeholder,
  required,
  value,
  onChange,
  number,
  name,
}) {
  return (
    <Wrapper>
      <InputField
        required={required}
        placeholder={placeholder}
        name={name}
        onChange={e => onChange(e.target.value, number, e.target.name)}
        value={value}
      />
    </Wrapper>
  );
}
