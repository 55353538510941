import React from "react";
import styled from "styled-components";

// @assets
import FacebookIcon from "../assets/icons/facebook";
import InstagramIcon from "../assets/icons/instagram";

const Wrapper = styled.section`
  margin-bottom: 5rem;
  text-align: center;
`;

const Title = styled.h5`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.socialMediaSection.primaryColor};
`;

const Icons = styled.div``;

const Icon = styled.a`
  display: inline-block;
  width: 4rem;
  height: 4rem;
  margin: 0 0.5rem;
  padding: 0.9rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.socialMediaSection.bgColor};

  svg {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.socialMediaSection.secondaryColor};
  }
`;

export default function SocialMediaSection() {
  return (
    <Wrapper>
      <Title>Nuestras redes sociales</Title>
      <Icons>
        <Icon href="https://www.facebook.com/ServillantasLa57/" target="_blank" rel="noopener">
          <FacebookIcon />
        </Icon>

        <Icon href="https://www.instagram.com/servillantasla57/" target="_blank" rel="noopener">
          <InstagramIcon />
        </Icon>
      </Icons>
    </Wrapper>
  );
}
