import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// @assets
import PseImg from "../assets/images/logo-pse.png";

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const Icon = styled.img`
  position: relative;
  z-index: 1;
  width: ${({ small }) => (small ? "2.5rem" : "3.5rem")};
  height: ${({ small }) => (small ? "2.5rem" : "3.5rem")};
`;

const Content = styled.span`
  position: relative;
  left: -1rem;
  padding: 0.3rem 0.8rem 0.3rem 1.5rem;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.main.pseColor};
  color: ${({ theme }) => theme.main.backgroundColor};
  font-size: 0.9rem;
  font-weight: bold;
  box-shadow: 0 2px 5px 0 ${({ theme }) => theme.main.boxShadow};
`;

function PseButton({ children, isSmall }) {
  return (
    <Wrapper href="http://servireencauche.pagoagil.co/" target="_blank">
      <Icon src={PseImg} small={isSmall} />
      {children && <Content>{children}</Content>}
    </Wrapper>
  );
}

export default PseButton;

PseButton.propTypes = {
  children: PropTypes.string,
};

PseButton.defaultProps = {
  children: "",
};
