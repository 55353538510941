// @vendors
import React from "react";
import styled from "styled-components";
import { string, array, func } from "prop-types";

// @components
import DashboardListItem from "../../components/dashboard/list-item";
import DashboardBtn from "../../components/dashboard/btn";

const Wrapper = styled.section`
  width: 90%;
  max-width: 1260px;
  margin: 3rem auto;
`;

const ContainerBtn = styled.div`
  margin-bottom: 3rem;
  text-align: center;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Title = styled.h5`
  text-transform: none;
`;

const List = styled.ul`
  padding-left: 0;
`;

function getTitle(text) {
  switch (text) {
    case "llanta":
      return "Referencia";
    case "carro":
      return "Carros";
    default:
      return "Título";
  }
}

export default function DashboardList({ btnLink, btnText, data, onDelete }) {
  return (
    <Wrapper>
      <ContainerBtn>
        <DashboardBtn link={`/administrador/${btnLink}`} text={btnText} />
      </ContainerBtn>
      {data.length > 0 && (
        <Header>
          <Title>{getTitle(btnText)}</Title>
          {btnText === "oferta" && <Title>Fecha</Title>}
        </Header>
      )}
      <List>
        {data.map(item => (
          <DashboardListItem
            key={item.id}
            {...item}
            type={btnText}
            onDelete={onDelete}
          />
        ))}
      </List>
    </Wrapper>
  );
}

DashboardList.propTypes = {
  btnLink: string,
  btnText: string,
  data: array,
  onDelete: func,
};

DashboardList.defaultProps = {
  btnLink: "",
  btnText: "",
  data: [],
  onDelete: function() {},
};
