import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// @utils
import { deleteCookie } from "../../utils/cookies";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @assets
import logo from "../../assets/images/logo-servillantas-black.png";
import LogoutIcon from "../../assets/icons/logout.js";

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 1260px;
  margin: 0 auto;
  padding: 0.5rem 0;

  ${MEDIA_QUERIES.tablet} {
    padding: 0;
  }
`;

const Logo = styled(Link)`
  display: inline-block;
  width: 50%;
  max-width: 230px;
  margin-right: 1rem;
  text-decoration: none;

  ${MEDIA_QUERIES.tablet} {
    margin-right: 0;
  }

  img {
    max-width: 100%;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  ${MEDIA_QUERIES.tablet} {
    flex-direction: row;
    text-align: left;
  }

  p {
    font-weight: bold;

    ${MEDIA_QUERIES.tablet} {
      margin-right: 1rem;
    }
  }
`;

const Logout = styled.button`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  padding: 0.3rem 0.5rem;
  border: 0;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.main.dangerColor};
  color: ${({ theme }) => theme.main.backgroundColor};
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;

  ${MEDIA_QUERIES.tablet} {
    margin-top: 0;
    padding: 0.4rem 1rem;
    font-size: 1rem;
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 0.8rem;
    fill: ${({ theme }) => theme.main.backgroundColor};

    ${MEDIA_QUERIES.tablet} {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
`;

export default function DashboardHeader() {
  function handleLogout() {
    deleteCookie("token");
    deleteCookie("author");
    window.location.reload();
  }

  return (
    <Wrapper>
      <Logo to="/administrador">
        <img src={logo} alt="Logo Servillantas" />
      </Logo>
      <Info>
        <p>Bienvenido Servillantas</p>
        <Logout onClick={handleLogout}>
          Salir
          <LogoutIcon />
        </Logout>
      </Info>
    </Wrapper>
  );
}
