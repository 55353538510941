// @vendors
import React from "react";
import { withTheme } from "styled-components";

// @components
import Modal from "../modal";
import SizeFilter from "./filter-modal/size/size-filter";
import VehicleFilter from "./filter-modal/vehicle/vehicle-filter";

// @constants
import { MAIN_FILTER_CATEGORIES } from "../../constants";

// @icons
import CarIcon from "../../assets/icons/car";
import WheelIcon from "../../assets/icons/wheel";

// @styled components
import { StyledFilterModal, StyledModalCategoryTitle } from "./filter-styles-components";

const getFilterInfo = (activeCategory) => {
  let TitleIcon, titleLabel, FilterComponent;

  switch (activeCategory) {
    case MAIN_FILTER_CATEGORIES.vehicle:
      TitleIcon = CarIcon;
      titleLabel = "BUSCAR POR VEHÍCULO";
      FilterComponent = VehicleFilter;
      break;
    case MAIN_FILTER_CATEGORIES.size:
      TitleIcon = WheelIcon;
      titleLabel = "BUSCAR POR TAMAÑO";
      FilterComponent = SizeFilter;
      break;
    default:
      return;
  }
  return { TitleIcon, titleLabel, FilterComponent };
}

const FilterModal = ({
  activeCategory,
  categoryState,
  onCloseClick,
  onSearch,
  theme,
}) => {
  const { TitleIcon, titleLabel, FilterComponent } = getFilterInfo(activeCategory);
  return (
    <Modal onCloseClick={onCloseClick}>
      <StyledFilterModal>
        <StyledModalCategoryTitle>
          <TitleIcon active activeColor={theme.filterModal.activeTextColor} />
          <p>{titleLabel}</p>
        </StyledModalCategoryTitle>
        <FilterComponent filterState={categoryState} onSearch={onSearch} />
      </StyledFilterModal>
    </Modal>
  );
}

export default withTheme(FilterModal);
