// @vendors
import React from "react";
import styled from "styled-components";

// @constants
import { MEDIA_QUERIES } from "../../constants";

// @components
import DashboardImage from "../../components/dashboard/image";
import TextInput from "../../components/text-input";
import TextBoxInput from "../../components/textbox-input";

const Wrapper = styled.article`
  margin-bottom: 2rem;

  ${MEDIA_QUERIES.tablet} {
    width: 45%;
  }
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  font-weight: bold;
`;

export default function DashboardOffersItems({
  id,
  title,
  description,
  image,
  pathImg,
  url,
  heading,
  sizes,
  onChange,
  onDelete,
  position,
}) {
  const ENV = process.env.REACT_APP_ENV || "dev";

  return (
    <Wrapper>
      <DashboardImage
        position={position}
        heading={heading}
        image={image}
        pathImg={pathImg}
        sizes={sizes}
        folder={ENV === "dev" ? `dev/ofertas_destacadas` : "ofertas_destacadas"}
        onChange={onChange}
        onDelete={onDelete}
      />
      <Label htmlFor={`title_${id}`}>Título de la promoción</Label>
      <TextInput
        bordered
        placeholder="Escriba el título"
        id={`title_${id}`}
        value={title}
        onChange={onChange}
        number={position}
        name="title"
      />
      <TextBoxInput
        placeholder="Descripción"
        value={description}
        onChange={onChange}
        number={position}
        name="description"
      />
      <Label htmlFor={`url_${id}`}>Url</Label>
      <TextInput
        bordered
        placeholder="Escriba la url del produto"
        id={`url_${id}`}
        value={url}
        onChange={onChange}
        number={position}
        name="url"
      />
    </Wrapper>
  );
}
