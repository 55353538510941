// @utils
import range from "./rangeNumbers";

function createPagination(totalData, itemsPerPage, currentPage) {
  const numPages = Math.ceil(totalData.length / itemsPerPage);
  const pagesParsed = range(1, numPages);
  const previousPage = currentPage <= 1 ? 1 : currentPage - 1;
  const nextPage = currentPage >= numPages ? numPages : currentPage + 1;

  return {
    pagesParsed,
    previousPage,
    nextPage,
  };
}

export default createPagination;
