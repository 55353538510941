// @vendors
import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

// @components
import Layout from "../containers/Layout";
import Hero from "../containers/hero-home";
import Promotions from "../containers/promotions";
import Categories from "../containers/categories-section";
import SearchSteps from "../containers/search-steps-section";
import Services from "../containers/services-section";
import Branches from "../containers/branches-section";
import Environmental from "../containers/environmental-section";
import Loading from "../components/Loading";

export default function HomePage() {
  const { loading, error, data } = useQuery(gql`
    {
      sliders {
        id
        image
      }

      cover_offers {
        id
        title
        image
        description
        url
      }

      services {
        id
        name
        icon
      }
    }
  `);

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  const { services, cover_offers, sliders } = data;

  return (
    <Layout
      title="Servillantas La 57 Venta y asesoría de llantas para carros"
      metaDescription="En Servillantas La 57 tenemos las mejores llantas para tu vehículo. Contamos con servicios de alineación, balanceo, cambio de aceite, nitrógeno, y más"
      navbarTransparent
    >
      <Hero sliders={sliders} />
      <Promotions promotions={cover_offers} />
      <Categories />
      <SearchSteps />

      {services.length !== 0 && (
        <Services title="También te ofrecemos" services={services} />
      )}
      <Branches />
      <Environmental />
    </Layout>
  );
}
