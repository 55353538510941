// @vendors
import React from "react";
import styled from "styled-components";

// @components
import ThumbnailCategory from "../components/thumbnail-category";

// @constants
import { MEDIA_QUERIES } from "../constants";

// @mocked-data
import categories from "../data/categories";

const Wrapper = styled.div`
  display: flex;

  ${MEDIA_QUERIES.tabletMax} {
    flex-direction: column;
  }
`;

export default function CategoriesSection() {
  return (
    <Wrapper>
      {categories.map(cat => (
        <ThumbnailCategory key={cat.id} {...cat} />
      ))}
    </Wrapper>
  );
}
