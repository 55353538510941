import gql from "graphql-tag";

const CREATE_CAR = gql`
  mutation createCar($data: CreateCarInput!) {
    createCar(data: $data) {
      id
      slug
      car_brand {
        id
        name
      }
      car_reference {
        id
        name
      }
      car_model {
        id
        year
      }
      sizes {
        id
        width {
          id
          width
        }
        profile {
          id
          profile
        }
        wheel {
          id
          wheel
        }
      }
    }
  }
`;

const UPDATE_CAR = gql`
  mutation updateCar(
    $id: ID!
    $data: UpdateCarInput!
    $size_to_remove: SizeRemoveInput!
  ) {
    updateCar(id: $id, data: $data, size_to_remove: $size_to_remove) {
      id
      slug
      car_brand {
        id
        name
      }
      car_reference {
        id
        name
      }
      car_model {
        id
        year
      }
      sizes {
        id
        width {
          id
          width
        }
        profile {
          id
          profile
        }
        wheel {
          id
          wheel
        }
      }
    }
  }
`;

const CREATE_CAR_BRAND = gql`
  mutation createCarBrand($carBrand: String!) {
    createCarBrand(carBrand: $carBrand) {
      id
      name
    }
  }
`;
const CREATE_CAR_REFERENCE = gql`
  mutation createCarReference($carBrand: ID!, $carReference: String!) {
    createCarReference(carBrand: $carBrand, carReference: $carReference) {
      id
      name
    }
  }
`;
const CREATE_CAR_MODEL = gql`
  mutation createCarModel($carModel: Int!) {
    createCarModel(carModel: $carModel) {
      id
      year
    }
  }
`;
const DELETE_CAR = gql`
  mutation deleteCar($id: ID!) {
    deleteCar(id: $id) {
      id
    }
  }
`;

export {
  CREATE_CAR,
  UPDATE_CAR,
  CREATE_CAR_BRAND,
  CREATE_CAR_REFERENCE,
  CREATE_CAR_MODEL,
  DELETE_CAR,
};
