import { initStore } from './store';
import { MAIN_FILTER_CATEGORIES } from "../constants";

// @constants
import { DEFAULT_SIZE_STATE, DEFAULT_VEHICLE_STATE } from '../constants';

const configureStore = () => {
  const actions = {
    UPDATE_SIZE: (curState, sizeState) => {
      return {
        mainFilterStore: {
          ...curState.mainFilterStore,
          [MAIN_FILTER_CATEGORIES.size]: {
            ...sizeState
          }
        }
      }
    },
    UPDATE_SIZE_FIELD: (curState, { fieldName, value }) => {
      return {
        mainFilterStore: {
          ...curState.mainFilterStore,
          [MAIN_FILTER_CATEGORIES.size]: {
            ...curState.mainFilterStore.size,
            [fieldName]: value
          }
        }
      }
    },
    DELETE_SIZE_FIELD: (curState, fieldName) => {
      let newSizeState;
      switch(fieldName) {
        case "width":
          newSizeState = DEFAULT_SIZE_STATE;
          break;
        case "profile":
          newSizeState = {
            ...curState.mainFilterStore[MAIN_FILTER_CATEGORIES.size],
            profile: null,
            wheel: ""
          };
          break;
        case "wheel":
          newSizeState = {
            ...curState.mainFilterStore[MAIN_FILTER_CATEGORIES.size],
            wheel: ""
          };
          break;
        default:
          newSizeState = DEFAULT_SIZE_STATE;
      }
      return {
        mainFilterStore: {
          ...curState.mainFilterStore,
          [MAIN_FILTER_CATEGORIES.size]: newSizeState
        }
      }
    },
    UPDATE_VEHICLE: (curState, vehicle) => {
      return {
        mainFilterStore: {
          ...curState.mainFilterStore,
          [MAIN_FILTER_CATEGORIES.vehicle]: {
            ...vehicle
          }
        }
      }
    },
    UPDATE_VEHICLE_FIELD: (curState, { fieldName, value }) => {
      return {
        mainFilterStore: {
          ...curState.mainFilterStore,
          [MAIN_FILTER_CATEGORIES.vehicle]: {
            ...curState.mainFilterStore.vehicle,
            [fieldName]: value
          }
        }
      }
    },
    DELETE_VEHICLE_FIELD: (curState, fieldName) => {
      let newVehicleState;
      switch(fieldName) {
        case "brand":
          newVehicleState = DEFAULT_VEHICLE_STATE;
          break;
        case "reference":
          newVehicleState = {
            ...curState.mainFilterStore[MAIN_FILTER_CATEGORIES.vehicle],
            reference: null,
            model: ""
          };
          break
        case "model":
          newVehicleState = {
            ...curState.mainFilterStore[MAIN_FILTER_CATEGORIES.vehicle],
            model: ""
          };
          break;
        default:
          newVehicleState = DEFAULT_VEHICLE_STATE;
      }
      return {
        mainFilterStore: {
          ...curState.mainFilterStore,
          [MAIN_FILTER_CATEGORIES.vehicle]: newVehicleState
        }
      }
    },
    CHANGE_CATEGORY: (curState, category) => {
      return {
        mainFilterStore: {
          ...curState.mainFilterStore,
          activeCategory: category
        }
      }
    },
  };

  initStore(actions, {
    mainFilterStore: {
      activeCategory: MAIN_FILTER_CATEGORIES.vehicle,
      [MAIN_FILTER_CATEGORIES.size]: DEFAULT_SIZE_STATE,
      [MAIN_FILTER_CATEGORIES.vehicle]: DEFAULT_VEHICLE_STATE
    }
  });
};

export default configureStore;

export const MAIN_FILTER_TYPES = {
  updateSize: "UPDATE_SIZE",
  updateSizeField: "UPDATE_SIZE_FIELD",
  updateVehicle: "UPDATE_VEHICLE",
  updateVehicleField: "UPDATE_VEHICLE_FIELD",
  changeCategory: "CHANGE_CATEGORY",
  deleteSizeField: "DELETE_SIZE_FIELD",
  deleteVehicleField: "DELETE_VEHICLE_FIELD"
}
