// @vendors
import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

// @requests
import { GET_SERVICES } from "../../requests/queries/services";
import { DELETE_SERVICE } from "../../requests/mutations/services";
import { DELETE_IMAGE } from "../../requests/mutations";

// @components
import Layout from "../../containers/dashboard/Layout";
import DashboardList from "../../containers/dashboard/list";
import Loading from "../../components/Loading";

// @assets
import ServicesIcon from "../../assets/icons/gear";

export default function DashboardServices({ history, location: { state } }) {
  const [notification, setNotification] = useState("");
  const { data, loading, error, refetch } = useQuery(GET_SERVICES);
  const [deleteService] = useMutation(DELETE_SERVICE, {
    update(cache, { data: { deleteService } }) {
      const { services } = cache.readQuery({
        query: GET_SERVICES,
      });
      cache.writeQuery({
        query: GET_SERVICES,
        data: {
          services: services.filter(service => service.id !== deleteService.id),
        },
      });
    },
  });
  const [deleteImage] = useMutation(DELETE_IMAGE);

  useEffect(() => {
    refetch();

    if (state) {
      setNotification(state.notification);

      setTimeout(() => {
        setNotification("");
        history.replace({ pathname: "/administrador/servicios", state: "" });
      }, 2000);
    }
  }, [history, state, refetch]);

  function onDelete(id, image, icon) {
    deleteImage({ variables: { path_img: image } });
    deleteImage({ variables: { path_img: icon } });
    deleteService({ variables: { id } });
  }

  if (loading) return <Loading />;
  if (error) return <p>Se ha producido un error intenta de nuevo</p>;

  return (
    <Layout
      title="Administrador - Servicios"
      notification={notification}
      Icon={ServicesIcon}
      heading="Administrar servicios"
    >
      <DashboardList
        btnLink="servicios/nuevo"
        btnText="servicio"
        data={data.services}
        onDelete={onDelete}
      />
    </Layout>
  );
}
