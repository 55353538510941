import gql from "graphql-tag";

const UPDATE_OFFER = gql`
  mutation updateCoverOffer($data: [UpdateCoverOfferInput!]!) {
    updateCoverOffer(data: $data) {
      id
      image
      title
      description
      url
    }
  }
`;

const UPDATE_SLIDER = gql`
  mutation updateSlider($data: [UpdateSliderInput!]!) {
    updateSlider(data: $data) {
      id
      image
      image_path
    }
  }
`;

const DELETE_IMAGE = gql`
  mutation deleteImage($path_img: String!) {
    deleteImage(path_img: $path_img) {
      response
    }
  }
`;

const UPDATE_EMAIL_SENDER = gql`
  mutation updateEmailSender($id: ID!, $email: String) {
    updateAuthorEmailSender(id: $id, email: $email) {
      id
      email_sender
    }
  }
`;

const SEND_TO_EMAIL = gql`
  mutation sendEmail($data: EmailInput!) {
    email(data: $data) {
      message
    }
  }
`;

const LOGIN = gql`
  mutation login($data: LoginAuthorInput!) {
    login(data: $data) {
      token
      author {
        id
      }
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!, $password: String!) {
    resetPassword(email: $email, password: $password) {
      token
      author {
        id
        email
      }
    }
  }
`;

const UPDATE_MODAL_PROMOTION = gql`
  mutation updateModalPromotion($id: ID!, $data: UpdateModalPromotionInput!) {
    updateModalPromotion(id: $id, data: $data) {
      id
      title
      description
      image
      image_path
      btn_text
      btn_url
    }
  }
`;

const UPLOAD_INVENTORY = gql`
  mutation uploadInventory($data: String!) {
    uploadInventory(data: $data) {
      message
    }
  }
`;

export {
  DELETE_IMAGE,
  UPDATE_OFFER,
  UPDATE_SLIDER,
  UPDATE_EMAIL_SENDER,
  SEND_TO_EMAIL,
  LOGIN,
  RESET_PASSWORD,
  UPDATE_MODAL_PROMOTION,
  UPLOAD_INVENTORY,
};
