import React from "react";
import styled from "styled-components";

// @components
import Select from "react-select";

const Wrapper = styled.div`
  margin: 5px;
`;

const Field = styled(Select)`
  .selectInput__control {
    padding: 6px 0 6px 18px;
    border-radius: 0;

    &:hover {
      border: 1px solid ${({ theme }) => theme.selectInput.focusedBorderColor};
    }
  }

  .selectInput__control--is-focused {
    border: 1px solid ${({ theme }) => theme.selectInput.focusedBorderColor};
    box-shadow: none;
  }

  .selectInput__indicator-separator {
    display: none;
  }

  .selectInput__placeholder {
    color: ${({ theme }) => theme.selectInput.placeHolderTextColor};
  }
`;

function SelectInput({
  id,
  placeholder,
  options,
  onChange,
  value,
  isDisabled,
}) {
  return (
    <Wrapper>
      <Field
        id={id}
        classNamePrefix="selectInput"
        options={options}
        placeholder={placeholder}
        defaultValue={value}
        value={value}
        onChange={e => onChange(e, null, id)}
        isDisabled={isDisabled}
      />
    </Wrapper>
  );
}

export default SelectInput;
